import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import address from '../../../../assets/booking/address.svg';
import cashImg from '../../../../assets/booking/cash.svg';
import mastercardIcon from '../../../../assets/payment/mastercard.svg';
import visaIcon from '../../../../assets/payment/visa.svg';
import close from '../../../../assets/trip/close.svg';
import './bookingdetail.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import { deleteTrip, getSingleTrip } from '../../../../redux/actions/trips';
import dayjs from 'dayjs';
import { loading } from '../../../../redux/actions/loading';
import Map from '../../../../components/GoogleMap/GoogleMap';
import Loader from '../../../../components/Loader/Loader';
import BookingPayment from '../../shipperHome/bookingType/oneOffBooking/confirmBooking/payment/bookingPayment';
import { getAllCards } from '../../../../redux/actions/card';

const BookingDetail = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.trips?.singleTrip);
  const [paymentShow, setPaymentShow] = useState(false);
  const [cardId, setCardId] = useState('');
  const [activeCardPayment, setActiveCardPayment] = useState(0);
  const cards = useSelector((state) => state?.card.allCards);

  useEffect(() => {
    dispatch(loading(true));
    dispatch(getSingleTrip(props?.tripId));
    dispatch(getAllCards());
  }, [props?.tripId, dispatch]);

  const handleDelete = (e) => {
    e.preventDefault();
    props.onHide();
    dispatch(deleteTrip(e.target.id));
  };

  const renderStatus = () => {
    switch (props?.selectedTrip) {
      case 'pending':
        return <span className="booking__status_complete">Confirmed</span>;
      case 'queued':
        return <span className="booking__status_transit">Pending</span>;
      case 'ongoing':
        return <span className="booking__status_transit">In transit</span>;
      case 'delivered':
        return <span className="booking__status_complete">Delivered</span>;
      default:
        return 'none';
    }
  };

  const paymentOption = () => {
    switch (activeCardPayment) {
      case 2:
        return (
          <div>
            <img className="mr-2" src={cashImg} alt="cash" />
            cash
          </div>
        );
      case 0:
        return (
          <div>
            {' '}
            {cards?.provider?.toLowerCase() === 'visa' ? (
              <img src={visaIcon} alt="cash" className="mr-3" />
            ) : (
              <img src={mastercardIcon} alt="cash" className="mr-3" />
            )}
            {cards?.filter((item) => item.id === cardId)?.map((item) => '****' + item.last_four)}
          </div>
        );
      case 1:
        return (
          <div>
            {cards?.provider?.toLowerCase() === 'visa' ? (
              <img src={visaIcon} alt="cash" className="mr-3" />
            ) : (
              <img src={mastercardIcon} alt="cash" className="mr-3" />
            )}
            {cards?.filter((item) => item.id === cardId)?.map((item) => '****' + item.last_four)}
          </div>
        );

      default:
        return 'none';
    }
  };

  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Body className="show-grid">
          <div className="booking__details">
            <div className="booking_detail__content">
              {/* BOOKING ADDRESS */}
              <div className="booking__address booking__detail__address">
                <div>
                  <img src={address} alt="address" />
                </div>
                <div>
                  <div className="book__address__pickup ml-3">
                    <h6 className="text-white">{props?.singleTrip?.pickup}</h6>
                  </div>
                  <div className="book__address__pickup ml-3 mt-4">
                    <h6 className="text-white">{props?.singleTrip?.delivery}</h6>
                  </div>
                </div>
              </div>

              {/* BOOKING DETAILS BODY */}
              <div className="booking_body__container">
                <div className="booking__detail__body">
                  <h6 className="booking__body__title">Booking ID</h6>
                  <p className="booking__body__response">{props?.singleTrip?.id}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Status</h6>
                  <p className="booking__body__response">{renderStatus()}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Date & time</h6>
                  <p className="booking__body__response">
                    {dayjs(props?.singleTrip?.recipient?.created_at).format('DD/MM/YYYY')}, &nbsp;
                    {dayjs(props?.singleTrip?.recipient?.created_at).format('h:mm ')}
                  </p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Nature of goods</h6>
                  <p className="booking__body__response">{props?.singleTrip?.goods?.name}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Value of goods</h6>
                  <p className="booking__body__response">₦{props?.singleTrip?.value_of_goods}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Estimated fare</h6>
                  <p className="booking__body__response">₦{props?.singleTrip?.price || 0.0}</p>
                </div>

                <div className="booking__contact__body mt-3">
                  <h6 className="booking__body__title">Contact</h6>
                  <div className="booking__contact__detail">
                    <div className="booking__contact__detail__image">
                      <img
                        src={'https://img.icons8.com/material/24/000000/user-male-circle--v1.png'}
                        alt="recipient contact"
                      />
                    </div>
                    <div className="booking__contact__detail__name">
                      <h6>{props?.singleTrip?.recipient?.name}</h6>
                      <a href={`tel:+${props?.singleTrip?.recipient?.contact}`}>
                        {props?.singleTrip?.recipient?.contact}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* BOOKING FOOTER / BUTTONS */}
              <div className="booking_bottom_line"></div>
              <div className="booking__footer">
                <button
                  className="btn btn__cancel"
                  id={props?.singleTrip?.id}
                  onClick={handleDelete}
                >
                  Cancel booking
                </button>
                <button className="btn btn__reject">Edit booking</button>
              </div>
            </div>

            <div className="google__maps">
              <img
                onClick={props.onHide}
                src={close}
                alt="close"
                className="close_transit d-flex m-4"
              />

              {/* BOOKING GOOGLE MAP */}
              {store?.pickup && props?.loading ? (
                <Loader />
              ) : (
                <div style={{ width: '100%', height: '100%' }}>
                  <Map pickup={store?.pickup} delivery={store?.delivery} />
                </div>
              )}

              <div className="drivers_content">
                {store?.driver === null ? (
                  <p> Booking not accepted yet</p>
                ) : (
                  <div>
                    <div className="driver_content__header">
                      <img
                        src={
                          store?.driver?.avatar_url ||
                          'https://img.icons8.com/material-outlined/24/000000/user-male-circle.png'
                        }
                        className="driver_content__icon"
                        alt="profile"
                      />
                      <div className="driver_content__header__text">
                        <h6>{store?.driver?.name}</h6>
                        <small>
                          <a href={`tel:+${store.driver?.phone}`}>{store.driver?.phone}</a>
                        </small>
                      </div>
                      <div className="driver_content__header__text">
                        <h6>{store?.vehicle?.brand}</h6>
                        <small>{store?.vehicle?.license_number}</small>
                      </div>
                      <div className="driver_content__header__text">
                        <h6>
                          4.8
                          <span>
                            <img
                              src="https://img.icons8.com/material/16/29CC92/star--v1.png"
                              alt="rating"
                            />
                          </span>
                        </h6>
                        <small>Rating</small>
                      </div>
                    </div>
                    <div className="booking__detail__line"></div>
                  </div>
                )}{' '}
                <div className="row">
                  <div className="col-md-6 booking__details__amount complete_info-row_col_right">
                    <h6>₦{props?.singleTrip?.price}</h6>
                    <small>Amount</small>
                  </div>
                  <div
                    className="col-md-6"
                    onClick={() => {
                      setPaymentShow(true);
                    }}
                  >
                    {paymentOption()}
                    <small>Payment method</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {paymentShow && (
        <BookingPayment
          show={paymentShow}
          setCardId={setCardId}
          cardId={cardId}
          setActiveCardPayment={setActiveCardPayment}
          activeCardPayment={activeCardPayment}
          onHide={() => setPaymentShow(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  singleTrip: state.trips.singleTrip,
  loading: state.home.loading,
});

export default connect(mapStateToProps)(BookingDetail);
