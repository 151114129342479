import React from 'react';
import { Switch } from 'react-router-dom';
import ShipperBooking from '../../pages/shipperPages/shipperBooking/ShipperBooking';
import ShipperHome from '../../pages/shipperPages/shipperHome/ShipperHome';
import ShipperTrip from '../../pages/shipperPages/shipperTrip/ShipperTrip';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

const ShipperRoute = () => {
  return (
    <Switch>
      <PrivateRoute path="/cargo-home" component={ShipperHome} />
      <PrivateRoute path="/cargo-booking" component={ShipperBooking} />
      <PrivateRoute path="/cargo-trip" component={ShipperTrip} />
    </Switch>
  );
};

export default ShipperRoute;
