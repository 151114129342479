import React, { useState } from 'react';
import './fleet.css';
import Driver from './driver/Driver';
import Vehicles from './vehicles/Vehicles';
import Navbar from '../../../components/mainNav/Navbar';

const Fleets = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <>
      <Navbar />
      <div className="home">
        <div className="home__content">
          <div className="fleets_container">
            <div className="fleets_header">
              <ul className="fleet_header_ul ">
                <li
                  className={toggleState === 1 ? 'fleets__li fleets_active' : 'fleets__li'}
                  onClick={() => toggleTab(1)}
                >
                  Drivers
                </li>
                <li
                  className={toggleState === 2 ? 'fleets__li fleets_active' : 'fleets__li'}
                  onClick={() => toggleTab(2)}
                >
                  Vehicles
                </li>
              </ul>
            </div>

            {toggleState === 1 ? <Driver /> : ''}
            {toggleState === 2 ? <Vehicles /> : ''}
          </div>
        </div>
      </div>
    </>
  );
};

export default Fleets;
