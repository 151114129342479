import instance from '../../axiosConfig';
import { toast } from 'react-toastify';
import { CREATE_BOOKING, GET_ALL_GOODS, GET_BOOKING, GET_PAGINATION } from '../types/bookingTypes';
import { loading } from './loading';

export const getAllGoods = () => {
  return async (dispatch) => {
    await instance
      .get('/goods')
      .then((response) => {
        dispatch({
          type: GET_ALL_GOODS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
export const getBooking = () => {
  return async (dispatch) => {
    await instance
      .get(`/trips?filter=pending`)
      .then((response) => {
        dispatch(loading(false));
        dispatch({
          type: GET_BOOKING,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getPagination = (id) => {
  console.log(id);
  return async (dispatch) => {
    await instance
      .get(`/trips?page=${id}`)
      .then((response) => {
        dispatch({
          type: GET_PAGINATION,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const createBooking = ({
  recipient_name,
  recipient_contact,
  pickup,
  delivery,
  truck_type_id,
  tonnage,
  value_of_goods,
  nature_of_goods_id,
  pickup_time,
  pickup_date,
  user_price,
}) => {
  return async (dispatch) => {
    await instance
      .post('/trips', {
        recipient_name,
        recipient_contact,
        pickup,
        delivery,
        truck_type_id,
        tonnage,
        value_of_goods,
        nature_of_goods_id,
        pickup_time,
        pickup_date,
        user_price,
      })
      .then((response) => {
        console.log(response?.data);
        dispatch({
          type: CREATE_BOOKING,
          payload: response,
        });
        toast.success('Your booking has been created', {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        toast.error(error.data?.message || `could not create Trip`);
      });
  };
};
