import React, { useState } from 'react';
import ShipperNav from '../../../components/shipperNav/ShipperNav';
import './shipperHome.css';
import bookIcon from '../../../assets/shipperHome/plus.svg';
import paymentIcon from '../../../assets/shipperHome/payment.svg';
import fareIcon from '../../../assets/home/calc.png';
import Fare from './fare/Fare';
import Payment from './payment/Payment';
import BookingType from './bookingType/BookingType';

const ShipperHome = () => {
  const [fareShow, setFareShow] = useState(false);
  const [paymentShow, setPaymentShow] = useState(false);
  const [bookingTypeShow, setBookingTypeShow] = useState(false);

  const userData = localStorage.getItem('userData');

  return (
    <>
      <ShipperNav />
      <div className="home shipper__home">
        <div className="shipper__home_container">
          <div className="shipper__home_container_title">
            <h1>Welcome back, {JSON.parse(userData).name} 🌤</h1>
            <p>Here are some quick things you can do right now!</p>
          </div>

          <div className="shipper__home__options">
            <div className="shipper__home__options_item">
              <div className="shipper__home__book_item" onClick={() => setBookingTypeShow(true)}>
                <div className="home__icon">
                  <h2>Make a Booking</h2>
                </div>
                <div className="shipper__home__options_item_icons book_icon">
                  <img src={bookIcon} alt="make a booking" />
                </div>
              </div>

              <div className="shipper__home__fare_item" onClick={() => setFareShow(true)}>
                <div className="home__icon">
                  <h2>Calculate Fare</h2>
                  <img src={fareIcon} alt=" Calculate Fare" />
                </div>

                <div className="shipper__home__options_item_icons">
                  <p>Get an estimate of how much your trip will cost. You can create a booking.</p>
                </div>
              </div>

              <div className="shipper__home__payment_item" onClick={() => setPaymentShow(true)}>
                <div className="home__icon">
                  <h2>Your Payments</h2>
                  <img src={paymentIcon} alt="Your payments" />
                </div>

                <div className="shipper__home__options_item_icons">
                  <p>Get an estimate of how much your trip will cost. You can create a booking.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Fare show={fareShow} onHide={() => setFareShow(false)} />
      <Payment show={paymentShow} onHide={() => setPaymentShow(false)} />

      <BookingType show={bookingTypeShow} onHide={() => setBookingTypeShow(false)} />
    </>
  );
};

export default ShipperHome;
