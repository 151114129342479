import { Table } from 'antd';
import React, { useState } from 'react';
import Button from '../../../../../components/Button/Button';
import SearchBar from '../../../../../components/search/SearchBar';
import AddWageModal from '../addRate/add-wage';
import { variableRateColumns } from './components/variable-column';

const VariablesPricing = () => {
  const [openAddRateModal, setOpenAddRateModal] = useState(false);
  const handleSearch = (e) => {
    // setSearchTerm(e.target.value);
  };
  return (
    <>
      {openAddRateModal && (
        <AddWageModal show={openAddRateModal} onHide={() => setOpenAddRateModal(false)} />
      )}
      <div className="fleets_table">
        {/* {props?.loading ? (
          <Loader />
        ) : ( */}
        <div className="table-responsive table-borderless mt-4">
          <div className="fleets_search">
            <SearchBar handleSearch={handleSearch} text="Search for rates" />
            <div className="fleets__select">
              <Button text={'Add rate'} onClick={() => setOpenAddRateModal(true)} />
            </div>
          </div>
          <div className="mt-3">
            <Table columns={variableRateColumns} dataSource={[]} />
          </div>
        </div>
      </div>
    </>
  );
};

export default VariablesPricing;
