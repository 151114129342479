import React from 'react'
import address from '../../../../../../assets/home/address.svg'

const ShippingLocation = ({goNextPage}) => {
    return (
        <>
            <div className="create__booking__container__content">
                <h1>Lease terms</h1>
                <div className="row">
                    <div className='col-1 address__icon' >
                        <img src={address} alt="address" />
                    </div>
                    <div className='col-10 create__booking__address'>
                        <div className="book__address__pickup ml-3">
                            <div className="form-floating mt-4">
                                <input type="text" className="form-control login__form__control" id="Pickup__location" placeholder="Pickup location"  
                                required
                                name='Pickup__location'
                                />
                                <label for="Pickup__location" className='login__label'>Pickup location</label>
                            </div>
                        </div>
                        <div className="book__address__pickup ml-3 mt-4">
                            <div className="form-floating mt-4">
                                <input type="text" className="form-control login__form__control col-12" id="Delivery__location" placeholder="Delivery location"  
                                required
                                />
                                <label for="Delivery__location" className='login__label'>Delivery location</label>
                            </div>                         
                        </div>
                        <div className="ytt">
                            <p className="form-check-label  mt-3">
                                <span className='add__delivery' >+ Add another delivery location</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="border-top my-3"></div>

                <div className="pickip__time">
                    <div className="row g-2">
                        {/* duration and year */}
                        <div className="col-md">
                            <div className="form-floating">
                                <select className="form-select form-select-lg p-3 login__form__control login__label" aria-label="form-select-lg example">
                                    <option selected>Monthly or Yearly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-floating">
                                <select className="form-select form-select-lg p-3 login__form__control login__label" aria-label="form-select-lg example">
                                    <option selected>Duration</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                            </div>
                        </div>

                        <h5 className='m-0 px-3 pt-2' style={{'color': '#939EAD', 'font-weight': 'normal'}}>Enter your price you want to pay</h5>
                            {/* name */}
                            <div className="col-md">
                                <div className="form-floating">
                                    <input type="text" className="form-control login__form__control" id="floatingInputGrid" name='lease__fee' placeholder='Lease fee' required/>
                                    <label htmlFor="lease__fee" className='login__label'>Lease fee</label>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="form-floating">
                                    <input type="text" className="form-control login__form__control" id="floatingInputGrid" name='unit' placeholder="unit" />
                                    <label htmlFor="units" className='login__label'>Units</label>
                                </div>
                            </div>
                        
                        <h5 className='m-0 px-3 pt-5' style={{'color': '#939EAD', 'font-weight': 'normal'}}>Let’s know your pickup date and time</h5>
                        <div className="col-md">
                            <div className="form-floating">
                                <input type="date" className="form-control login__form__control" id="floatingInputGrid" placeholder="Last name" required/>
                                <label htmlFor="floatingInputGrid" className='login__label'>Pickup date</label>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="form-floating">
                                <input type="time" className="form-control login__form__control" id="floatingInputGrid" placeholder="Last name" />
                                <label htmlFor="floatingInputGrid" className='login__label'>Pickup time</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ytt mt-5">
                    <button type="submit" onClick={goNextPage} className="btn btn-login btn-block"> Continue</button>
                </div>
            </div>
        </>
    )
}

export default ShippingLocation
