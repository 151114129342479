import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Loader = () => {
  return (
    <div className="text-center m-auto">
      <ClipLoader
        height="20"
        width="30"
        margin="auto"
        color="yellow"
        ariaLabel="loading"
        center="true"
        strokeWidthSecondary={3}
        secondaryColor="white"
        strokeWidth={6}
      />
    </div>
  );
};

export default Loader;
