import React from 'react'
import Modal from 'react-bootstrap/Modal'
import base from './Base.svg'
import { Link } from 'react-router-dom'


const InviteSuccess = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                
                <Modal.Body>
                    <div className="container login__right pb-5">
                            <div class="mt-10 text-center">
                                <img src={base} alt="Envelope" className="evenlope" />
                                <h4 class="mt-4 header__text">Invite sent successfull!</h4>
                                <p class="verify-text">Curabitur aliquet quam id dui posuere blandit. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar.</p>                
                            </div>
                                
                            <div className="mt-5 text-center">
                                <button type="submit" className="btn verify-button send_invite_btn"
                                >
                                Continue to dashboard
                                </button>

                                <div className="ytt">
                                    <p className="form-check-label text-center mt-3">
                                        <span className="text-secondary">Still want to add more team member?    
                                        </span> 
                                         <Link to="/register" className='login__links'>
                                             Click here
                                        </Link>
                                    </p>
                                </div>
                            </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default InviteSuccess
