import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import camImg from '../../../../assets/settings/camera.png';
import { getUser } from '../../../../redux/actions/home';
import { updateUser, uploadProfilePhoto } from '../../../../redux/actions/settings';

const MyAccount = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const name = firstName + ' ' + lastName;
  const store = useSelector((state) => state.home.user);
  const [photo, setPhoto] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateUser({
        name,
        phone,
      }),
    );
  };

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const formData = new FormData();
  formData.append('photo', photo);

  // TODO: fix this
  const onImageChange = (e) => {
    setPhoto(e.target.files[0]);
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }

    if (e.target.files[0]) {
      dispatch(uploadProfilePhoto(photo));

      // dispatch(uploadProfilePhoto({
      //     photo: URL.createObjectURL(event.target.files[0])
      // }))
    }
    // dispatch(uploadProfilePhoto({
    //     formData
    // }));
  };
  return (
    <>
      <form className="form mt-4 pb-5" onSubmit={handleSubmit}>
        <div className="row">
          <figure className="col-md-6 mb-4">
            <input type="file" name="photo" onChange={onImageChange} id="filetype" />
            <label htmlFor="filetype">
              <img
                src={
                  image === null
                    ? `https://img.icons8.com/ios-filled/80/000000/user-male-circle.png`
                    : image
                }
                className="previewImg"
                alt="preview"
              />
              <img className="cameraImg" src={camImg} alt="camera" />
            </label>
          </figure>
        </div>

        <div className="row g-2">
          {/* name */}
          <div className="col-md">
            <div className="form-floating">
              <input
                type="text"
                className="form-control login__form__control"
                id="floatingInputGrid"
                placeholder="first name"
                defaultValue={store?.name?.split(' ').slice(0, -1)?.join(' ')}
                onChange={(e) => setFirstName(e.target.value)}
                name="firstName"
                // disabled
                required
              />
              <label htmlFor="floatingInputGrid" className="login__label">
                First name
              </label>
            </div>
          </div>
          <div className="col-md">
            <div className="form-floating">
              <input
                type="text"
                className="form-control login__form__control"
                id="floatingInputGrid"
                placeholder="Last name"
                defaultValue={store?.name?.split(' ').slice(-1)?.join(' ')}
                name="lastName"
                // disabled
                onChange={(e) => setLastName(e.target.value)}
              />
              <label htmlFor="floatingInputGrid" className="login__label">
                Last name
              </label>
            </div>
          </div>
        </div>
        {/* phone number  */}
        <div className="form-floating mt-4">
          <input
            type="tel"
            className="form-control login__form__control"
            id="number"
            placeholder="Phone number"
            name="phone"
            defaultValue={store?.phone}
            required
            onChange={(e) => setPhone(e.target.value)}
          />
          <label htmlFor="number" className="login__label">
            Phone number
          </label>
        </div>
        {/* email  */}
        <div className="form-floating mt-4 mb-4">
          <input
            type="email"
            className="form-control login__form__control"
            id="email"
            placeholder="jeremiah@ellie-logistics.com"
            name="email"
            required
            defaultValue={store?.email}
          />
          <label htmlFor="email" className="login__label">
            Email address
          </label>
        </div>
        {/*account type  */}
        {/* <div className="form mt-4">
                    <select className="form-select form-select-lg p-3 login__form__control login__label" aria-label="form-select-lg example">
                        <option selected>As a business</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div> */}
        <div className="form-floating mt-4 mb-4">
          <input
            type="text"
            className="form-control login__form__control"
            id="accountType"
            defaultValue={store?.role?.name}
            name="accountType"
            required
            disabled
          />
          <label htmlFor="accountType" className="login__label">
            Role
          </label>
        </div>
        <button type="submit" className="btn btn-login btn-block col-lg-4 mt-5">
          Save changes
        </button>
      </form>
      <ToastContainer />
    </>
  );
};

export default MyAccount;
