import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import address from '../../../../assets/booking/address.svg';
import cashImg from '../../../../assets/booking/cash.svg';
import User from '../../../../assets/trip/user.png';
import close from '../../../../assets/trip/close.svg';
import { connect, useDispatch, useSelector } from 'react-redux';
import { loading } from '../../../../redux/actions/loading';
import { getSingleTrip } from '../../../../redux/actions/trips';
import Map from '../../../../components/GoogleMap/GoogleMap';
import Loader from '../../../../components/Loader/Loader';
import dayjs from 'dayjs';

const ActiveTrip = (props) => {
  const store = useSelector((state) => state?.trips?.singleTrip);
  const dispatch = useDispatch();

  console.log(store);

  useEffect(() => {
    dispatch(loading(true));
    dispatch(getSingleTrip(props?.tripId));
  }, [props?.tripId, dispatch]);

  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Body className="show-grid">
          <div className="booking__details">
            <div className="booking_detail__content">
              {/* BOOKING ADDRESS */}
              <div className="booking__address booking__detail__address">
                <div>
                  <img src={address} alt="address" />
                </div>
                <div>
                  <div className="book__address__pickup ml-3">
                    <h6 className="text-white">{store?.pickup}</h6>
                  </div>
                  <div className="book__address__pickup ml-3 mt-4">
                    <h6 className="text-white">{store?.delivery}</h6>
                  </div>
                </div>
              </div>

              {/* BOOKING DETAILS BODY */}
              <div className="booking_body__container">
                <div className="booking__detail__body">
                  <h6 className="booking__body__title">Booking ID</h6>
                  <p className="booking__body__response">#{store?.id}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Status</h6>
                  <p className="booking__body__response booking__detail__status">Confirmed</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Date & time</h6>
                  <p className="booking__body__response">
                    {dayjs(store?.recipient?.created_at).format('DD/MM/YYYY')}, &nbsp;
                    {dayjs(store?.recipient?.created_at).format('h:mm ')}
                  </p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Nature of goods</h6>
                  <p className="booking__body__response">{store?.goods?.name}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Value of goods</h6>
                  <p className="booking__body__response">₦{store?.value_of_goods}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Estimated fare</h6>
                  <p className="booking__body__response">₦{store?.price || 0}</p>
                </div>

                <div className="booking__contact__body mt-3">
                  <h6 className="booking__body__title">Contact Person</h6>
                  <div className="booking__contact__detail">
                    <div className="d-flex align-items-center">
                      <div className="booking__contact__detail__image mr-3">
                        <img src={User} alt="user" />
                      </div>
                      <div className="booking__contact__detail__name">
                        <h6>{store?.recipient?.name}</h6>
                        <a href={`tel:+${store?.recipient?.contact}`}>
                          {store?.recipient?.contact}
                        </a>
                      </div>
                    </div>
                    <div className="trip__code">
                      <h6>{store?.trip_code || '0000'}</h6>
                      <small>Your trip code</small>
                    </div>
                  </div>
                </div>
              </div>

              {/* BOOKING FOOTER / BUTTONS */}
              <div className="booking_bottom_line"></div>
              <div className="booking__footer">
                <button className="btn btn__reject col-lg-12">1hrs 20mins remaining</button>
              </div>
            </div>

            <div className="google__maps">
              <img
                onClick={props.onHide}
                src={close}
                alt="close"
                className="close_transit d-flex m-4"
              />

              {/* BOOKING GOOGLE MAP */}
              {store?.pickup && props?.loading ? (
                <Loader />
              ) : (
                <div style={{ width: '100%', height: '100%' }}>
                  <Map pickup={store?.pickup} delivery={store?.delivery} />
                </div>
              )}

              <div className="driver_content">
                {store?.driver === null ? (
                  <p> Booking has been accepted but a driver has not been assigned to this trip</p>
                ) : (
                  <div>
                    <div className="driver_content__header">
                      <img
                        src={
                          store?.recipient?.avatar_url ||
                          'https://img.icons8.com/material-outlined/24/000000/user-male-circle.png'
                        }
                        className="driver_content__icon"
                        alt="profile"
                      />
                      <div className="driver_content__header__text">
                        <h6>{store?.driver?.name}</h6>
                        <small>
                          <a href={`tel:+${store.driver?.phone}`}>{store.driver?.phone}</a>
                        </small>
                      </div>
                      <div className="driver_content__header__text">
                        <h6>{store?.vehicle?.brand}</h6>
                        <small>{store?.vehicle?.license_number}</small>
                      </div>
                      <div className="driver_content__header__text">
                        <h6>
                          4.8
                          <span>
                            <img
                              src="https://img.icons8.com/material/16/29CC92/star--v1.png"
                              alt="rating"
                            />
                          </span>
                        </h6>
                        <small>Rating</small>
                      </div>
                    </div>
                    <div className="booking__detail__line"></div>
                  </div>
                )}{' '}
                <div className="row">
                  <div className="col-md-6 booking__details__amount complete_info-row_col_right">
                    <h6>₦{props?.singleTrip?.price}</h6>
                    <small>Amount</small>
                  </div>
                  <div className="col-md-6">
                    <h6>
                      {' '}
                      <img src={cashImg} alt="cash" /> Cash
                    </h6>
                    <small>Payment method</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  singleTrip: state.trips.singleTrip,
  loading: state.home.loading,
});

export default connect(mapStateToProps)(ActiveTrip);
