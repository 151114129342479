export const columns = [
  {
    title: 'ASSET',
    dataIndex: 'asset',
    key: 'name',
  },
  {
    title: 'PICK UP LOCALE',
    dataIndex: 'pickUpLocale',
    key: 'age',
  },
  {
    title: 'DELIVERY LOCALE',
    dataIndex: 'deliveryLocale',
    key: 'deliveryLocale',
  },
  {
    title: 'RATE',
    dataIndex: 'rate',
    key: 'rate',
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'ACTION',
    dataIndex: 'action',
    key: 'action',
  },
];
