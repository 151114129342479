import React from 'react';

const EmptyState = ({ text }) => {
  return (
    <div className="text-center">
      <h6> Oops! No {text} data available at the moment</h6>
    </div>
  );
};

export default EmptyState;
