import { Dropdown } from 'react-bootstrap';

export const columns = [
  {
    title: 'COMPANY',
    dataIndex: 'user',
    render: (text) => `${text?.name}`,
    key: 'user',
  },
  {
    title: 'DESCRIPTION',
    dataIndex: 'goods',
    render: (text) => `${text?.name}`,
    key: 'goods',
  },
  {
    title: 'PICKUP LOCATION',
    dataIndex: 'pickup',
    key: 'pickup',
    // render: (pickup) => <>{JSON.parse(pickup)?.description}</>,
  },
  {
    title: 'DESTINATION',
    dataIndex: 'delivery',
    key: 'delivery',
    // render: (delivery) => <>{JSON.parse(delivery)[0]?.payload?.description}</>,
  },
  {
    title: 'VEHICLE TYPE',
    dataIndex: 'truck_type_id',
    key: 'truck_type_id',
    render: (vehicle) => (
      <>
        {/* <img
          src={vehicleTypes?.filter((truck) => truck?.id === vehicle)[0]?.icon_url}
          className="driver_content__icon "
          alt={vehicle}
        />
        <p className="neutral__paragraph p-0">
          {vehicleTypes?.filter((truck) => truck?.id === vehicle)[0]?.capacity} ton(s)
        </p> */}
      </>
    ),
  },
  {
    title: 'ESTIMATED PRICE',
    dataIndex: 'base_price',
    key: 'base_price',
    render: (text) => `₦${text || 0}`,
  },
  {
    title: 'ACTIONS',
    key: 'actions',
    render: (id) => (
      <div className="dropdown" key={id}>
        <Dropdown
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown.Toggle className="table-button" id="dropdown-basic">
            <i className="fas fa-ellipsis-h"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                // setAcceptModalShow(true);
              }}
            >
              Accept Booking
            </Dropdown.Item>
            <Dropdown.Item
              id={id.id}
              className="cancel"
              onClick={
                () => {}
                // setRejectModalShow(true)
              }
            >
              Reject Booking
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];
