import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import pdfImg from '../../../../../assets/driver/pdfcheck.png';
import close from '../../../../../assets/driver/close.svg';
import './vehicledetail.css';
import { useDispatch, useSelector } from 'react-redux';
import { getEachVehicle, uploadVehicleCredential } from '../../../../../redux/actions/fleet';
import Loader from '../../../../../components/Loader/Loader';
import dayjs from 'dayjs';
import Button from '../../../../../components/Button/Button';

const VehicleDetail = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.fleets?.specificVehicle);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    e.persist();
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
      // [e.target.files]: e.target.files,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      uploadVehicleCredential({
        id: props.id,
        formData,
      }),
    );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getEachVehicle(props?.id));
    setLoading(false);
  }, [props.id, dispatch]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Modal {...props} size="lg">
          <Modal.Body className="show-grid">
            <div className="booking__details">
              <div className="booking_detail__content">
                {/* BOOKING DETAIL HEADER */}
                <div className="booking_detail__header">
                  <div className="booking__name">
                    <img
                      className="mr-3"
                      src={store?.truck?.icon_url}
                      width="60px"
                      height="60px"
                      alt="vehicle"
                    />
                    <div className="user__name">
                      <h6 className="user__name__h6">{store?.brand}</h6>
                      <small className="trip__history__date">
                        Added since {dayjs(store?.created_at).format('MMM, DD YYYY')} |{' '}
                        {dayjs(store?.created_at).format('hh:mm A')}
                      </small>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Dropdown>
                      <Dropdown.Toggle className="table-button" id="dropdown-basic">
                        <i class="fas fa-ellipsis-h"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Edit Vehicle</Dropdown.Item>
                        <Dropdown.Item
                          onClick={props?.handleDelete}
                          id={props?.id}
                          className="dropdown__danger"
                        >
                          Delete Vehicle
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="container">
                  <div className=" driver__detail_row row text-center">
                    <div className="col-md-6 complete_info-row_col complete_info-row_col_right">
                      <h6>{store?.truck?.capacity}</h6>
                      <small>Deliveries made</small>
                    </div>
                    <div className="col-md-6 complete_info-row_col">
                      <h6>0</h6>
                      <small>Distance covered</small>
                    </div>
                  </div>
                </div>

                {/* BOOKING DETAILS BODY */}
                <div className="booking_body__container">
                  <div>
                    <div className="booking__detail__body">
                      <h6 className="booking__body__title">Status</h6>
                      <p className="active_status">
                        {store?.active === 1 ? (
                          <p className="trip__status">
                            {' '}
                            <span>Active</span>
                          </p>
                        ) : (
                          <p className="booking__status_new">
                            {' '}
                            <span>Deactivated</span>{' '}
                          </p>
                        )}
                      </p>
                    </div>

                    <div className="booking__detail__body mt-3">
                      <h6 className="booking__body__title">Vehicle Brand</h6>
                      <p className="booking__body__response">{store?.brand}</p>
                    </div>

                    <div className="booking__detail__body mt-3">
                      <h6 className="booking__body__title">Mode</h6>
                      <p className="booking__body__response">{store?.truck?.name}</p>
                    </div>

                    <div className="booking__detail__body mt-3">
                      <h6 className="booking__body__title">Year</h6>
                      <p className="booking__body__response">{store?.model}</p>
                    </div>

                    <div className="booking__detail__body mt-3">
                      <h6 className="booking__body__title">License Number</h6>
                      <p className="booking__body__response">{store?.license_number}</p>
                    </div>

                    <div className="booking__detail__body mt-3">
                      <h6 className="booking__body__title">Registration Number</h6>
                      <p className="booking__body__response">{store?.registration_number}</p>
                    </div>

                    <div className="booking__detail__body mt-3">
                      <h6 className="booking__body__title">Expiry Date</h6>
                      <p className="booking__body__response">{store?.expiry_date}</p>
                    </div>

                    <div className="booking__detail__body mt-3">
                      <h6 className="booking__body__title">Colour</h6>
                      <p className="booking__body__response">{store?.color}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="trip__history">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="basic__h3">Documents</h3>
                  <img
                    onClick={props.onHide}
                    src={close}
                    alt="close"
                    className="close_transit d-flex m-4"
                  />
                </div>

                <form className="document__upload" onSubmit={handleSubmit}>
                  <div className="document__container">
                    <div className="document__upload__item">
                      {' '}
                      <img src={pdfImg} alt="pdf" />
                      <div className="document__upload__item__img">
                        <p>Driver's license Document</p>
                        <small>ROAD WORTHINESS</small>
                      </div>
                    </div>
                    <div className="document__upload__item__icon">
                      <input
                        type="file"
                        name="road_worthiness"
                        id="road_worthiness"
                        onChange={handleChange}
                        className="d-none"
                        accept="application/pdf,application/vnd.ms-excel,image/gif, image/jpeg"
                      />
                      <label htmlFor="road_worthiness">Upload New</label>
                    </div>
                  </div>

                  <div className="document__container">
                    <div className="document__upload__item">
                      {' '}
                      <img src={pdfImg} alt="pdf" />
                      <div className="document__upload__item__img">
                        <p>Training Certificate Document</p>
                        <small>VEHICLE INSURANCE</small>
                      </div>
                    </div>
                    <div className="document__upload__item__icon">
                      <input
                        type="file"
                        name="vehicle_insurance"
                        id="vehicle_insurance"
                        onChange={handleChange}
                        className="d-none"
                        accept="application/pdf,application/vnd.ms-excel.image/gif, image/jpeg"
                      />
                      <label htmlFor="vehicle_insurance">Upload New</label>
                    </div>
                  </div>

                  <div className="document__container">
                    <div className="document__upload__item">
                      {' '}
                      <img src={pdfImg} alt="pdf" />
                      <div className="document__upload__item__img">
                        <p>Training Certificate Document</p>
                        <small>INSPECTION REPORT</small>
                      </div>
                    </div>
                    <div className="document__upload__item__icon">
                      <input
                        type="file"
                        name="inspection_report"
                        onChange={handleChange}
                        id="inspection_report"
                        className="d-none"
                        accept="application/pdf,application/vnd.ms-excel,image/gif, image/jpeg"
                      />
                      <label htmlFor="inspection_report">Upload New</label>
                    </div>
                  </div>

                  <Button text="Submit" />
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default VehicleDetail;
