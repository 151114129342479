import { ACCEPT_INVITE_COMPANY, CREATE_COMPANY, FETCH_COMPANY_USERS, GET_USER_ROLES, INVITE_USERS_COMPANY, SEND_INVITES, UPDATE_COMPANY, UPLOAD_COMPANY_CREDENTIALS, UPLOAD_COMPANY_LOGO } from "../types/companyTypes";

const initialState = {
    company: {},
    params: {},
    companyUsers: [],
    allRoles: []
};

function companyReducer(state = initialState, action) {
    const { type, payload } = action; 

    switch (type) {
        case CREATE_COMPANY: 
          return{
            ...state,
            company: payload
        }
        case GET_USER_ROLES: 
          return{
            ...state,
            allRoles: payload
        }
        case UPDATE_COMPANY: 
          return{
            ...state,
            company: payload.action
        }
        case INVITE_USERS_COMPANY: 
          return{
            ...state,
        }
        case ACCEPT_INVITE_COMPANY: 
          return{
            ...state,
        }
        case FETCH_COMPANY_USERS: 
          return{
            ...state,
            companyUsers: payload
        }
        case UPLOAD_COMPANY_LOGO: 
          return{
            ...state,
        }
        case UPLOAD_COMPANY_CREDENTIALS: 
          return{
            ...state,
        }
        case SEND_INVITES: 
          return{
            ...state,
        }
      default:
        return state;
    }
}

export default companyReducer