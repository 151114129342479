import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../../../assets/settings/close.svg';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../../../redux/actions/home';
import pineLogo from '../../../../../assets/payment/pine.png';
import instance from '../../../../../axiosConfig';
import LoadModal from './LoadModal';

const AddCard = (props) => {
  const store = useSelector((state) => state?.home?.user);
  const dispatch = useDispatch();
  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_API_KEY,
    tx_ref: Date.now(),
    amount: 10,
    currency: 'NGN',
    payment_options: 'card',
    customer: {
      email: store?.email,
      phone_number: store?.phone,
      name: store?.name,
    },
    customizations: {
      title: 'Refundable payment',
      logo: pineLogo,
    },
  };

  // const handlePayment = async () => {
  //   await instance
  //     .post('payment/card', {
  //       last_four: paymentData?.card?.last_4digits,
  //       token: paymentData?.card?.token,
  //       provider: paymentData?.card?.type,
  //       vault_service: 'Flutterwave',
  //     })
  //     .then((res) => {
  //       setLoadingModal(false);
  //       console.log(res.data);
  //     });
  // };
  const handleVerification = async (transaction_id) => {
    await instance.post(`/payment/${transaction_id}/verify`, {}).then((res) => {
      // setLoadingModal(false);
      console.log(res.data);
    });
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3>Add card</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-center">
              Dear {store?.name}, a refundable amount of ₦10 will be deducted from your card, do you
              still want to continue?
            </p>
          </div>

          <div className="reject__footer">
            <button onClick={props.onHide} className="btn btn__reject mr-4">
              Discard
            </button>
            <button
              className="btn btn__accept"
              onClick={() => {
                handleFlutterPayment({
                  callback: (response) => {
                    console.log(response);
                    handleVerification(response?.transaction_id);
                    setLoadingModal(true);
                    props.onHide();
                    closePaymentModal(); // this will close the modal programmatically
                  },
                  onClose: () => {},
                });
              }}
            >
              {' '}
              Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <LoadModal show={loadingModal} onHide={() => setLoadingModal(false)} />
    </>
  );
};

export default AddCard;
