import React from 'react';
import { Modal } from 'react-bootstrap';
import nestle from '../../../../../assets/home/nestle.png';
import address from '../../../../../assets/home/address.svg';
import ContainerIcon from '../../../../../assets/calcfare/container.svg';
import close from '../../../../../assets/trip/close.svg';

const LeaseBookingDetails = (props) => {
  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Body className="show-grid">
          <div className="booking__details">
            <div className="booking_detail__content">
              {/* BOOKING DETAIL HEADER */}
              <div className="booking_detail__header">
                <div className="booking__name">
                  <img className="mr-3" src={nestle} alt="nestle" />
                  <div>
                    <h6>Nestlé Nigeria PLC</h6>
                    <div className="booking__small">
                      <small>+2341 2790707</small>
                    </div>
                  </div>
                </div>
                <p className=" booking__status_new">New</p>
              </div>
              <div className="booking__line"></div>

              {/* BOOKING DETAILS BODY */}
              <div className="booking_body__container">
                <div className="booking__detail__body">
                  <h6 className="booking__body__title">Booking ID</h6>
                  <p className="booking__body__response">#15285046</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Date & time</h6>
                  <p className="booking__body__response">March 10, 2020 10:58AM</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Nature of goods</h6>
                  <p className="booking__body__response">Fresh farm produce (1 ton)</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Value of goods</h6>
                  <p className="booking__body__response">₦800,000.00 - ₦950,000.00</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Estimated fare</h6>
                  <p className="booking__body__response">₦54,000.00 - ₦75,000.00</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Duration</h6>
                  <p className="booking__body__response">2 months</p>
                </div>

                {/* BOOKING ADDRESS */}
                <div className="booking__address">
                  <div>
                    <img src={address} alt="address" />
                  </div>
                  <div>
                    <div className="book__address__pickup ml-3">
                      <h6>Shed 20, NPA Wharf Port Complex</h6>
                      <div className="booking__small">
                        <small>Apapa, Lagos, Nigeria</small>
                      </div>
                    </div>
                    <div className="book__address__pickup ml-3 mt-4">
                      <h6>Shed 20, NPA Wharf Port Complex</h6>
                      <div className="booking__small">
                        <small>Apapa, Lagos, Nigeria</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* BOOKING FOOTER / BUTTONS */}
              <div className="booking_bottom_line"></div>
              <div className="booking__footer mt-4">
                <button onClick={props.onHide} className="btn btn__reject">
                  Reject request
                </button>
                <button className="btn btn_accept btn-login">Accept request</button>
              </div>
            </div>

            <div className="google__maps">
              <img
                onClick={props.onHide}
                src={close}
                alt="close"
                className="close_transit d-flex m-4"
              />

              {/* BOOKING GOOGLE MAP */}

              <div className="driver_content col-5">
                <div className="driver_content__header justify-content-start">
                  <img src={ContainerIcon} className="driver_content__icon" alt="rating" />
                  <div className="driver_content__header__text ">
                    <h6>Container Truck</h6>
                    <small>30 tons</small>
                  </div>
                  <div className="driver_content__header__text ">
                    <h6>Units</h6>
                    <small>30</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeaseBookingDetails;
