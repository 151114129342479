import React, { useEffect } from 'react';
import address from '../../../../../../assets/booking/address.svg';
import calendarImg from '../../../../../../assets/shipperHome/calendar.svg';
// import editImg from '../../../../../../assets/settings/Edit.png';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Mousewheel } from 'swiper';
import './bookingDescription.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getAllGoods } from '../../../../../../redux/actions/booking';
import { getTruckTypes } from '../../../../../../redux/actions/fleet';
import { bookingCalculateFare } from '../../../../../../redux/actions/home';
import Button from '../../../../../../components/Button/Button';
import dayjs from 'dayjs';

// install Swiper modules
SwiperCore.use([Navigation, Mousewheel]);

const BookingDescription = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.fleets);

  useEffect(() => {
    dispatch(getAllGoods());
    dispatch(getTruckTypes());
  }, [dispatch]);

  const nexStep = (e) => {
    e.preventDefault();

    dispatch(
      bookingCalculateFare({
        truck_type_id: props?.truckId,
        distance: props?.distance,
        tonnage: props?.values?.tonnage,
        value_of_goods: props?.values?.value_of_goods,
      }),
      props?.goNextPage(),
    );
  };

  console.log(props);

  return (
    <>
      <div className="booking__address booking__detail__address m-0">
        <div>
          <img src={address} alt="address" />
        </div>
        <div>
          <div className="book__address__pickup ml-3">
            <h6 className="text-white">{props?.values?.pickup}</h6>
          </div>
          <div className="book__address__pickup ml-3 mt-4">
            <h6 className="text-white">{props?.values?.delivery}</h6>
          </div>
        </div>
      </div>
      {/* booking description */}
      <div className="booking__description__container">
        <div className="booking__description__container__header d-flex justify-content-between align-content-center mb-2">
          <div className="d-flex gap-2">
            <img src={calendarImg} alt="calender" />
            <span>
              {props?.values?.pickup_date} | {dayjs(props?.values?.pickup_time).format('hh:mm A')}
            </span>
          </div>
          {/* <img src={editImg} alt="edit" /> */}
        </div>
        <div className="border-bottom mb-4"></div>

        <h6>Booking description</h6>
        <div className="form-floating mt-2">
          <select
            className="form-select form-select-lg p-3 login__form__control login__label"
            aria-label="form-select-lg example"
            onChange={props?.handleFormData('nature_of_goods_id')}
          >
            {props?.booking?.allGoods.map((list) => (
              <option key={list?.id} value={list?.id}>
                {list?.name}
              </option>
            ))}
          </select>
          <label htmlFor="Delivery__location" className="login__label">
            Select nature of goods
          </label>
        </div>

        <div className="row g-2 mt-1">
          {/* value of goods and weight */}
          <div className="col-md">
            <div className="form-floating">
              <input
                type="text"
                className="form-control login__form__control"
                id="value_of_goods"
                placeholder="Last name"
                defaultValue={props?.values?.value_of_goods}
                onChange={props?.handleFormData('value_of_goods')}
                required
                name="value_of_goods"
              />
              <label htmlFor="value_of_goods" className="login__label">
                Est. value of goods
              </label>
            </div>
          </div>
          <div className="col-md">
            <div className="form-floating">
              <input
                type="text"
                className="form-control login__form__control"
                id="tonnage"
                placeholder="tonnage of goods"
                defaultValue={props?.values?.tonnage}
                onChange={props?.handleFormData('tonnage')}
              />
              <label htmlFor="tonnage" className="login__label">
                Est. weight of goods
              </label>
            </div>
          </div>
        </div>

        {/* Select truck category */}
        {/* <div className="row select__truck">
                    <div className="login__right mt-2">
                        <p className='f-16 text-left'>Select category</p>
                    </div>
                    
                    <div className=" category__truck__container">
                        <Swiper slidesPerView={4} spaceBetween={0} mousewheel={true} className="mySwiper" navigation={true}>
                            <SwiperSlide>
                                <div className="each__truck category__trucks truck__active first__category__truck">
                                    <p className='neutral__paragraph'>Van</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="each__truck category__trucks">
                                    <p className='neutral__paragraph'>Box truck</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="each__truck category__trucks">
                                    <p className='neutral__paragraph'>Articulated</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="each__truck category__trucks last__category__truck">
                                    <p className='neutral__paragraph'>Container Truck</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="each__truck category__trucks">
                                    <p className='neutral__paragraph'>Box truck</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>                                   
                    </div>                    
                </div>  */}

        {/* Select truck type */}
        <div className="row select__truck">
          <div className="login__right mt-4">
            <p className="f-16 text-left">Select truck type</p>
          </div>

          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            mousewheel={true}
            className="mySwiper"
            navigation={true}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              526: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
          >
            {store?.allTruckTypes?.map((truck) => (
              <SwiperSlide>
                <div
                  className={
                    truck?.id === props?.truckId
                      ? 'each__truck  active_truck'
                      : 'each__truck vehicle__truck'
                  }
                  key={truck?.id}
                  name="truck_type_id"
                  onClick={() => {
                    props?.setTruckId(truck?.id);
                  }}
                  onChange={props?.handleFormData({ truck_type_id: truck?.id })}
                  value={truck?.id}
                >
                  <img src={truck?.icon_url} width="60px" height="60px" alt={truck?.name} />

                  <p className="neutral__paragraph p-0">
                    {truck?.name} ({truck?.capacity} tons)
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="d-flex justify-content-lg-center gap-5 mt-2">
          <Button onClick={props.prevPage} className="btn_clear" text={'Back'} />
          <Button onClick={nexStep} className="btn" text={'Continue'} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  booking: state.bookings,
  loading: state.home.loading,
});

export default connect(mapStateToProps)(BookingDescription);
