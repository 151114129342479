import instance from '../../axiosConfig';
import { toast } from 'react-toastify';
import {
  ACTIVATE_USER,
  ADD_BANK_DETAILS,
  DEACTIVATE_USER,
  GET_ALL_BANKS,
  MANAGE_USERS,
  UPDATE_PASSWORD,
  UPDATE_USERS,
  UPLOAD_PROFILE_PHOTO,
} from '../types/settingsType';

// upload profile image
export const uploadProfilePhoto = ({ photo }) => {
  return async (dispatch) => {
    await instance
      .post('/users/photo', photo)
      .then((response) => {
        console.log(response);
        dispatch({
          type: UPLOAD_PROFILE_PHOTO,
          payload: response.data.data,
        });
        toast.success('Your profile photo has been updated', {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error('oops! something went wrong', {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};

// update user details
export const updateUser =
  ({ name, phone }) =>
  async (dispatch) => {
    try {
      await instance.put('/users', { name, phone }).then((response) => {
        dispatch({
          type: UPDATE_USERS,
          payload: response.data,
        });
      });
      toast.success('Your profile has been updated', {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(err?.data?.message, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

// update password
export const updatePassword = (data) => async (dispatch) => {
  try {
    await instance.put('/users/password', data).then((response) => {
      dispatch({
        type: UPDATE_PASSWORD,
        payload: response.data,
      });
      toast.success(`${response.data.message}`, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  } catch (err) {
    toast.error(err?.data?.message, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

// activate user
export const activateUser = (data) => async (dispatch) => {
  try {
    await instance
      .put('/users/status/activate', {
        id: data,
      })
      .then((response) => {
        dispatch({
          type: ACTIVATE_USER,
          payload: response.data,
        });
        // toast.success(`${response.data.message}`, {
        //   position: 'top-right',
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      });
  } catch (err) {
    toast.error(err?.data?.message, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

// deactivate user
export const deactivateUser = (data) => async (dispatch) => {
  try {
    await instance
      .put('/users/status/deactivate', {
        id: data,
      })
      .then((response) => {
        dispatch({
          type: DEACTIVATE_USER,
          payload: response.data,
        });
        // toast.success(`${response?.data?.message}`, {
        //   position: 'top-right',
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      });
  } catch (err) {
    toast.error(err?.data?.message, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

// get all banks
export const getAllBanks = () => {
  return async (dispatch) => {
    await instance
      .get('banks/list')
      .then((response) => {
        dispatch({
          type: GET_ALL_BANKS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        toast.error(error?.data?.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};

// add bank account
export const addBankAccount = (formData, id) => async (dispatch) => {
  try {
    await instance.post(`companies/${id.id}/bank-details`, formData.formData).then((response) => {
      console.log(response);
      dispatch({
        type: ADD_BANK_DETAILS,
        payload: response.data,
      });
      toast.success('Your account information has been uploaded', {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  } catch (err) {
    toast.error(err.data.message, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

// manage users
export const getAllUsers = () => {
  return async (dispatch) => {
    await instance
      .get('users/all')
      .then((response) => {
        dispatch({
          type: MANAGE_USERS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        toast.error(error?.data?.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};
