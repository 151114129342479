import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { register } from '../../redux/actions/auth';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';
import './CreateAccount.css';
import { propTypes } from 'react-bootstrap/esm/Image';
import Pine from '../../components/Pine/Pine';

const SuccessfulSignUp = ({ register }) => {
  const [formData, setFormData] = useState({});

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  let togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleChange = (e) => {
    e.persist();
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
      name: firstName + ' ' + lastName,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    register({
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      role_id: formData.role_id,
      password: formData.password,
    });
  };

  let isAuthenticated = localStorage.getItem('isAuthenticated');

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="App">
      <div className="row">
        <Pine />

        <div className="col-md-7 text-center">
          <div className="mt-8">
            <div className="create__header">
              <h4 className="mt-4 text-center">Create your Pine account, 🚀</h4>
              <p className="text-secondary text-center f-16">Please, Sign up to continue</p>
            </div>
            <div className="col-md-8 mx-auto">
              <form className="form mt-4 pb-5" onSubmit={handleSubmit}>
                <div className="row g-2">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control login__form__control"
                        id="name"
                        placeholder="First name"
                        defaultValue={formData.name}
                        onChange={(e) => setFirstName(e.target.value)}
                        name="name"
                        required
                      />
                      <label htmlFor="name" className="login__label">
                        First name
                      </label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        defaultValue={formData.last_name}
                        onChange={(e) => setLastName(e.target.value)}
                        className="form-control login__form__control"
                        name="name"
                        id="last_name"
                        placeholder="Last name"
                      />
                      <label htmlFor="last_name" className="login__label">
                        Last name
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-floating mt-4">
                  <input
                    type="tel"
                    className="form-control login__form__control"
                    id="phone"
                    placeholder="Phone number"
                    name="phone"
                    defaultValue={formData.phone}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="phone" className="login__label">
                    Phone number
                  </label>
                </div>

                <div className="form-floating mt-4 mb-4">
                  <input
                    type="email"
                    className="form-control login__form__control"
                    id="email"
                    placeholder="jeremiah@ellie-logistics.com"
                    name="email"
                    onChange={handleChange}
                    defaultValue={formData.email}
                    required
                  />
                  <label htmlFor="email" className="login__label">
                    Email address
                  </label>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="custom-control custom-radio radio_item">
                      <input
                        type="radio"
                        id="owner"
                        value="1"
                        name="role_id"
                        className="custom-control-input"
                        onChange={handleChange}
                      />
                      <label className="custom-control-label" htmlFor="owner">
                        I'm a truck owner
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="custom-control custom-radio radio_item">
                      <input
                        value="4"
                        type="radio"
                        id="shipper"
                        name="role_id"
                        onChange={handleChange}
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="shipper">
                        I'm a shipper
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form mt-4">
                  <select
                    className="form-select form-select-lg p-3 login__form__control login__label"
                    aria-label="form-select-lg example"
                  >
                    <option defaultValue="As a business">As a business</option>
                    <option defaultValue="As an individual (personal)">
                      As an individual (personal)
                    </option>
                  </select>
                </div>

                <div className="form-floating mt-4">
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    className="form-control login__form__control"
                    id="password"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    defaultValue={formData.password}
                    required
                  />
                  <label htmlFor="password" className="login__label">
                    Create Password
                  </label>
                  <i onClick={togglePasswordVisibility} className="fas fa-eye eye"></i>
                </div>

                <div className="form mt-3">
                  <p className="form-check-label text-left ml-4 pb-4">
                    <input className="form-check-input" type="checkbox" id="checkbox" />
                    <label htmlFor="checkbox" className="ml-3">
                      <span className="text-secondary">I agree to Pine's </span>
                      <a
                        href="https://pinecargo.com/terms-and-conditions.html"
                        className="login__links"
                      >
                        Terms & conditions
                      </a>{' '}
                      and
                      <a className="login__links" href="https://pinecargo.com/privacy-policy.html">
                        Policy
                      </a>
                    </label>
                  </p>
                </div>

                <button type="submit" className="btn btn-login btn-block">
                  Create account
                </button>
                <div className="ytt">
                  <p className="form-check-label text-center mt-3">
                    <span className="text-secondary">Have an account already? </span>
                    <Link to="/" className="login__links">
                      Click here
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

SuccessfulSignUp.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: propTypes.bool,
};

const mapStateToProp = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProp, { register })(SuccessfulSignUp);
