import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import ShipperNav from '../../../components/shipperNav/ShipperNav';
import ActiveTrip from './activetrip/ActiveTrip';
import ConfirmTrip from './confirmTrip/ConfirmTrip';
import dayjs from 'dayjs';
import { connect, useDispatch, useSelector } from 'react-redux';
import { deleteTrip, getDeliveredTrip, getOngoingTrip } from '../../../redux/actions/trips';
import { Table } from 'antd';
import { getPagination } from '../../../redux/actions/booking';
import SearchBar from '../../../components/search/SearchBar';
import { loading } from '../../../redux/actions/loading';
import Loader from '../../../components/Loader/Loader';
import useWindowDimensions from '../../../hooks/useWindowResize';
import MobileCargoOngoing from './components/mobile_ongoing';
import MobileCargoDelivered from './components/mobile_completed';

const ShipperTrip = (props) => {
  const ongoingTrip = useSelector((state) => state?.trips.ongoingTrips?.data);
  const [tripId, setTripId] = useState([]);
  const [activeTrip, setActiveTrip] = useState(false);
  const [confirmTrip, setConfirmTrip] = useState(false);
  const dispatch = useDispatch();
  const [ongoingData, setOngoingData] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [searchOngoingTerm, setSearchOngoingTerm] = useState([]);
  // const [selectedTrip, setSelectedTrip] = useState('');
  const [deliveredData, setDeliveredData] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const deliveredStoreData = useSelector((state) => state?.trips.deliveredTrips?.data);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    dispatch(getOngoingTrip());
    dispatch(getDeliveredTrip());
    dispatch(loading(true));
  }, [dispatch]);

  useEffect(() => {
    if (deliveredStoreData) {
      setDeliveredData(deliveredStoreData);
      setSearchTerm(deliveredStoreData);
    }
    if (ongoingTrip) {
      setSearchOngoingTerm(ongoingTrip);
      setOngoingData(ongoingTrip);
    }
  }, [ongoingTrip, deliveredStoreData]);

  // function to handle search bar input
  const handleSearch = (e) => {
    let currValue = e.target.value;
    const deliveredFilteredData = searchTerm?.data.filter((entry) =>
      entry.pickup.includes(currValue.toLowerCase()),
    );
    setDeliveredData(deliveredFilteredData);

    const ongoingFilteredData = searchOngoingTerm?.data.filter((entry) =>
      entry.pickup.includes(currValue.toLowerCase()),
    );

    setOngoingData(ongoingFilteredData);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteTrip(e.target.id));
  };

  const renderStatus = () => {
    switch (true) {
      case 'ongoing':
        return <span className="booking__status_transit">Active</span>;
      case 'delivered':
        return <span className="booking__status_complete">Delivered</span>;
      default:
        return 'none';
    }
  };

  const columns = [
    {
      title: 'BOOKING ID',
      dataIndex: 'id',
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'created_at',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm A'),
    },
    {
      title: 'PICKUP LOCATION',
      dataIndex: 'pickup',
    },
    {
      title: 'ESTIMATED PRICE',
      dataIndex: 'price',
      render: (text) => `₦ ${text === null ? 0.0 : text}`,
    },
    {
      title: 'STATUS',
      key: 'accepted',
      render: () => <>{renderStatus()}</>,
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (id) => (
        <div className="dropdown">
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle className="table-button" id="dropdown-basic">
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Edit Trip</Dropdown.Item>
              <Dropdown.Item id={id.id} className="cancel" onClick={handleDelete}>
                Cancel Trip
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const handleBookingDetails = (id) => {
    setTripId(id);
    // setActiveTrip(true);
  };

  const { width } = useWindowDimensions();

  return (
    <>
      <ShipperNav />

      <div className="home">
        <div className="home__content">
          <div className=" bookings">
            <div className="table-responsive table-borderless mt-4">
              <div className=" booking__header">
                <div>
                  <h4 onClick={() => setConfirmTrip(true)}>Trips</h4>
                </div>
                <div className="d-lg-flex gap-3 justify-content-between align-items-center">
                  <SearchBar handleSearch={handleSearch} />

                  <div className="fleets_header">
                    <ul className="fleets_header_ul">
                      <li
                        className={toggleState === 1 ? ' fleets_active' : 'fleets__li'}
                        onClick={() => toggleTab(1)}
                      >
                        In transit
                      </li>
                      <li
                        className={toggleState === 2 ? ' fleets_active' : 'fleets__li'}
                        onClick={() => toggleTab(2)}
                      >
                        Completed
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {!ongoingTrip ? (
                <Loader />
              ) : (
                <>
                  {toggleState === 1 ? (
                    <>
                      {width < 1026 ? (
                        <MobileCargoOngoing
                          handleBookingDetails={handleBookingDetails}
                          renderStatus={renderStatus}
                        />
                      ) : (
                        <Table
                          loading={props?.loading}
                          columns={columns}
                          dataSource={ongoingData}
                          pagination={{
                            pageSize: 15,
                            // total: ongoingTrip?.total,
                            onChange: (page) => {
                              dispatch(getPagination(page));
                              // setDataSource(store.data)
                            },
                          }}
                          onRow={(rowIndex) => {
                            return {
                              onClick: () => {
                                handleBookingDetails(rowIndex.id);
                                setActiveTrip(true);
                              },
                            };
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {width < 1026 ? (
                        <MobileCargoDelivered
                          handleBookingDetails={handleBookingDetails}
                          renderStatus={renderStatus}
                        />
                      ) : (
                        <Table
                          loading={props?.loading}
                          columns={columns}
                          key={deliveredData?.data?.id}
                          dataSource={deliveredData}
                          pagination={{
                            pageSize: 15,
                            total: deliveredData?.total,
                            onChange: (page) => {
                              dispatch(getPagination(page));
                              // setDataSource(store.data)
                            },
                          }}
                          onRow={(rowIndex) => {
                            return {
                              onClick: () => {
                                handleBookingDetails(rowIndex.id);
                                setConfirmTrip(true);
                              },
                            };
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {activeTrip && (
        <ActiveTrip show={activeTrip} onHide={() => setActiveTrip(false)} tripId={tripId} />
      )}
      {confirmTrip && (
        <ConfirmTrip show={confirmTrip} onHide={() => setConfirmTrip(false)} tripId={tripId} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  trips: state.trips.allTrips,
  loading: state.home.loading,
});
export default connect(mapStateToProps)(ShipperTrip);
