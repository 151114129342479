import { LOADING } from '../types/loadingType';
import {
  DELETE_TRIP,
  DELIVERED_TRIP,
  GET_ALL_TRIPS,
  GET_SINGLE_TRIP,
  ONGOING_TRIP,
  PENDING_TRIP,
  QUEUED_TRIP,
} from '../types/tripTypes';

const initialState = {
  allTrips: [],
  singleTrip: {},
  queuedTrips: [],
  ongoingTrips: [],
  deliveredTrips: [],
  pendingTrips: [],
  loading: false,
};

function tripReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case GET_ALL_TRIPS:
      return {
        ...state,
        loading: false,
        allTrips: payload,
      };

    case DELIVERED_TRIP:
      return {
        ...state,
        loading: false,
        deliveredTrips: payload,
      };
    case QUEUED_TRIP:
      return {
        ...state,
        loading: false,
        queuedTrips: payload,
      };
    case PENDING_TRIP:
      return {
        ...state,
        loading: false,
        pendingTrips: payload,
      };
    case ONGOING_TRIP:
      return {
        ...state,
        loading: false,
        ongoingTrips: payload,
      };

    case GET_SINGLE_TRIP:
      return {
        ...state,
        loading: false,
        singleTrip: payload,
      };
    case DELETE_TRIP:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default tripReducer;
