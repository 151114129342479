import instance from '../../axiosConfig';
import { toast } from 'react-toastify';
import {
  ACCEPT_TRIP,
  ASSIGN_DRIVER,
  CALCULATE_FARE,
  GET_STATISTICS,
  GET_TRIPS,
  GET_USER_DETAILS,
  GO_OFFLINE,
  GO_ONLINE,
  REJECT_TRIP,
} from '../types/homeTypes';
import { loading } from './loading';

export const getUser = () => {
  return async (dispatch) => {
    await instance
      .get('/users/')
      .then((response) => {
        dispatch({
          type: GET_USER_DETAILS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getStatistics = () => {
  return async (dispatch) => {
    await instance
      .get('/users/statistics/')
      .then((response) => {
        dispatch({
          type: GET_STATISTICS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getTrips = () => {
  return async (dispatch) =>
    await instance
      .get('/trips?filter=pending')
      .then((res) => {
        dispatch(loading(false));
        dispatch({
          type: GET_TRIPS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(loading(false));
        console.log(err);
      });
};

export const goOnline = () => {
  return async (dispatch) => {
    await instance
      .post('/users/availability/online')
      .then((response) => {
        console.log(response);
        dispatch({
          type: GO_ONLINE,
          payload: response.data.data,
        });
        toast.success(`${response.data.data}`, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const goOffline = () => {
  return async (dispatch) => {
    await instance
      .post('/users/availability/offline')
      .then((response) => {
        dispatch({
          type: GO_OFFLINE,
          payload: response.data.data,
        });
        toast.success(`${response.data.data}`, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// calculate the distance between two points in latitude and longitude taking
export const calculateFare =
  ({ truck_type_id, tonnage, value_of_goods, distance }) =>
  async (dispatch) => {
    try {
      await instance
        .post('/payment/fare/calculate', {
          truck_type_id,
          tonnage,
          value_of_goods,
          distance,
        })
        .then((response) => {
          dispatch(loading(false));
          dispatch({
            type: CALCULATE_FARE,
            payload: response.data,
          });
        });
    } catch (err) {
      toast.error(err.data.message, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
export const bookingCalculateFare =
  ({ truck_type_id, tonnage, value_of_goods, distance }) =>
  async (dispatch) => {
    try {
      await instance
        .post('/payment/fare/calculate', {
          truck_type_id,
          tonnage,
          value_of_goods,
          distance,
        })
        .then((response) => {
          dispatch(loading(false));
          dispatch({
            type: CALCULATE_FARE,
            payload: response.data,
          });
        });
    } catch (err) {
      toast.error(err.data.message, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

export const acceptTrip = (id) => {
  return async (dispatch) => {
    await instance
      .post(`trips/${id}`)
      .then((response) => {
        dispatch({
          type: ACCEPT_TRIP,
          payload: response.data.data,
        });
        toast.success(`${response.data.data}`, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};

export const rejectTrip = (id) => {
  return async (dispatch) => {
    await instance
      .post(`trips/${id}/reject`)
      .then((response) => {
        dispatch({
          type: REJECT_TRIP,
          payload: response.data,
        });
        toast.success(`${response.data.data}`, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};

export const assignDriver = ({ id, driver }) => {
  return async (dispatch) => {
    await instance
      .post(`trips/${id}/driver`, { user_id: driver })
      .then((response) => {
        dispatch({
          type: ASSIGN_DRIVER,
          payload: response.data.data,
        });
        toast.success(`${response.data.data}`, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};

export const assignVehicle = ({ id, vehicle }) => {
  return async (dispatch) => {
    await instance
      .post(`trips/${id}/vehicle`, { vehicle_id: vehicle })
      .then((response) => {
        dispatch({
          type: ASSIGN_DRIVER,
          payload: response.data.data,
        });
        toast.success(`${response.data.data}`, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};
