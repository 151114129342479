import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import close from '../../../../../assets/settings/close.svg';
import { activateUser } from '../../../../../redux/actions/settings';

const ActivateUserModal = (props) => {
  const dispatch = useDispatch();

  const handleActivateUser = () => {
    dispatch(activateUser(props?.userId));
    props.onHide();
  };

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3>Activate User</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-center">Are you sure you want to Activate this account?</p>
          </div>

          <div className="reject__footer ">
            <button onClick={props.onHide} className="btn btn__reject mr-4">
              Cancel
            </button>
            <button className="btn btn__accept" onClick={handleActivateUser}>
              {' '}
              Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ActivateUserModal;
