export const CREATE_COMPANY = 'CREATE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const INVITE_USERS_COMPANY = 'INVITE_USERS_COMPANY';
export const ACCEPT_INVITE_COMPANY = 'ACCEPT_INVITE_COMPANY';
export const FETCH_COMPANY_USERS = 'FETCH_COMPANY_USERS';
export const UPLOAD_COMPANY_LOGO = 'UPLOAD_COMPANY_LOGO';
export const UPLOAD_COMPANY_CREDENTIALS = 'UPLOAD_COMPANY_CREDENTIALS';
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';
export const SEND_INVITES  = 'SEND_INVITE';
export const GET_USER_ROLES = 'GET_USER_ROLES';

