import React from 'react';

const SearchBar = ({ handleSearch, text }) => {
  return (
    <form className="search">
      <button type="button" className="btn__search">
        <img
          src="https://img.icons8.com/ios-glyphs/25/A5ABC1/search--v1.png"
          id="search__bar_img"
          alt="search"
        />
      </button>
      <input
        type="text"
        placeholder={text}
        onChange={(e) => {
          handleSearch(e);
        }}
        name="search"
        id="search__bar"
      />
    </form>
  );
};

export default SearchBar;
