import React from 'react';
import PropTypes from 'prop-types';

const Select = ({ options, className, label }) => {
  return (
    <div className="form mt-4 form-floating ">
      <select
        className={`form-select form-select-lg p-3 login__form__control login__label ${className}`}
        aria-label="form-select-lg"
      >
        {options.map((option, index) => (
          <option key={index} defaultValue={option}>
            {option}
          </option>
        ))}
      </select>
      <label htmlFor="floatingTextarea" className="login__label">
        {label}
      </label>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.array,
};

export default Select;
