import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import close from '../../../../../assets/settings/close.svg';
import { addBankAccount, getAllBanks } from '../../../../../redux/actions/settings';
import { ToastContainer } from 'react-toastify';

const AddBankAccount = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.setting.allBanks);
  const [formData, setFormData] = useState({});
  const companyId = useSelector((state) => state.home.user.company_id);
  const [bankCode, setBankCode] = useState('');
  //   const [bankName, setBankName] = useState('');

  useEffect(() => {
    dispatch(getAllBanks());
  }, [dispatch]);

  const handleChange = (e) => {
    e.persist();
    setBankCode(e.target.value);
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
      bank_code: bankCode,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addBankAccount({ formData }, { id: companyId }));
  };

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3>Add bank account</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-left">Cash withdrawn will be paid into this bank account</p>
            <form className="form mt-4" onSubmit={handleSubmit}>
              <div className="form-floating mt-4">
                <select
                  className="form-select form-select-lg p-3 login__form__control login__label"
                  name="bank_name"
                  onChange={handleChange}
                >
                  <option>Select Bank</option>
                  {store?.data?.Banks.map((bank) => (
                    <option
                      id={bank?.Code}
                      key={bank?.Id}
                      value={bank?.Name}
                      data-code={bank?.Code}
                    >
                      {bank?.Name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-floating mt-4">
                <input
                  type="text"
                  className="form-control login__form__control"
                  id="floatingInputGrid"
                  placeholder="Account number"
                  name="account_number"
                  required
                  onChange={handleChange}
                />
                <label htmlFor="floatingInputGrid" className="login__label">
                  Account number
                </label>
              </div>

              {/* <div className="form-floating mt-4">
                                <input type="text" className="form-control login__form__control" id="floatingInputGrid" placeholder="Account name"  
                                required disabled
                                name='bank_code'
                                />
                                <label htmlFor="floatingInputGrid" className='login__label'>Account name</label>
                            </div> */}
              <button type="submit" className="btn btn-login btn-block mt-5">
                Save
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AddBankAccount;
