import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import close from '../../../../../../../assets/settings/close.svg';
import editImg from '../../../../../../../assets/shipperHome/edit.svg';

const EditPrice = (props) => {
  const fare = useSelector((state) => state?.home?.calculateFare?.data);

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3>Edit price</h3>
          <button onClick={props.onHide} className="btn btn__reject">
            Done
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-left">Edit the price you want to pay.</p>
            <div className="estimate_delivery">
              <h6>
                ₦{props.editedPrice === '' ? fare?.price : props.editedPrice}{' '}
                <span className="ml-3">
                  <img src={editImg} alt="edit" />
                </span>
              </h6>
              <span>Estimated delivery fare</span>
            </div>
            <form className="form mt-5">
              <h6>Enter your price you want to pay</h6>
              <div className="form-floating mt-4">
                <input
                  type="text"
                  className="form-control login__form__control"
                  id="floatingInputGrid"
                  placeholder="Edited price"
                  required
                  defaultValue={props?.editedPrice}
                  onChange={(e) => props?.setEditedPrice(e.target.value)}
                />
                <label htmlFor="floatingInputGrid" className="login__label">
                  Edited price
                </label>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditPrice;
