import instance from '../../axiosConfig';
import {
  DELETE_SPECIFIC_VEHICLE,
  GET_SPECIFIC_VEHICLE,
  GET_ALL_VEHICLES,
  GET_ALL_TRUCKTYPES,
  GET_ALL_DRIVERS,
  CREATE_NEW_VEHICLE,
  UPLOAD_VEHICLE_CREDENTIALS,
} from '../types/fleetTypes';
import { toast } from 'react-toastify';
import { LOADING } from '../types/loadingType';
import { loading } from './loading';

// get all vehicles
export const getAllVehicles = () => {
  return async (dispatch) => {
    await instance
      .get('/vehicles')
      .then((response) => {
        dispatch(loading(false));
        dispatch({
          type: GET_ALL_VEHICLES,
          payload: response.data.data,
        });
        dispatch(loading(false));
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};

// delete each vehicle
export const deleteVehicle = (id) => {
  return async (dispatch) => {
    await instance
      .delete(`/vehicles/${id}`)
      .then((response) => {
        dispatch({
          type: DELETE_SPECIFIC_VEHICLE,
          payload: response.data.data,
        });
        toast.success(`${response.data.message}`, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};

// get specific vehicle
export const getEachVehicle = (id) => {
  return async (dispatch) => {
    await instance
      .get(`/vehicles/${id}`)
      .then((response) => {
        dispatch({
          type: GET_SPECIFIC_VEHICLE,
          payload: response?.data?.data,
        });
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};

//create a vehicle
export const createVehicle = (formData) => async (dispatch) => {
  try {
    await instance.post('/vehicles', formData).then((response) => {
      dispatch({
        type: CREATE_NEW_VEHICLE,
        payload: response.data,
      });
      toast.success(`${response.data.message}`, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  } catch (err) {
    toast.error(err.data.message, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

// upload vehicle credentials
export const uploadVehicleCredential =
  ({ id, formData }) =>
  async (dispatch) => {
    console.log(id);
    try {
      await instance.post(`/vehicles/${id}/files`, formData).then((response) => {
        console.log(response);
        dispatch({
          type: UPLOAD_VEHICLE_CREDENTIALS,
          payload: response.data,
        });
        toast.success(`${response.data.message}`, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    } catch (err) {
      toast.error(err.data.message, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

// get truck types
export const getTruckTypes = () => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    await instance
      .get('/truck-types')
      .then((response) => {
        dispatch({
          type: GET_ALL_TRUCKTYPES,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};

//
export const getDrivers = () => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    await instance
      .get('/users/all?type=driver')
      .then((response) => {
        dispatch({
          type: GET_ALL_DRIVERS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};
// create a vehicle
