import React, { useState } from 'react';
import Navbar from '../../../components/mainNav/Navbar';
import createIcon from '../../../assets/dispatch/create.svg';
import greenArrowIcon from '../../../assets/dispatch/green_arrow.svg';
import calcImg from '../../../assets/home/calc.png';
import SearchBar from '../../../components/search/SearchBar';
import { Table } from 'antd';
import { columns } from './components/columns';
import AcceptModal from '../../truckPages/Home/acceptmodal/AcceptModal';
import RejectModal from '../../truckPages/Home/rejected/RejectModal';
import BookingDetail from '../../truckPages/Home/bookingDetail/BookingDetail';
import { Link } from 'react-router-dom';
import OneOffBooking from '../../shipperPages/shipperHome/bookingType/oneOffBooking/OneOffBooking';

const DispatchHome = () => {
  const [tripId, setTripId] = useState('');
  const [bookingDetails, setBookingDetails] = useState(false);
  const [acceptModalShow, setAcceptModalShow] = useState(false);
  const [rejectModalShow, setRejectModalShow] = useState(false);
  const [createBooking, setCreateBooking] = useState(false);
  const totalPage = 9;
  const handleSearch = (e) => {};
  const dataSource = [];

  const handleBookingDetails = (id) => {
    setTripId(id);
    setBookingDetails(true);
  };
  return (
    <div>
      <Navbar />
      <div className="home">
        <div className="home__content">
          {/*   CREATE DISPATCH AND TOTAL TRIPS */}
          <div className=" home__grid">
            <div className="home_content_col home_content_col__left">
              <div className="home_content_grid">
                <div>
                  <select
                    className="form-select ml-3 mt-2"
                    id="home__select"
                    aria-label=".form-select-lg example"
                  >
                    <option>Today</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                  <div className="total__amount">
                    <p style={{ 'font-size': '16px' }}>Total Amount earned</p>
                    {/*  <h5>₦{props?.home?.earnings}</h5> */}
                  </div>
                </div>
                <div className=" total__trip">
                  <div className="total__amount total__trips">
                    <p>Total trips so far</p>
                    {/* <h5>{props?.home?.trips}</h5> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="home__grid align-items-center gap-4 m-0 p-0">
              <div className="first-row p-15">
                <div
                  className=" account__online"
                  onClick={() => {
                    setCreateBooking(true);
                  }}
                >
                  <img src={createIcon} alt="create trip" />
                  <div className="account_online__content">
                    <h6>Create A Dispatch Order</h6>
                    <p>Create deliveries and assign to your drivers for dispatch.</p>
                  </div>
                  <img src={greenArrowIcon} alt="green arrow" />
                </div>
              </div>
              <div className="first-row">
                <Link to="/pricing">
                  <div
                    className=" account__online justify-content-between align-items-center"
                    // onClick={() => setCalcFareModal(true)}
                  >
                    <div className="d-flex">
                      {' '}
                      <img src={calcImg} alt="calculate fare" className="mr-4 object_fit" />
                      <div className="account_online__content">
                        <h6>Pricing</h6>
                        <p>Set your price</p>
                      </div>
                    </div>

                    <img src={greenArrowIcon} alt="arrow" />
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className=" bookings">
            <div className="table-responsive table-borderless mt-4">
              <div className=" booking__header">
                <div>
                  <h4>Bookings ({totalPage})</h4>
                </div>
                <div className=" align-items-center">
                  <SearchBar handleSearch={handleSearch} text="Search by description" />
                </div>
              </div>{' '}
              <div>
                <Table
                  // loading={props?.loading}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    pageSize: 15,
                    total: totalPage,
                    // current: currentPage,
                    // onChange: (page) => {
                    //   setCurrentPage(page);
                    //   dispatch(getPagination(currentPage));
                    // },
                  }}
                  onRow={(rowIndex) => {
                    return {
                      onClick: () => {
                        handleBookingDetails(rowIndex.id);
                      },
                    };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {bookingDetails && (
        <BookingDetail
          show={bookingDetails}
          onHide={() => setBookingDetails(false)}
          tripId={tripId}
          selectedTrip="pending"
          setAcceptModalShow={setAcceptModalShow}
          setRejectModalShow={setRejectModalShow}
        />
      )}
      {/* MODAL FOR ACCEPTING AND REJECTING A REQUEST */}
      {acceptModalShow && (
        <AcceptModal show={acceptModalShow} onHide={() => setAcceptModalShow(false)} id={tripId} />
      )}

      {rejectModalShow && (
        <RejectModal show={rejectModalShow} onHide={() => setRejectModalShow(false)} id={tripId} />
      )}

      {createBooking && (
        <OneOffBooking show={createBooking} onHide={() => setCreateBooking(false)} />
      )}
    </div>
  );
};

export default DispatchHome;
