import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import './style.css';
import close from './Close.svg';
import { useDispatch, useSelector } from 'react-redux';
import InviteSuccess from '../InviteSuccess/InviteSuccess.jsx';
import { ToastContainer } from 'react-toastify';
import { getAllRoles, sendInvites } from '../../../redux/actions/company';

const InviteTeam = (props) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({});
  const store = useSelector((state) => state?.company?.allRoles);
  const [addStaff, setAddStaff] = useState([]);
  const i = 3;
  const handleChange = (e) => {
    e.persist();
    setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = [{ people: formData }];
    console.log(payload);
    dispatch(sendInvites(formData));
  };

  const AddStaff = () => {
    return (
      <div className="row g-2 mt-2">
        <div className="col-md">
          <div className="form-floating">
            <input
              type="text"
              className="form-control login__form__control"
              id="floatingInputGrid"
              placeholder="Email or phone number"
              required
              name={`people[${i}][identifier]`}
              onChange={handleChange}
            />
            <label htmlFor="floatingInputGrid" className="login__label">
              Email or phone number
            </label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating">
            <select
              className="form-select form-select-lg p-3 login__form__control login__label"
              onChange={handleChange}
              name={`people[${i}]role_id`}
            >
              <option>Choose a role</option>
              {store?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const handleAddStaffClick = () => {
    setAddStaff(addStaff.concat(<AddStaff key={addStaff.length} />));
  };

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3>Invite your team</h3>
          <button
            className="send_invite_btn"
            onClick={handleSubmit}
            //onClick={() => setModalShow(true)}
          >
            Send invite
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-left">Invite all your team members.</p>
            <form className="form mt-4" onSubmit={handleSubmit} name="people">
              <div className="row g-2">
                <div className="col-md">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control login__form__control"
                      id="floatingInputGrid"
                      placeholder="Email or phone number"
                      name={`people[${i}][identifier]`}
                      required
                      onChange={handleChange}
                    />
                    <label htmlFor="floatingInputGrid" className="login__label">
                      Email or phone number
                    </label>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <select
                      className="form-select form-select-lg p-3 login__form__control login__label"
                      onChange={handleChange}
                      name={`people[${i}]role_id`}
                    >
                      <option>Choose a role</option>
                      {store?.map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="invite__line"></div>

              {addStaff}

              <div className="ytt">
                <p className="form-check-label mt-3" onClick={handleAddStaffClick}>
                  <span className="login__links">+ Add more staff</span>
                </p>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <InviteSuccess show={modalShow} onHide={() => setModalShow(false)} />
      <ToastContainer />
    </>
  );
};

export default InviteTeam;
