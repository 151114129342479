import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import address from '../../../../assets/booking/address.svg';
import close from '../../../../assets/trip/close.svg';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getSingleTrip } from '../../../../redux/actions/trips';
import dayjs from 'dayjs';
import { loading } from '../../../../redux/actions/loading';
import Map from '../../../../components/GoogleMap/GoogleMap';
import Loader from '../../../../components/Loader/Loader';
import { getAllVehicles } from '../../../../redux/actions/fleet';

const BookingDetail = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.trips?.singleTrip);
  const allVehicles = useSelector((state) => state?.fleets?.allVehicles?.data);

  useEffect(() => {
    dispatch(loading(true));
    dispatch(getSingleTrip(props?.tripId));
    dispatch(getAllVehicles());
  }, [props?.tripId, dispatch]);

  const renderStatus = () => {
    switch (props?.selectedTrip) {
      case 'queued':
        return <span className="booking__status_queued">Queued</span>;
      case 'pending':
        return <span className="booking__status_new">New</span>;
      case 'ongoing':
        return <span className="booking__status_transit">In transit</span>;
      case 'delivered':
        return <span className="booking__status_complete">Delivered</span>;
      default:
        return 'none';
    }
  };

  const handleAccept = () => {
    props?.setAcceptModalShow(true);
    props?.onHide();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    props.onHide();
    props?.setRejectModalShow(true);
  };

  return (
    <>
      {store && props?.loading ? (
        <Loader />
      ) : (
        <Modal {...props} size="lg">
          <Modal.Body className="show-grid">
            <div className="booking__details">
              <div className="booking_detail__content">
                {/* BOOKING DETAIL HEADER */}
                <div className="booking_detail__header">
                  <div className="booking__name">
                    {/* <img className='mr-3' src={nestle} alt="nestle" /> */}
                    {/* <div>
                      <h6>{props?.singleTrip?.recipient?.name}</h6>
                      <div className="booking__small">
                        <small>
                          <a
                            style={{ 'text-decoration': 'none', color: '#939EAD' }}
                            href={`tel:+${props?.singleTrip?.recipient?.contact}`}
                          >
                            {props?.singleTrip?.recipient?.contact}
                          </a>
                        </small>
                      </div>
                    </div> */}
                  </div>
                  {renderStatus()}
                </div>
                <div className="booking__line"></div>

                {/* BOOKING DETAILS BODY */}
                <div className="booking_body__container">
                  <div className="booking__detail__body">
                    <h6 className="booking__body__title">Booking ID</h6>
                    <p className="booking__body__response">{props?.singleTrip?.id}</p>
                  </div>

                  <div className="booking__detail__body mt-3">
                    <h6 className="booking__body__title">Date & time</h6>
                    <p className="booking__body__response">
                      {dayjs(props?.singleTrip?.recipient?.created_at).format('DD/MM/YYYY')}, &nbsp;
                      {dayjs(props?.singleTrip?.recipient?.created_at).format('h:mm A ')}
                    </p>
                  </div>

                  <div className="booking__detail__body mt-3">
                    <h6 className="booking__body__title">Nature of goods</h6>
                    <p className="booking__body__response">{props?.singleTrip?.goods?.name}</p>
                  </div>

                  <div className="booking__detail__body mt-3">
                    <h6 className="booking__body__title">Value of goods</h6>
                    <p className="booking__body__response">
                      ₦{props?.singleTrip?.value_of_goods?.toLocaleString()}
                    </p>
                  </div>

                  <div className="booking__detail__body mt-3">
                    <h6 className="booking__body__title">Estimated fare</h6>
                    <p className="booking__body__response">₦{props?.singleTrip?.price || 0.0}</p>
                  </div>

                  <div className="booking__address">
                    <div>
                      <img src={address} alt="address" />
                    </div>
                    <div>
                      <>
                        {' '}
                        <div className="book__address__pickup ml-3">
                          <h6>{store?.pickup}</h6>
                        </div>
                        <div className="book__address__pickup ml-3 mt-4">
                          <h6>{store?.delivery}</h6>
                        </div>
                      </>
                    </div>
                  </div>

                  {/* <div className="booking__contact__body mt-3">
                  <h6 className="booking__body__title">Contact</h6>
                  <div className="booking__contact__detail">
                    <div className="booking__contact__detail__image">
                      <img
                        src={'https://img.icons8.com/material/24/000000/user-male-circle--v1.png'}
                        alt="recipient contact"
                      />
                    </div>
                    <div className="booking__contact__detail__name">
                      <h6>{props?.singleTrip?.recipient?.name}</h6>
                      <a href={`tel:+${props?.singleTrip?.recipient?.contact}`}>
                        {props?.singleTrip?.recipient?.contact}
                      </a>
                    </div>
                  </div>
                </div> */}
                </div>

                {/* BOOKING FOOTER / BUTTONS */}
                <div className="booking_bottom_line"></div>
                <div className="booking__footer">
                  <button
                    className="btn btn__reject"
                    id={props?.singleTrip?.id}
                    onClick={handleDelete}
                  >
                    Reject request
                  </button>
                  <button className="btn btn-login" onClick={() => handleAccept()}>
                    Accept Request{' '}
                  </button>
                </div>
              </div>

              <div className="google__maps">
                <img
                  onClick={props.onHide}
                  src={close}
                  alt="close"
                  className="close_transit d-flex m-4"
                />

                {/* BOOKING GOOGLE MAP */}
                {store?.pickup && props?.loading ? (
                  <Loader />
                ) : (
                  <div style={{ width: '100%', height: '100%' }}>
                    <Map pickup={store?.pickup} delivery={store?.delivery} />
                  </div>
                )}

                <div className="driver_content">
                  <div className="driver_content__header justify-content-start">
                    <img
                      src={
                        allVehicles?.filter((truck) => truck?.id === store?.truck_type_id)[0]
                          ?.icon_url
                      }
                      className="driver_content__icon"
                      alt="rating"
                    />
                    <div className="driver_content__header__text ">
                      <h6>
                        {
                          allVehicles?.filter((truck) => truck?.id === store?.truck_type_id)[0]
                            ?.name
                        }
                      </h6>
                      <small>{store?.tonnage}</small>
                    </div>
                  </div>
                </div>

                {/* <div className="driver_content">
                  <div className="driver_content__header">
                    <img
                      src={
                        store?.recipient?.avatar_url ||
                        'https://img.icons8.com/material-outlined/24/000000/user-male-circle.png'
                      }
                      className="driver_content__icon"
                      alt="profile"
                    />
                    <div className="driver_content__header__text">
                      <h6>{props?.singleTrip?.user?.name}</h6>
                      <small>
                        <a href={`tel:+${props?.singleTrip?.user?.phone}`}>
                          {props?.singleTrip?.user?.phone}
                        </a>
                      </small>
                    </div>
                    <div className="driver_content__header__text">
                      <h6>{props?.singleTrip?.vehicle?.brand || 'Truck'}</h6>
                      <small>{props?.singleTrip?.vehicle?.license_number}</small>
                    </div>
                    <div className="driver_content__header__text">
                      <h6>
                        {props?.singleTrip?.vehicle?.rate_id || 1}
                        <span>
                          <img
                            src="https://img.icons8.com/material/16/29CC92/star--v1.png"
                            alt="rating"
                          />
                        </span>
                      </h6>
                      <small>Rating</small>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* MODAL FOR ACCEPTING AND REJECTING A REQUEST */}
    </>
  );
};

const mapStateToProps = (state) => ({
  singleTrip: state.trips.singleTrip,
  loading: state.home.loading,
});

export default connect(mapStateToProps)(BookingDetail);
