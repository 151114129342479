import React from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import sucessImg from '../../../../assets/home/success.png'
import './acceptModal.css'

const SuccessModal = (props) => {
    return (
        <>
             <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                
                <Modal.Body>
                    <div className="container login__right pb-5">
                            <div class="mt-10 text-center">
                                <img src={sucessImg} alt="Envelope" className="evenlope" />
                                <h4 class="mt-4 header__text">Request accepted!</h4>
                                <p class="verify-text">You have successfully accepted this booking request and the driver will be notified so they can start preparing for the trip.</p>                
                            </div>
                                
                            <div className="mt-5 text-center">
                                <button type="submit" className="btn verify-button send_invite_btn"
                                >
                                View trip details
                                </button>

                                <div className="ytt">
                                    <p className="form-check-label text-center mt-3 accepted__link">
                                         <Link to="/" className='login__links'>
                                         Continue to bookings
                                        </Link>
                                    </p>
                                </div>
                            </div>
                    </div>
                </Modal.Body>
            </Modal> 
        </>
    )
}

export default SuccessModal
