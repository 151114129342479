import instance from '../../axiosConfig';
import { toast } from 'react-toastify';
import {
  CREATE_COMPANY,
  CREATE_COMPANY_FAIL,
  GET_USER_ROLES,
  SEND_INVITES,
  UPDATE_COMPANY,
} from '../types/companyTypes';

//CREATE COMPANY
export const createCompany = (formData) => async (dispatch) => {
  try {
    const res = await instance.post('/companies', formData);
    toast.success('Your company has been created', {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({
      type: CREATE_COMPANY,
      payload: res.data,
    });
    window.location.href = '/thanksforsigning';
  } catch (err) {
    toast.error(err.data.message, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    dispatch({
      type: CREATE_COMPANY_FAIL,
    });
  }
};

// update company details
export const updateCompany = (formData, id) => async (dispatch) => {
  try {
    const res = await instance.put(`/companies/${id.id}`, formData.formData);
    toast.success('Your company has been updated', {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({
      type: UPDATE_COMPANY,
      payload: res.data,
    });
    //window.location.href = '/thanksforsigning';
  } catch (err) {
    toast.error(err.data.message, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    dispatch({
      type: CREATE_COMPANY_FAIL,
    });
  }
};

// get all user types
export const getAllRoles = () => {
  return async (dispatch) => {
    await instance
      .get('/roles')
      .then((response) => {
        dispatch({
          type: GET_USER_ROLES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// send invites
export const sendInvites = (formData) => async (dispatch) => {
  console.log(formData);
  try {
    await instance
      .post('users/invite', {
        formData,
      })
      .then((response) => {
        console.log(response);
        dispatch({
          type: SEND_INVITES,
          payload: response.data,
        });
        toast.success('', {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    // window.location.href = '/thanksforsigning';
  } catch (err) {
    toast.error(err.data.message, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    dispatch({
      type: CREATE_COMPANY_FAIL,
    });
  }
};
