import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwiperSlide, Swiper } from 'swiper/react/swiper-react';
import Button from '../../../../../../components/Button/Button';
import { getTruckTypes } from '../../../../../../redux/actions/fleet';

const AddVariableWage = () => {
  const store = useSelector((state) => state?.fleets);
  const [truckId, setTruckId] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTruckTypes());
  }, [dispatch]);

  return (
    <form>
      <div className="form-floating my-4">
        <input
          type="text"
          className="form-control login__form__control"
          id="price_per_ton"
          placeholder="price_per_ton"
          name="price_per_ton"
          // onChange={handleChange}
          required
        />
        <label htmlFor="price_per_ton" className="login__label">
          Price Per ton
        </label>
      </div>
      <div className="form-floating my-4">
        <input
          type="text"
          className="form-control login__form__control"
          id="price_per_kilometer"
          placeholder="price_per_kilometer"
          name="price_per_kilometer"
          // onChange={handleChange}
          required
        />
        <label htmlFor="price_per_kilometer" className="login__label">
          Price per kilometer
        </label>
      </div>

      <div className="row select__truck">
        <div className="login__right mt-4">
          <p className="f-16 text-left">Select truck type</p>
        </div>

        <Swiper
          slidesPerView={4}
          spaceBetween={10}
          mousewheel={true}
          className="mySwiper"
          navigation={true}
          breakpoints={{
            546: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {store?.allTruckTypes?.map((truck) => (
            <SwiperSlide key={truck?.id}>
              <div
                className={
                  truck?.id === truckId ? 'each__truck  active_truck' : 'each__truck vehicle__truck'
                }
                key={truck?.id}
                name="truck_type_id"
                value={truck?.id}
                onClick={() => {
                  setTruckId(truck?.id);
                  // calculateDistance();
                }}
              >
                <img src={truck?.icon_url} width="60px" height="60px" alt={truck?.name} />
                <p className="neutral__paragraph p-0">
                  {truck?.name} ({truck?.capacity} tons)
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="my-4">
          <Button text={'Add'} />
        </div>
      </div>
    </form>
  );
};

export default AddVariableWage;
