import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";

const LoaderMini = () => {
  return (
    <div>
        <ClipLoader 
            height="20"
            width="30"
            margin='auto'
            color='white'
            ariaLabel='loading'
            center='true'
            strokeWidthSecondary={3}
            secondaryColor="white"
            strokeWidth={6}
        />
    </div>
  )
}

export default LoaderMini