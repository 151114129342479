import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import address from '../../../../assets/home/address.svg';
import check from '../../../../assets/trip/check.svg';
import close from '../../../../assets/trip/close.svg';
import '../trip.css';
import { useDispatch, useSelector } from 'react-redux';
import { loading } from '../../../../redux/actions/loading';
import { getSingleTrip } from '../../../../redux/actions/trips';
import dayjs from 'dayjs';

const CompletedTrip = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.trips?.singleTrip);

  useEffect(() => {
    dispatch(loading(true));
    dispatch(getSingleTrip(props?.tripId));
  }, [props?.tripId, dispatch]);

  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Body className="show-grid">
          <div className="booking__details">
            <div className="booking_detail__content">
              {/* BOOKING DETAIL HEADER */}
              <div className="booking_detail__header">
                <div className="booking__name">
                  <img
                    className="mr-3"
                    src={
                      store?.recipient?.avatar_url ||
                      'https://img.icons8.com/material-outlined/44/000000/user-male-circle.png'
                    }
                    alt={store?.recipient?.name}
                  />
                  <div>
                    <h6>{store?.recipient?.name}</h6>
                    <div className="booking__small">
                      <a
                        style={{ 'text-decoration': 'none', color: '#939EAD' }}
                        href={`tel:+${props?.singleTrip?.recipient?.contact}`}
                      >
                        {store?.recipient?.contact}
                      </a>
                    </div>
                  </div>
                </div>
                <p className="booking__status_complete">Complete</p>
              </div>

              {/* BOOKING DETAILS BODY */}
              <div className="booking_body__container">
                <div className="booking__detail__body">
                  <h6 className="booking__body__title">Booking ID</h6>
                  <p className="booking__body__response">#{store?.id}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Date & time</h6>
                  <p className="booking__body__response">
                    {dayjs(props?.singleTrip?.recipient?.created_at).format('DD/MM/YYYY')}, &nbsp;
                    {dayjs(props?.singleTrip?.recipient?.created_at).format('h:mm A ')}
                  </p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Nature of goods</h6>
                  <p className="booking__body__response">{store?.goods?.name}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Value of goods</h6>
                  <p className="booking__body__response">₦{store?.value_of_goods || 0}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Estimated fare</h6>
                  <p className="booking__body__response">₦{store?.price || 0.0}</p>
                </div>

                {/* BOOKING ADDRESS */}
                <div className="booking__address">
                  <div>
                    <img src={address} alt="address" />
                  </div>
                  <div>
                    <div className="book__address__pickup ml-3">
                      <h6>{store?.pickup}</h6>
                    </div>
                    <div className="book__address__pickup ml-3 mt-4">
                      <h6>{store?.delivery}</h6>
                    </div>
                  </div>
                </div>
              </div>

              {/* BOOKING FOOTER / BUTTONS */}
              <div className="booking__rating_container">
                <h5>TRIP RATING</h5>
                <div className="booking__rating">
                  <div className="booking__rating__stars d-flex align-items-center">
                    <img
                      src="https://img.icons8.com/material/24/FACF19/star--v1.png"
                      alt="rating"
                    />
                    <img
                      src="https://img.icons8.com/material/24/FACF19/star--v1.png"
                      alt="rating"
                    />
                    <img
                      src="https://img.icons8.com/material/24/FACF19/star--v1.png"
                      alt="rating"
                    />
                    <img
                      src="https://img.icons8.com/material/24/939EAD/star--v1.png"
                      alt="rating"
                    />
                    <img
                      src="https://img.icons8.com/material/24/939EAD/star--v1.png"
                      alt="rating"
                    />
                    <span>very Good</span>
                  </div>
                </div>
                <div className="trip_rating">
                  <ul className="trip_rating__stars">
                    <li>Navigation</li>
                    <li>Communication</li>
                    <li>Timeliness</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="completed_info">
              <img onClick={props.onHide} src={close} alt="close" className="close d-flex m-4" />

              <div className="completed_info__header text-center">
                <img
                  src={
                    store?.driver?.icon_url ||
                    'https://img.icons8.com/material-outlined/44/000000/user-male-circle.png'
                  }
                  alt="profile"
                />
                <h6>{store?.driver?.name}</h6>
                <small>
                  {' '}
                  <a
                    style={{ 'text-decoration': 'none', color: '#939EAD' }}
                    href={`tel:+${store?.driver?.phone}`}
                  >
                    {store?.driver?.phone}
                  </a>
                </small>
                <br />
                <p className="d-lg-inline-flex">
                  4.8{' '}
                  <img
                    src="https://img.icons8.com/material/16/29CC92/star--v1.png"
                    alt="rating"
                    className="ml-1 mr-1"
                  />{' '}
                  <span>Rating</span>{' '}
                </p>
              </div>
              <div className="completed_info__body">
                <div className="complete_info-row row text-center">
                  <div className="col-md-6 complete_info-row_col complete_info-row_col_right">
                    <h6>Date</h6>
                    <small> {dayjs(store?.ended_at).format('DD/MM/YYYY')}</small>
                  </div>
                  <div className="col-md-6 complete_info-row_col">
                    <h6>Time</h6>
                    <small>{dayjs(store?.ended_at).format('h:mm A ')}</small>
                  </div>
                </div>
                <div className="completed_info__body__content">
                  <div className="booking__detail__body mt-3">
                    <h6 className="booking__body__title">Vehicle Name</h6>
                    <p className="booking__body__response">{store?.vehicle?.brand}</p>
                  </div>
                  <div className="booking__detail__body mt-3">
                    <h6 className="booking__body__title">Vehicle No.</h6>
                    <p className="booking__body__response">{store?.vehicle?.license_number}</p>
                  </div>
                  <div className="booking__detail__body mt-3">
                    <h6 className="booking__body__title">Trip Distance</h6>
                    <p className="booking__body__response">{store?.distance}KM</p>
                  </div>
                  <div className="booking__detail__body mt-3">
                    <h6 className="booking__body__title">Trip Duration</h6>
                    <p className="booking__body__response">
                      {dayjs(store?.started_at).format('h:mm A ') -
                        dayjs(store?.ended_at).format('h:mm A ')}{' '}
                    </p>
                  </div>
                </div>
                <div className="completed_info_body_footer text-center">
                  <p>
                    This trip has been completed <img className="ml-2" src={check} alt="complete" />{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompletedTrip;
