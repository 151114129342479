import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { SwiperSlide, Swiper } from 'swiper/react/swiper-react';
import Button from '../../../../../../components/Button/Button';
import { getTruckTypes } from '../../../../../../redux/actions/fleet';

const AddFixedWage = () => {
  const google = window.google;
  const [pickup, setPickup] = useState('');
  const [delivery, setDelivery] = useState('');
  const [truckId, setTruckId] = useState('');

  const store = useSelector((state) => state?.fleets);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTruckTypes());
  }, [dispatch]);

  const searchOptions = {
    location: new google.maps.LatLng(9.082, 8.6753),
    radius: 2000,
    types: ['address'],
    componentRestrictions: { country: ['ng'] },
  };

  const onError = (status, clearSuggestions) => {
    toast.error(`Google Maps API returned error with status: ${status} `, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    clearSuggestions();
  };

  return (
    <form className="px-2">
      <PlacesAutocomplete
        value={pickup}
        onChange={setPickup}
        onSelect={setPickup}
        onError={onError}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="form-floating">
            <input
              className="form-control login__form__control"
              id="Pick_up_location"
              name="Pick_up_location"
              required
              {...getInputProps({
                placeholder: 'Pick-up location',
              })}
            />
            <label htmlFor="Pick_up_location" className="login__label">
              Pick-up location
            </label>
            <div>
              {loading && (
                <div>
                  <Loader />
                </div>
              )}
              {suggestions.map((suggestion) => {
                const style = suggestion.active
                  ? { backgroundColor: '#FACF19', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };

                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <PlacesAutocomplete
        value={delivery}
        onChange={setDelivery}
        onSelect={setDelivery}
        onError={onError}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="form-floating mt-4">
            <input
              className="form-control login__form__control"
              id="delivery_location"
              name="Delivery_location"
              required
              {...getInputProps({
                placeholder: 'Delivery location',
              })}
            />
            <label htmlFor="delivery_location" className="login__label">
              Delivery location
            </label>
            <div>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const style = suggestion.active
                  ? { backgroundColor: '#FACF19', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };

                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <div className="form-floating my-4">
        <input
          type="text"
          className="form-control login__form__control"
          id="fixed_price"
          placeholder="Fixed prize"
          name="fixed_price"
          // onChange={handleChange}
          required
        />
        <label htmlFor="fixed_price" className="login__label">
          Fixed prize
        </label>
      </div>

      <Swiper
        slidesPerView={4}
        spaceBetween={10}
        mousewheel={true}
        className="mySwiper"
        navigation={true}
        breakpoints={{
          546: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {store?.allTruckTypes?.map((truck) => (
          <SwiperSlide key={truck?.id}>
            <div
              className={
                truck?.id === truckId ? 'each__truck  active_truck' : 'each__truck vehicle__truck'
              }
              key={truck?.id}
              name="truck_type_id"
              value={truck?.id}
              onClick={() => {
                setTruckId(truck?.id);
                // calculateDistance();
              }}
            >
              <img src={truck?.icon_url} width="60px" height="60px" alt={truck?.name} />
              <p className="neutral__paragraph p-0">
                {truck?.name} ({truck?.capacity} tons)
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="my-4">
        <Button text={'Add'} />
      </div>
    </form>
  );
};

export default AddFixedWage;
