import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import TruckOwnerRoute from './routes/truckOwner';
import ShipperRoute from './routes/shipper';
import { fetchToken, onMessageListener } from './firebase';
import { useState } from 'react';
import Messages from './components/Toast/Messages';
import { toast } from 'react-toastify';
import { DispatchRoute } from './routes/dispatch';

const App = () => {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);

  console.log('isTokenFound', isTokenFound);

  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload.notification.title, body: payload.notification.body });
      setShow(true);
    })
    .catch((err) => console.log('failed: ', err));

  fetchToken(setTokenFound);

  return (
    <div className="App">
      {show && (
        <div className="d-none">
          {toast(<Messages title={notification.title} body={notification.body} limit={1} />)}{' '}
        </div>
      )}

      <Router>
        <ShipperRoute />
        <TruckOwnerRoute />
        <DispatchRoute />
      </Router>
    </div>
  );
};

export default App;
