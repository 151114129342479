import { DEFAULT_CARD, GET_ALL_CARDS } from '../types/cardTypes';

const initialState = {
  allCards: [],
};

function cardReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CARDS:
      return {
        ...state,
        allCards: payload,
      };
    case DEFAULT_CARD:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default cardReducer;
