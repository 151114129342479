import { Card } from 'antd';
import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getDrivers } from '../../../../../redux/actions/fleet';
import '../../fleet.css';

const MobileDriver = ({
  searchTerm,
  setDriverId,
  setActivateDriverModal,
  setDeactivateDriverModal,
}) => {
  const dispatch = useDispatch();
  const drivers = useSelector((state) => state?.fleets?.allDrivers?.data);

  useEffect(() => {
    dispatch(getDrivers());
  }, [dispatch]);
  return (
    <div>
      {drivers
        ?.filter((item) => {
          if (searchTerm === '') {
            return item;
          } else if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return item;
          }
        })
        ?.map((item, index) => (
          <Card key={index} className="mb-3 shadow-sm">
            <div className="d-flex justify-content-between gap-1">
              <div>
                <h6 className="mobile_title">NAME</h6>
                <p className="mobile_text">{item?.name}</p>
              </div>

              <div className="dropdown">
                <Dropdown>
                  <Dropdown.Toggle className="table-button" id="dropdown-basic">
                    <i className="fas fa-ellipsis-h"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setDriverId(item?.id);
                        setActivateDriverModal(true);
                      }}
                    >
                      {' '}
                      Activate Driver
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ color: '#F25A68' }}
                      id={item?.id}
                      onClick={() => {
                        setDriverId(item?.id);
                        setDeactivateDriverModal(true);
                      }}
                    >
                      Deactivate Driver
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div>
              {' '}
              <div>
                <h6 className="mobile_title">PHONE NUMBER</h6>
                <p className="mobile_text">{item?.phone}</p>
              </div>
              <h6 className="mobile_title">STATUS</h6>
              {item.status === true ? (
                <p className="trip__status w-fit"> Active</p>
              ) : (
                <p className="booking__status_new text-center w-fit"> Deactivated</p>
              )}{' '}
            </div>
          </Card>
        ))}
    </div>
  );
};

export default MobileDriver;
