import instance from '../../axiosConfig';
import './Navbar.css';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useLocation, withRouter } from 'react-router-dom';
import Logo from '../../assets/nav/Pine.svg';
import { setCurrentUser } from '../../redux/user/user.actions';
import { connect, useSelector } from 'react-redux';
import { LOGOUT } from '../../redux/types/authTypes';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Person from '../../assets/nav/person.svg';
// import home from '../../assets/nav/home.svg';
import { ReactComponent as HomeIcon } from '../../assets/nav/home.svg';

import { ReactComponent as TripIcon } from '../../assets/nav/trip.svg';
import { ReactComponent as FleetsIcon } from '../../assets/nav/fleets.svg';
import support from '../../assets/nav/support.svg';
import notifyIcon from '../../assets/nav/notify.svg';
import nav_call from '../../assets/nav/nav_call.svg';
import mail from '../../assets/nav/mail.svg';
import arrow from '../../assets/nav/arrow.svg';
import switchIcon from '../../assets/nav/switch.svg';
import dispatchIcon from '../../assets/nav/dispatch.svg';
import Notification from '../notification/Notification';

const Navigation = ({ setCurrentUser }) => {
  const history = useHistory();
  const store = useSelector((state) => state?.home?.user);
  const [isDispatch, setIsDispatch] = useState(false);
  const location = useLocation();

  const logOut = (event) => {
    event.preventDefault();
    instance
      .post('/logout')
      .then((res) => {
        localStorage.clear();
        setCurrentUser(null);
        history.push('/');
        store.dispatch({ type: LOGOUT });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {}, [history]);

  // console.log('default', isDispatch);

  const handleSwitchToDispatch = () => {
    if (isDispatch === true) {
      window.sessionStorage.removeItem('isDispatch');
      history.push('/carrier-home');
      console.log('carrier home', isDispatch);
    } else {
      window.sessionStorage.setItem('isDispatch', true);
      history.push('/dispatch/dispatch-home');
      console.log('dispatch home', isDispatch);
    }
  };

  const switchAccount = window.sessionStorage.getItem('isDispatch');
  const switchAccounts = JSON.parse(switchAccount);

  return (
    <>
      <Navbar
        variant="pills"
        className="navbar navbar-expand-lg navbar-light bg-white pine__navbar"
        bg="light"
        expand="lg"
      >
        {/* pine logo */}
        <div className="logo__nav">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="Logo" />
          </Link>
          <div className="logo__line"></div>
        </div>{' '}
        {/* pine logo */}
        {/* mobile toggle button */}
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="border-0 outline-0 shadow-none"
        >
          <img src="https://img.icons8.com/material-rounded/30/000000/menu--v3.png" alt="menu" />
        </Navbar.Toggle>
        {/* mobile toggle button */}
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav.Item className="ml-1">
            <NavLink
              to={switchAccounts === true ? '/dispatch/dispatch-home' : '/carrier-home'}
              className="pine__links nav-link"
              title="Home"
            >
              <HomeIcon
                className={location.pathname === '/carrier-home' ? 'activeIcon' : 'navIcon'}
              />
              <span> Home</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink className="pine__links nav-link" to="/trips" title="Trips">
              <TripIcon className={location.pathname === '/trips' ? 'activeIcon' : 'navIcon'} />
              <span> Trips</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink className="pine__links nav-link" to="/fleets" title="Fleets">
              <FleetsIcon className={location.pathname === '/fleets' ? 'activeIcon' : 'navIcon'} />
              <span> Fleets</span>
            </NavLink>
          </Nav.Item>

          <div className="ml-auto support-nav">
            <NavDropdown
              className="support-nav"
              title={
                <span className="pine__links" id="support">
                  <img src={support} alt="Envelope" /> Support
                </span>
              }
            >
              <div className="call__container d-flex justify-content-sm-between">
                <div className="call__icon d-flex align-items-center">
                  <span className="call__span">
                    <img src={nav_call} alt="call us at +2347046100066" />
                  </span>
                  <a href="tel:+2347046100066">+2347046100066</a>
                </div>
                <img src={arrow} alt="arrow" />
              </div>

              <div className="call__container mt-3 d-flex justify-content-sm-between">
                <div className="call__icon d-flex align-items-center">
                  <span className="call__span">
                    <img src={mail} alt="Hi@pinecargo.com" />
                  </span>
                  <a href="mailto:Hi@pinecargo.com">Hi@pinecargo.com</a>
                </div>
                <img src={arrow} alt="arrow" />
              </div>
            </NavDropdown>
          </div>

          {/* notification */}
          <NavDropdown
            title={
              <div className="d-lg-block d-flex align-items-center">
                <img src={notifyIcon} alt="Notification" />
                <span className="d-lg-none d-block support-text ml-3">Notification</span>
              </div>
            }
            className="button"
          >
            <Notification />
          </NavDropdown>

          {/* switch to dispatch */}
          <NavDropdown
            title={
              <div className="d-lg-block d-flex align-items-center">
                <img src={switchIcon} alt="delivery truck" className="person" />
                <span className="d-lg-none d-block support-text ml-3">Switch to dispatch</span>
              </div>
            }
            className="button"
          >
            <NavDropdown.Item className="profile__title">Switch to</NavDropdown.Item>
            <NavDropdown.Item>
              <div
                onClick={() => {
                  // setIsDispatch(!isDispatch);
                  setIsDispatch((isDispatch) => !isDispatch);
                  handleSwitchToDispatch();
                }}
              >
                <img src={dispatchIcon} alt="switch to dispatch " />
                <span className="ml-2">
                  {switchAccounts ? 'Carrier' : 'Dispatch'} {store?.name}
                </span>
              </div>
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <div className="d-lg-block d-flex align-items-center">
                {' '}
                <img src={Person} alt="profile" className="person" />
                <span className="d-lg-none d-block support-text ml-3">Profile</span>
              </div>
            }
            className="button"
          >
            <NavDropdown.Item className="profile__title">
              {store?.name}
              <br />
              <small>{store?.role?.name}</small>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>
              <Link to="/settings" className="settings_nav">
                {' '}
                My Account
              </Link>{' '}
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/settings" className="settings_nav">
                {' '}
                Settings
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item className="profile__logout" onClick={logOut}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation));
