import { toast } from 'react-toastify';
import instance from '../../axiosConfig';
import { GET_ALL_NOTIFICATIONS } from '../types/notificationTypes';

export const getAllNotifications = () => {
  return async (dispatch) => {
    await instance
      .get('trips/messages')
      .then((response) => {
        dispatch({
          type: GET_ALL_NOTIFICATIONS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        toast.error(error?.data?.message, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
};
