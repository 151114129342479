import { useEffect, useState } from 'react';
import './Home.css';
import { Dropdown } from 'react-bootstrap';
import accountImg from '../../../assets/home/account.svg';
import calcImg from '../../../assets/home/calc.png';
import arrow from '../../../assets/home/arrow.svg';
import CalculateFare from './CalculateFare/CalculateFare';
import Navbar from '../../../components/mainNav/Navbar';
import LeaseBooking from './leaseBooking/LeaseBooking';
import { connect, useSelector } from 'react-redux';
import { getStatistics, getTrips, getUser, goOffline, goOnline } from '../../../redux/actions/home';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { loading } from '../../../redux/actions/loading';
import Loader from '../../../components/Loader/Loader';
import SearchBar from '../../../components/search/SearchBar';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import { getPagination } from '../../../redux/actions/booking';
import instance from '../../../axiosConfig';
import BookingDetail from './bookingDetail/BookingDetail';
import AcceptModal from './acceptmodal/AcceptModal';
import RejectModal from './rejected/RejectModal';
import { getTruckTypes } from '../../../redux/actions/fleet';
import { getPendingTrip } from '../../../redux/actions/trips';
import useWindowDimensions from '../../../hooks/useWindowResize';
import MobileHome from './components/mobile_home';

const Home = (props) => {
  const [leaseBookingModal, setleaseBookingModal] = useState(false);
  const [calcFareModal, setCalcFareModal] = useState(false);
  const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [tripId, setTripId] = useState('');
  const [bookingDetails, setBookingDetails] = useState(false);
  const [acceptModalShow, setAcceptModalShow] = useState(false);
  const [rejectModalShow, setRejectModalShow] = useState(false);
  const vehicleTypes = useSelector((state) => state?.fleets?.allTruckTypes);
  const paginationData = useSelector((state) => state?.bookings?.pagination);
  const pendingData = useSelector((state) => state?.trips?.pendingTrips?.data);

  const { width } = useWindowDimensions();

  useEffect(() => {
    const getData = async () => {
      await instance
        .get(`/trips?filter=pending`)
        .then((res) => {
          dispatch(loading(false));
          setSearchTerm(res.data.data.data);
          setTotalPage(res.data.data.total);
          setDataSource(res.data.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getStatistics());
    dispatch(getTrips());
    dispatch(loading(true));
    dispatch(getTruckTypes());
    dispatch(getPendingTrip());
  }, [dispatch, dataSource]);

  // this updates the pagination data
  useEffect(() => {
    if (paginationData) {
      setDataSource(paginationData?.data);
    }
  }, [paginationData]);

  // this restores the pagination data to the original pending data
  useEffect(() => {
    if (paginationData?.data?.length === 0) {
      setDataSource(pendingData);
    }
  }, [paginationData, pendingData]);

  // this toggles the online and offline button
  const handleChecked = () => {
    setChecked(!checked);
    if (checked === false) {
      dispatch(goOnline());
    } else {
      dispatch(goOffline());
    }
  };
  // this handles the search bar
  const handleSearch = (e) => {
    let currValue = e.target.value;

    const filteredData = searchTerm.filter((entry) => {
      return entry.goods.name.toLowerCase().includes(currValue.toLowerCase());
    });
    setDataSource(filteredData);
  };

  const handleBookingDetails = (id) => {
    setTripId(id);
    setBookingDetails(true);
  };

  // handles of columns in the table
  const columns = [
    {
      title: 'COMPANY',
      dataIndex: 'user',
      render: (text) => `${text?.name}`,
      key: 'user',
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'goods',
      render: (text) => `${text?.name}`,
      key: 'goods',
    },
    {
      title: 'PICKUP LOCATION',
      dataIndex: 'pickup',
      key: 'pickup',
      // render: (pickup) => <>{JSON.parse(pickup)?.description}</>,
    },
    {
      title: 'DESTINATION',
      dataIndex: 'delivery',
      key: 'delivery',
      // render: (delivery) => <>{JSON.parse(delivery)[0]?.payload?.description}</>,
    },
    {
      title: 'VEHICLE TYPE',
      dataIndex: 'truck_type_id',
      key: 'truck_type_id',
      render: (vehicle) => (
        <>
          <img
            src={vehicleTypes?.filter((truck) => truck?.id === vehicle)[0]?.icon_url}
            className="driver_content__icon "
            alt={vehicle}
          />
          <p className="neutral__paragraph p-0">
            {vehicleTypes?.filter((truck) => truck?.id === vehicle)[0]?.capacity} ton(s)
          </p>
        </>
      ),
    },
    {
      title: 'ESTIMATED PRICE',
      dataIndex: 'base_price',
      key: 'base_price',
      render: (text) => `₦${text || 0}`,
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (id) => (
        <div className="dropdown" key={id}>
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle className="table-button" id="dropdown-basic">
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setAcceptModalShow(true);
                }}
              >
                Accept Booking
              </Dropdown.Item>
              <Dropdown.Item id={id.id} className="cancel" onClick={() => setRejectModalShow(true)}>
                Reject Booking
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      <Navbar />
      <div className="home">
        <div className="home__content">
          {/* ACCOUNT ONLINE AND TOTAL TRIPS */}
          <div className=" home__grid">
            <div className="home_content_col home_content_col__left">
              <div className="home_content_grid">
                <div>
                  <select
                    className="form-select ml-3 mt-2"
                    id="home__select"
                    aria-label=".form-select-lg example"
                  >
                    <option>Today</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                  <div className="total__amount">
                    <p style={{ 'font-size': '16px' }}>Total Amount earned</p>
                    <h5>₦{props?.home?.earnings}</h5>
                  </div>
                </div>
                <div className=" total__trip">
                  <div className="total__amount total__trips">
                    <p>Total Trips</p>
                    <h5>{props?.home?.trips}</h5>
                  </div>
                </div>
                <div className=" total__trip">
                  <div className="total__amount total__trips">
                    <p>Total Vehicles</p>
                    <h5>{props?.home?.vehicles}</h5>
                  </div>
                </div>
                <div className=" total__trip">
                  <div className="total__amount total__trips">
                    <p>Total Drivers</p>
                    <h5>{props?.home?.drivers}</h5>
                  </div>
                </div>
              </div>
            </div>

            {/* Calculate fare and account online */}
            <div className="d-flex align-items-center gap-4 m-0 p-0 flex-column flex-lg-row flex-md-row">
              <div className="first-row p-15">
                <div className=" account__online">
                  <img src={accountImg} alt="account online" />
                  <div className="account_online__content">
                    <h6>Account Online</h6>
                    <p>Go offline to get some well deserved rest.</p>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      value={checked}
                      className="custom-control-input"
                      id="offline"
                      defaultChecked
                      onChange={handleChecked}
                    />
                    <label className="custom-control-label" htmlFor="offline"></label>
                  </div>
                </div>
              </div>

              <div className="first-row">
                <div className=" account__online" onClick={() => setCalcFareModal(true)}>
                  <img src={calcImg} alt="calculate fare" />
                  <div className="account_online__content">
                    <h6>Calculate fare</h6>
                    <p>Get an estimate of how much your trip will cost.</p>
                  </div>
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            </div>
          </div>

          {/* bookings */}
          <div className=" bookings">
            <div className="table-responsive table-borderless mt-4">
              <div className=" booking__header">
                <div>
                  <h4>Bookings ({totalPage})</h4>
                </div>
                <div className=" align-items-center">
                  <SearchBar handleSearch={handleSearch} text="Search by description" />
                </div>
              </div>

              {props?.loading ? (
                <Loader />
              ) : (
                <div>
                  {width < 1025 ? (
                    <MobileHome
                      handleBookingDetails={handleBookingDetails}
                      searchTerm={searchTerm}
                    />
                  ) : (
                    <Table
                      loading={props?.loading}
                      columns={columns}
                      dataSource={dataSource}
                      pagination={{
                        pageSize: 15,
                        total: totalPage,
                        current: currentPage,
                        onChange: (page) => {
                          setCurrentPage(page);
                          dispatch(getPagination(currentPage));
                        },
                      }}
                      onRow={(rowIndex) => {
                        return {
                          onClick: () => {
                            handleBookingDetails(rowIndex.id);
                          },
                        };
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {bookingDetails && (
        <BookingDetail
          show={bookingDetails}
          onHide={() => setBookingDetails(false)}
          tripId={tripId}
          selectedTrip="pending"
          setAcceptModalShow={setAcceptModalShow}
          setRejectModalShow={setRejectModalShow}
        />
      )}

      {/* CALCULATE FARE MODAL */}
      {calcFareModal && (
        <CalculateFare show={calcFareModal} onHide={() => setCalcFareModal(false)} />
      )}

      {leaseBookingModal && (
        <LeaseBooking show={leaseBookingModal} onHide={() => setleaseBookingModal(false)} />
      )}

      {/* MODAL FOR ACCEPTING AND REJECTING A REQUEST */}
      {acceptModalShow && (
        <AcceptModal show={acceptModalShow} onHide={() => setAcceptModalShow(false)} id={tripId} />
      )}

      {rejectModalShow && (
        <RejectModal show={rejectModalShow} onHide={() => setRejectModalShow(false)} id={tripId} />
      )}

      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  home: state.home.statistics,
  loading: state.home.loading,
  trips: state.home.trips,
});

export default connect(mapStateToProps)(Home);
