import React from 'react'
import { Modal } from 'react-bootstrap'
import close  from '../../../../../assets/settings/close.svg'

const EditCard = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header
                className="modal__header"
                 >
                     <img onClick={props.onHide} src={close} alt="close" />
                    <h3>Edit card</h3>
                    <div></div>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="container login__right pb-5">
                        <p className='f-16 text-left'>Curabitur aliquet quam id dui posuere blandit.</p>
                        <form className="form mt-4" >
                            
                            <div className="form-floating mt-4">
                                <input type="text" className="form-control login__form__control" id="floatingInputGrid" placeholder="Account number"  
                                // value={formData.name} onChange={handleChange} 
                                required
                                />
                                <label for="floatingInputGrid" className='login__label'>Card number</label>
                            </div>

                            <div className="form-floating mt-4">
                                <input type="text" className="form-control login__form__control" id="floatingInputGrid" placeholder="Account number"  
                                // value={formData.name} onChange={handleChange} 
                                required
                                />
                                <label for="floatingInputGrid" className='login__label'>Expiry date</label>
                            </div>

                        </form>
                    </div>

                    <div className="reject__footer "> 
                        <button onClick={props.onHide} className='btn btn__reject mr-4'>Discard</button>
                        <button  className='btn btn__accept'>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditCard
