import React from 'react'
import Logo from "./Logo.png";

const Pine = () => {
    return (
        <>
            <div className="col-md-5 d-none d-md-block d-lg-block p-4 bg-pine">
                <div className="px-4">
                    <img src={Logo} alt="Pine Logo" className="logo" />
                </div>
                <h2 className="px-4 trust">Trust us to always <span className="text-warning">deliver</span>.</h2>
                <p className=" px-4 truck"> A platform where truck owners/logistics companies or transporters can have direct access to companies and individuals that want to move their goods to different  states in Nigeria.</p>
                <p className=" px-4 copyright">© 2021 Pine Africa. All rights reserved.</p>
            </div>
        </>
    )
}

export default Pine
