import React from 'react'
import { Modal } from 'react-bootstrap'
import Loader from '../../../../../components/Loader/Loader'

const LoadModal = (props) => {
  return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        
        <Modal.Body>
            <div className="container login__right pb-5">
                <Loader/>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default LoadModal