import Modal from 'react-bootstrap/Modal';
import React, { useEffect } from 'react';
import close from '../../../../../../../assets/home/Close.svg';
import cashIcon from '../../../../../../../assets/booking/cash.svg';
import mastercardIcon from '../../../../../../../assets/payment/mastercard.svg';
import visaIcon from '../../../../../../../assets/payment/visa.svg';
import { useDispatch, useSelector } from 'react-redux';
import { defaultCard, getAllCards } from '../../../../../../../redux/actions/card';
import instance from '../../../../../../../axiosConfig';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import pineLogo from '../../../../../../../assets/payment/pine.png';
import checkIcon from '../../../../../../../assets/booking/check.svg';
import { getUser } from '../../../../../../../redux/actions/home';

const BookingPayment = (props) => {
  const store = useSelector((state) => state?.card.allCards);
  const userDetail = useSelector((state) => state?.home.user);
  const fare = useSelector((state) => state?.home?.calculateFare?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCards());
    dispatch(getUser());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_API_KEY,
    tx_ref: Date.now(),
    amount: props.editedPrice === '' ? fare?.price : props.editedPrice,
    currency: 'NGN',
    payment_options: 'card',
    customer: {
      email: userDetail?.email,
      phone_number: userDetail?.phone,
      name: userDetail?.name,
    },
    customizations: {
      title: 'Pay for a trip',
      //   description: 'Payment for items in cart',
      logo: pineLogo,
    },
  };

  const handleVerification = async (transaction_id) => {
    await instance.post(`/payment/${transaction_id}/verify`, {}).then((res) => {
      // setLoadingModal(false);
      console.log(res.data);
    });
  };

  const handleCardPayment = (index) => {
    props?.setActiveCardPayment(index);
    dispatch(defaultCard(props?.cardId));
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3>Your payments</h3>
          {/* <button className="send_invite_btn" onClick={AddCardFunction}>
            Add card
          </button> */}
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-left">Select your payment method.</p>
            <div className="form mt-4" onSubmit={handleSubmit}>
              <div className="payment__option">
                <button
                  className="btn cash__btn col-12 d-flex justify-content-between align-items-center"
                  onClick={() => handleCardPayment(2)}
                >
                  <span>
                    <img src={cashIcon} alt="cash" className="mr-3" /> Cash
                  </span>{' '}
                  <img
                    src={checkIcon}
                    className={props?.activeCardPayment === 2 ? 'd-block' : 'd-none '}
                    alt="check"
                  />
                </button>
              </div>

              {store?.map((card, index) => (
                <div className="payment__option mt-2" key={card?.id}>
                  <button
                    className="btn cash__btn col-12 d-flex justify-content-between align-items-center"
                    onClick={() => {
                      handleCardPayment(index);
                      props?.setCardId(card?.id);
                    }}
                  >
                    <span>
                      {card?.provider?.toLowerCase() === 'visa' ? (
                        <img src={visaIcon} alt="cash" className="mr-3" />
                      ) : (
                        <img src={mastercardIcon} alt="cash" className="mr-3" />
                      )}
                      **** {card?.last_four}
                    </span>
                    <img
                      src={checkIcon}
                      className={props?.activeCardPayment === index ? 'd-block' : 'd-none '}
                      alt="check"
                    />
                  </button>
                </div>
              ))}

              <div className="payment__option mt-2">
                <button
                  className="btn cash__btn col-12"
                  onClick={() => {
                    handleFlutterPayment({
                      callback: (response) => {
                        handleVerification(response?.transaction_id);
                        props.onHide();
                        closePaymentModal(); // this will close the modal programmatically
                      },
                      onClose: () => {},
                    });
                  }}
                >
                  <img src={cashIcon} alt="cash" className="mr-3" /> Other payment methods
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookingPayment;
