import instance from '../../axiosConfig';
import React, { useEffect } from 'react';
import { Link, NavLink, useHistory, useLocation, withRouter } from 'react-router-dom';
import Logo from '../../assets/nav/Pine.svg';
import { setCurrentUser } from '../../redux/user/user.actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { LOGOUT } from '../../redux/types/authTypes';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Person from '../../assets/nav/person.svg';
import { ReactComponent as HomeIcon } from '../../assets/nav/home.svg';
import { ReactComponent as TripIcon } from '../../assets/nav/trip.svg';
import { ReactComponent as BookingIcon } from '../../assets/booking/booking.svg';
import support from '../../assets/nav/support.svg';
import notify from '../../assets/nav/notify.svg';
import nav_call from '../../assets/nav/nav_call.svg';
import mail from '../../assets/nav/mail.svg';
import arrow from '../../assets/nav/arrow.svg';
import Notification from '../notification/Notification';
import { getUser } from '../../redux/actions/home';

const ShipperNav = ({ setCurrentUser }) => {
  const store = useSelector((state) => state?.home?.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const logOut = (event) => {
    event.preventDefault();
    history.push('/');
    instance
      .post('/logout')
      .then((res) => {
        localStorage.clear();
        setCurrentUser(null);
        store.dispatch({ type: LOGOUT });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  // console.log(active, 'active nav');

  return (
    <>
      <Navbar
        variant="pills"
        className="navbar navbar-expand-lg navbar-light bg-white pine__navbar"
        bg="light"
        expand="lg"
      >
        <div className="logo__nav">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="Logo" />
          </Link>
          <div className="logo__line"></div>
        </div>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="border-0 outline-0 shadow-none"
        >
          <img src="https://img.icons8.com/material-rounded/30/000000/menu--v3.png" alt="menu" />
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav.Item className="ml-1">
            <NavLink to="/cargo-home" className="pine__links nav-link" title="Home">
              <HomeIcon
                className={location.pathname === '/cargo-home' ? ' activeIcon' : 'navIcon'}
              />
              <span> Home</span>
            </NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink className="pine__links nav-link" to="/cargo-booking" title="Fleets">
              <BookingIcon
                className={location.pathname === '/cargo-booking' ? ' activeIcon' : 'navIcon'}
              />
              <span> Bookings</span>
            </NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink className="pine__links nav-link" to="/cargo-trip" title="Trips">
              <TripIcon
                className={location.pathname === '/cargo-trip' ? ' activeIcon' : 'navIcon'}
              />
              <span> Trips</span>
            </NavLink>
          </Nav.Item>

          <div className="ml-auto support-nav">
            <NavDropdown
              className="support-nav"
              title={
                <span className="pine__links" id="support">
                  <img src={support} alt="Envelope" /> Support
                </span>
              }
            >
              <div className="call__container d-flex justify-content-sm-between">
                <div className="call__icon d-flex align-items-center">
                  <span className="call__span">
                    <img src={nav_call} alt="call us at +2348144047543" />
                  </span>
                  <a href="tel:+2347046100066">+2347046100066</a>
                </div>
                <img src={arrow} alt="arrow" />
              </div>

              <div className="call__container mt-3 d-flex justify-content-sm-between">
                <div className="call__icon d-flex align-items-center">
                  <span className="call__span">
                    <img src={mail} alt="mail us at Hi@pinecargo.com" />
                  </span>
                  <a href="mailto:Hi@pinecargo.com">Hi@pinecargo.com</a>
                </div>
                <img src={arrow} alt="arrow" />
              </div>
            </NavDropdown>
          </div>

          {/* notification */}
          <NavDropdown
            title={
              <div className="d-lg-block d-flex align-items-center">
                <img src={notify} alt="Notification" />
                <span className="d-lg-none d-block support-text ml-3">Notification</span>
              </div>
            }
            className="button"
          >
            <Notification />
          </NavDropdown>

          {/* settings */}
          <NavDropdown
            title={
              <div className="d-lg-block d-flex align-items-center">
                {' '}
                <img src={Person} alt="profile" className="person" />
                <span className="d-lg-none d-block support-text ml-3">Profile</span>
              </div>
            }
            className="button"
          >
            <NavDropdown.Item className="profile__title">
              {store?.name} <br />
              <small>{store?.role?.name}</small>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>
              <Link to="/settings" className="settings_nav">
                {' '}
                My Account
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/settings" className="settings_nav">
                {' '}
                Settings
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item className="profile__logout" onClick={logOut}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShipperNav));
