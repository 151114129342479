import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import banKImg from '../../../../assets/settings/bank.png';
import deleteImg from '../../../../assets/settings/delete.png';
import editImg from '../../../../assets/settings/Edit.png';
import AddBankAccount from './AddBankAccount/AddBankAccount';
import instance from '../../../../axiosConfig';
import { GET_USER_BANK_DETAIL } from '../../../../redux/types/settingsType';
import Loader from '../../../../components/Loader/Loader';

const BankInfo = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const store = useSelector((state) => state.home.user);
  const [loading, setLoading] = useState(true);
  const [bankDetails, setBankDetails] = useState([]);

  useEffect(() => {
    const getUserBankDetails = async () => {
      setLoading(true);
      await instance.get(`companies/${store?.company_id}/bank-details`).then((response) => {
        setBankDetails(response.data.data);
        setLoading(false);
        dispatch({
          type: GET_USER_BANK_DETAIL,
          payload: response.data.data,
        });
      });
    };
    getUserBankDetails();
  }, [setBankDetails, dispatch, store?.company_id]);

  if (loading) {
    return (
      <main className="text-center">
        <Loader />
      </main>
    );
  }
  return (
    <>
      <div className="bankInfo">
        <div className="bankInfo__title">
          <h3>Your bank accounts</h3>
          <p className="form-check-label mt-2" onClick={() => setModalShow(true)}>
            <span className="text-secondary">Have an account already add more? </span>
            <span className="login__links">Add bank account</span>
          </p>
        </div>
        <div className="bankInfo__content">
          {bankDetails?.map((bank) => (
            <div className="bankInfo__content__item" key={bank?.id}>
              <div className="bankInfo__content__item__left">
                <img src={banKImg} alt="bank" />
                <div>
                  <h4>{bank?.bank_name}</h4>
                  <small>{bank?.account_number}</small>
                </div>
              </div>
              <div className="bankInfo__content__item__right">
                <img src={editImg} alt="edit bank details" />
                <img src={deleteImg} alt="delete bank" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <AddBankAccount show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default BankInfo;
