export const subscriptionData = [
  {
    id: 1,
    name: 'Free',
    price: '$0.00',
    duration: '/Month',
    description: 'Everything you need for a small scale dispatch',
    features: [
      '15 Monthly dispatch',
      'Unlimited team',
      'Unlimited drivers',
      'Live chat support',
      'Realtime location',
      'Assign dispatch to multiple drivers',
    ],
  },
  {
    id: 2,
    name: 'Pro',
    price: '$19.99',
    duration: '/Month',
    description: 'Take it further as your business grows',
    features: [
      'Everything Free, Plus:',
      '500 Monthly dispatch',
      'API access',
      'Priority live chat support',
    ],
  },
  {
    id: 3,
    name: 'Enterprise',
    price: 'Contact sales',
    description: 'Need unrestricted access and more',
    features: [
      'Everything Pro, Plus:',
      'Unlimited Dispatch',
      'GPS Hardware discount',
      '24/7 support',
    ],
  },
];
