import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../../../assets/trip/close.svg';
// import GoogleMapReact from 'google-map-react';
import ShippingLocation from './shippingLocation/ShippingLocation';
import BookingDescription from '../oneOffBooking/bookingDescription/BookingDescription';
import ConfirmBooking from '../oneOffBooking/confirmBooking/ConfirmBooking';

const LeaseBooking = (props) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState({
    ShippingLocation: {},
    bookingDescription: {},
    confirmBooking: {},
  });

  function goNextPage() {
    if (page === 4) return;
    setPage((page) => page + 1);
  }

  function updateData(type, newData) {
    setData((data) => {
      return { ...data, [type]: newData };
    });
  }

  // function submit() {
  //   fetch("/api/form", { method: "POST", body: JSON.stringify(data) });
  // }

  return (
    <>
      <Modal {...props} size="lg" id="create__booking__modal">
        <Modal.Header className="modal__header accept__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3 className="text-center">Make a booking</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="create__google__maps">
            {/* BOOKING GOOGLE MAP */}
            {/* <GoogleMapReact
                      bootstrapURLKeys={{ key: GoogleAPIKEY }}
                      defaultCenter={{ lat: -34.397, lng: 150.644 }}
                      defaultZoom={6}
                  >                
                  </GoogleMapReact> */}

            <div className="create__booking__container">
              {page === 1 && (
                <ShippingLocation data={data.user} update={updateData} goNextPage={goNextPage} />
              )}
              {page === 2 && (
                <BookingDescription
                  data={data.profile}
                  update={updateData}
                  goNextPage={goNextPage}
                />
              )}
              {page === 3 && (
                <ConfirmBooking data={data.settings} update={updateData} goNextPage={goNextPage} />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeaseBooking;
