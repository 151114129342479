import React, { useState } from 'react';
import './Login.css';
//import instance from '../../../../axiosConfig';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { login } from '../../redux/actions/auth';
//import { setCurrentUser } from '../../../../redux/user/user.actions';
import { ToastContainer } from 'react-toastify';
import { loading } from '../../redux/actions/loading';
import Button from '../../components/Button/Button';
import Pine from '../../components/Pine/Pine';
import { Link } from 'react-router-dom';

const Login = ({ login }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loading(true));
    login({
      email: `${email}`,
      password: `${password}`,
    });
  };

  let togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div className="login">
      <div className="row">
        <Pine />

        <div className="col-md-7 text-center">
          <div className="mt-30 login__right">
            <h4 className="mt-4 text-center">Welcome back to Pine, 👏🏽</h4>
            <p className="f-16">Please, log in to your account to continue</p>
            <div className="col-md-8 mx-auto">
              <form className="form mt-4" onSubmit={handleSubmit}>
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control login__form__control"
                    id="floatingInput"
                    placeholder="jeremiah@ellie-logistics.com"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="floatingInput" className="login__label">
                    Email
                  </label>
                </div>

                <div className="form-floating">
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    className="form-control login__form__control"
                    id="floatingPassword"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label htmlFor="floatingPassword" className="login__label">
                    Password
                  </label>
                  <i onClick={togglePasswordVisibility} className="fas fa-eye eye"></i>
                </div>

                <div className="form">
                  <p className="form-check-label  text-left pb-4 pt-3">
                    <span className="text-secondary">Forgot your password? </span>{' '}
                    <a href="#/" className="login__links">
                      Click here
                    </a>
                  </p>
                </div>

                <Button text={'Log in'} />
                <Link to="/register">
                  <p className="form-check-label text-center mt-3">
                    <span className="text-secondary">Don't have an account? </span>
                    <span className="login__links">Click here</span>
                  </p>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
