import { Card } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyState from '../../../../../components/emptyState/EmptyState';
import { getAllUsers } from '../../../../../redux/actions/settings';

const MobileUsers = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.setting.manageUser?.data);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <div>
      <div className="user__header">
        <h4>Users</h4>
      </div>
      {store?.length === 0 ? (
        <EmptyState text={'user'} />
      ) : (
        <>
          {store?.map((item, index) => (
            <Card key={index} className="mb-3 shadow-sm overflow-hidden">
              <div className="d-flex justify-content-between gap-1">
                <div>
                  <h6 className="mobile_title">NAME</h6>
                  <p className="mobile_text">{item?.name}</p>
                </div>

                <div>
                  <h6 className="mobile_title">PHONE NUMBER</h6>
                  <p className="mobile_text">{item?.phone}</p>
                </div>
              </div>

              <div>
                <h6 className="mobile_title">ROLE</h6>
                <p className="mobile_text">{item?.role_id === 1 ? 'Owner' : 'User'}</p>
              </div>

              <div>
                {item?.status === true ? (
                  <p className="trip__status">
                    {' '}
                    <span>Active</span>
                  </p>
                ) : (
                  <p className="booking__status_new text-center">
                    {' '}
                    <span>Inactive</span>{' '}
                  </p>
                )}
              </div>
            </Card>
          ))}
        </>
      )}
    </div>
  );
};

export default MobileUsers;
