import { toast } from 'react-toastify';
import instance from '../../axiosConfig';
import {
  GET_ALL_TRIPS,
  DELETE_TRIP,
  GET_SINGLE_TRIP,
  QUEUED_TRIP,
  ONGOING_TRIP,
  DELIVERED_TRIP,
  PENDING_TRIP,
} from '../types/tripTypes';
import { loading } from './loading';

// delete each vehicle
export const deleteTrip = (id) => {
  return async (dispatch) => {
    await instance
      .post(`/trips/${id}/cancel`)
      .then((response) => {
        dispatch(loading(false));
        dispatch({
          type: DELETE_TRIP,
          payload: response.data.data,
        });
        toast.success(response.data.data, {
          position: 'top-right',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        dispatch(loading(false));
        console.log(error);
      });
  };
};

// get all trips
export const getAllTrips = (selectedTrip) => {
  return async (dispatch) => {
    await instance
      .get(`/trips?filter=${selectedTrip}`)
      .then((response) => {
        dispatch(loading(false));
        dispatch({
          type: GET_ALL_TRIPS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(loading(false));
      });
  };
};
export const getQueuedTrip = () => {
  return async (dispatch) => {
    await instance
      .get(`/trips?filter=queued`)
      .then((response) => {
        dispatch(loading(false));
        dispatch({
          type: QUEUED_TRIP,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(loading(false));
      });
  };
};
export const getOngoingTrip = () => {
  return async (dispatch) => {
    await instance
      .get(`/trips?filter=ongoing`)
      .then((response) => {
        dispatch(loading(false));
        dispatch({
          type: ONGOING_TRIP,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(loading(false));
      });
  };
};
export const getPendingTrip = () => {
  return async (dispatch) => {
    await instance
      .get(`/trips?filter=pending`)
      .then((response) => {
        dispatch(loading(false));
        dispatch({
          type: PENDING_TRIP,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(loading(false));
      });
  };
};
export const getDeliveredTrip = () => {
  return async (dispatch) => {
    await instance
      .get(`/trips?filter=delivered`)
      .then((response) => {
        dispatch(loading(false));
        dispatch({
          type: DELIVERED_TRIP,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(loading(false));
      });
  };
};

// get single trip
export const getSingleTrip = (id) => {
  return async (dispatch) => {
    await instance
      .get(`/trips/${id}`)
      .then((response) => {
        dispatch(loading(false));
        dispatch({
          type: GET_SINGLE_TRIP,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(loading(false));
        console.log(error);
      });
  };
};
