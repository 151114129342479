import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import ShipperNav from '../../../components/shipperNav/ShipperNav';
import BookingDetail from './bookingDetail/BookingDetail';
import dayjs from 'dayjs';
import { connect, useDispatch, useSelector } from 'react-redux';
import { deleteTrip, getPendingTrip, getQueuedTrip } from '../../../redux/actions/trips';
import { ToastContainer } from 'react-toastify';
import SearchBar from '../../../components/search/SearchBar';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import { getPagination } from '../../../redux/actions/booking';
import Loader from '../../../components/Loader/Loader';
import { loading } from '../../../redux/actions/loading';
import useWindowDimensions from '../../../hooks/useWindowResize';
import MobilePendingTrip from './components/mobile_pending';
import MobileCargoQueued from './components/mobile_queued';

const ShipperBooking = (props) => {
  const [tripId, setTripId] = useState('');
  const [bookingDetails, setBookingDetails] = useState(false);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [searchPending, setSearchPending] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const queuedStoreData = useSelector((state) => state?.trips.queuedTrips);
  const [toggleState, setToggleState] = useState('queued');
  const pendingStoreData = useSelector((state) => state?.trips.pendingTrips?.data);

  const paginationData = useSelector((state) => state?.bookings?.pagination);

  // use state for queued trip pagination
  const [currentPage, setCurrentPage] = useState(1);
  // const [queuedPagination, setQueuedPagination] = useState([]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    dispatch(loading(true));
    dispatch(getPendingTrip());
    dispatch(getQueuedTrip());
  }, [dispatch]);

  useEffect(() => {
    if (queuedStoreData) {
      setSearchTerm(queuedStoreData?.data);
      setDataSource(queuedStoreData?.data);
      setTotalPage(queuedStoreData?.totalPages);
    }
    if (pendingStoreData) {
      setSearchPending(pendingStoreData);
      setPendingData(pendingStoreData);
    }
  }, [queuedStoreData, pendingStoreData]);

  // this restores the pagination data to the original pending data
  useEffect(() => {
    if (paginationData) {
      setDataSource(paginationData?.data);
    }
    if (paginationData?.data?.length === 0) {
      setDataSource(queuedStoreData);
    }
  }, [paginationData, queuedStoreData]);

  // handle search
  const handleSearch = (e) => {
    let currValue = e.target.value;
    const filteredData = searchTerm.filter((entry) =>
      entry.pickup.toLowerCase().includes(currValue.toLowerCase()),
    );
    const filteredPending = searchPending.filter((entry) =>
      entry.pickup.toLowerCase().includes(currValue.toLowerCase()),
    );
    setDataSource(filteredData);
    setPendingData(filteredPending);
  };
  // handles delete trip
  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteTrip(e.target.id));
  };
  // handles opening of booking details
  const handleBookingDetails = (id) => {
    setTripId(id);
    setBookingDetails(true);
  };

  // handles rendering of different trip status
  const renderStatus = () => {
    switch (toggleState) {
      case 'queued':
        return <span className="booking__status_transit">Pending</span>;
      case 'pending':
        return <span className="booking__status_complete"> Confirmed</span>;

      default:
        return <span className="booking__status_complete">Confirmed</span>;
    }
  };

  // columns for the table
  const columns = [
    {
      title: 'BOOKING ID',
      dataIndex: 'id',
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'created_at',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm A'),
    },
    {
      title: 'PICKUP LOCATION',
      dataIndex: 'pickup',
    },
    {
      title: 'DESTINATION',
      dataIndex: 'delivery',
    },
    {
      title: 'STATUS',
      key: 'accepted',
      render: (accepted) => <>{renderStatus()}</>,
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (id) => (
        <div className="dropdown" key={id}>
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle className="table-button" id="dropdown-basic">
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Edit Booking</Dropdown.Item>
              <Dropdown.Item id={id.id} className="cancel" onClick={handleDelete}>
                Cancel Booking
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const { width } = useWindowDimensions();

  return (
    <>
      <ShipperNav />

      <div className="home">
        <div className="home__content">
          {/* bookings */}
          <div className=" bookings">
            <div className="table-responsive table-borderless mt-4">
              <div className=" booking__header">
                <h4>Bookings </h4>

                <div className="d-lg-flex gap-3 justify-content-between align-items-center">
                  <SearchBar handleSearch={handleSearch} text="Search by pickup locations" />

                  <div className="fleets_header">
                    <ul className="fleets_header_ul">
                      <li
                        className={
                          toggleState === 'queued' ? 'fleets__li fleets_active' : 'fleets__li'
                        }
                        onClick={() => toggleTab('queued')}
                      >
                        Queued
                      </li>
                      <li
                        className={
                          toggleState === 'pending' ? 'fleets__li fleets_active' : 'fleets__li'
                        }
                        onClick={() => toggleTab('pending')}
                      >
                        Pending
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {props?.loading ? (
                <Loader />
              ) : (
                <>
                  {toggleState === 'queued' ? (
                    <>
                      {width < 1026 ? (
                        <MobileCargoQueued
                          handleBookingDetails={handleBookingDetails}
                          renderStatus={renderStatus}
                        />
                      ) : (
                        <Table
                          loading={loading}
                          columns={columns}
                          dataSource={dataSource}
                          pagination={{
                            pageSize: 12,
                            total: totalPage,
                            current: currentPage,
                            onChange: (page) => {
                              setCurrentPage(page);
                              dispatch(getPagination(currentPage));
                            },
                          }}
                          onRow={(rowIndex) => {
                            return {
                              onClick: () => {
                                handleBookingDetails(rowIndex.id);
                              },
                            };
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {width < 1025 ? (
                        <MobilePendingTrip
                          handleBookingDetails={handleBookingDetails}
                          renderStatus={renderStatus}
                        />
                      ) : (
                        <Table
                          loading={loading}
                          columns={columns}
                          dataSource={pendingData}
                          pagination={{
                            pageSize: 12,
                            total: totalPage,
                            onChange: (page) => {
                              setCurrentPage(page);
                              dispatch(getPagination(currentPage));
                            },
                          }}
                          onRow={(rowIndex) => {
                            return {
                              onClick: () => {
                                handleBookingDetails(rowIndex.id);
                              },
                            };
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {bookingDetails && (
        <BookingDetail
          show={bookingDetails}
          onHide={() => setBookingDetails(false)}
          tripId={tripId}
          selectedTrip={toggleState}
        />
      )}

      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.bookings.allBookings,
  loading: state.bookings.loading,
});

export default connect(mapStateToProps)(ShipperBooking);
