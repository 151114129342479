import React, { useEffect, useState } from 'react';
import instance from '../../../axiosConfig';
import Navbar from '../../../components/mainNav/Navbar';
import { LOGOUT } from '../../../redux/types/authTypes';
import BankInfo from './bankInfo/BankInfo';
import BusinessInfo from './businessInfo/BusinessInfo';
import ChangePassword from './changePassword/ChangePassword';
import ManageUser from './manageUsers/ManageUser';
import MyAccount from './myAccount/MyAccount';
import './settings.css';
import { useHistory } from 'react-router-dom';
import { getUser } from '../../../redux/actions/home';
import { useDispatch, useSelector } from 'react-redux';
import ShipperNav from '../../../components/shipperNav/ShipperNav';
import ManageSubscription from './manageSubscription/ManageSubscription';
import useWindowDimensions from '../../../hooks/useWindowResize';
import MobileUsers from './manageUsers/components/mobile_users';

const Settings = ({ setCurrentUser }) => {
  const [toggleState, setToggleState] = useState(1);
  const dispatch = useDispatch();
  const store = useSelector((state) => state.home.user);
  const history = useHistory();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const logOut = (event) => {
    event.preventDefault();
    instance
      .post('/logout')
      .then((res) => {
        if (res.status === 'success') {
          localStorage.clear();
          setCurrentUser(null);
          store.dispatch({ type: LOGOUT });
          history.push('/');
        }
        history.push('/');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const switchAccount = window.sessionStorage.getItem('isDispatch');
  const switchAccounts = JSON.parse(switchAccount);

  const { width } = useWindowDimensions();

  return (
    <>
      {store?.role_id === 1 ? <Navbar /> : <ShipperNav />}
      <div className="settings">
        <div className="row">
          <div className="col-lg-3">
            <div className="siderbar">
              <div className="siderbar_header">
                <h3>Settings</h3>
                <small>
                  Manage your personal information, subscription, add team members, setup bank
                  details, etc.
                </small>
              </div>
              <div className="siderbar_body">
                <ul className="sidebar__ul">
                  <li
                    className={toggleState === 1 ? 'sidebar__li sidebar_active' : 'sidebar__li'}
                    onClick={() => toggleTab(1)}
                  >
                    {' '}
                    My Account
                  </li>
                  <li
                    className={toggleState === 2 ? 'sidebar__li sidebar_active' : 'sidebar__li'}
                    onClick={() => toggleTab(2)}
                  >
                    Business Profile
                  </li>
                  {store?.role_id === 1 ? (
                    <>
                      <li
                        className={toggleState === 3 ? 'sidebar__li sidebar_active' : 'sidebar__li'}
                        onClick={() => toggleTab(3)}
                      >
                        Bank Information
                      </li>
                      <li
                        className={toggleState === 4 ? 'sidebar__li sidebar_active' : 'sidebar__li'}
                        onClick={() => toggleTab(4)}
                      >
                        Manage Users
                      </li>
                    </>
                  ) : (
                    ''
                  )}
                  {switchAccounts === true ? (
                    <li
                      className={toggleState === 5 ? 'sidebar__li sidebar_active' : 'sidebar__li'}
                      onClick={() => toggleTab(5)}
                    >
                      Manage Subscription
                    </li>
                  ) : (
                    ''
                  )}
                  <li
                    className={toggleState === 6 ? 'sidebar__li sidebar_active' : 'sidebar__li'}
                    onClick={() => toggleTab(6)}
                  >
                    Change Password
                  </li>
                  <li className="sidebar__li" onClick={logOut}>
                    Logout
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="content">
              {toggleState === 1 ? <MyAccount /> : ''}
              {toggleState === 2 ? <BusinessInfo /> : ''}
              {toggleState === 3 ? <BankInfo /> : ''}
              {toggleState === 4 ? <>{width < 768 ? <MobileUsers /> : <ManageUser />}</> : ''}
              {toggleState === 5 ? <ManageSubscription /> : ''}
              {toggleState === 6 ? <ChangePassword /> : ''}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
