import React, { useEffect, useState } from 'react';
import calendarImg from '../../../../../../assets/shipperHome/calendar.svg';
import editImg from '../../../../../../assets/shipperHome/edit.svg';
import cashImg from '../../../../../../assets/shipperHome/cash.svg';
import address from '../../../../../../assets/booking/address.svg';
import EditPrice from './contents/EditPrice';
import ContactPerson from './contents/ContactPerson';
import { useDispatch, useSelector } from 'react-redux';
import { createBooking } from '../../../../../../redux/actions/booking';
import BookingPayment from './payment/bookingPayment';
import { getUser } from '../../../../../../redux/actions/home';
import dayjs from 'dayjs';
import { getAllCards } from '../../../../../../redux/actions/card';
import Button from '../../../../../../components/Button/Button';
import { ToastContainer } from 'react-toastify';

const ConfirmBooking = (props) => {
  const [showEditPrice, setShowEditPrice] = useState(false);
  const [showContactPerson, setShowContactPerson] = useState(false);
  const [paymentShow, setPaymentShow] = useState(false);
  const store = useSelector((state) => state?.home?.calculateFare.data);
  const [cardId, setCardId] = useState(15);
  const [activeCardPayment, setActiveCardPayment] = useState(1);
  const [editedPrice, setEditedPrice] = useState('');

  const createdBooking = useSelector((state) => state?.bookings?.createBooking?.data?.status);
  const cards = useSelector((state) => state?.card.allCards);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      createBooking({
        recipient_name: props?.formData?.recipient_name,
        recipient_contact: props?.formData?.recipient_contact,
        pickup: props?.formData?.pickup,
        delivery: props?.formData?.delivery,
        truck_type_id: props?.truckId,
        tonnage: Number(props?.formData?.tonnage),
        value_of_goods: Number(props?.formData?.value_of_goods),
        nature_of_goods_id: Number(props?.formData.nature_of_goods_id),
        pickup_time: props?.formData?.pickup_time,
        pickup_date: props?.formData?.pickup_date,
        user_price:
          editedPrice === '' ? Number(store?.price?.toLocaleString()) : Number(editedPrice),
      }),
    );
  };

  useEffect(() => {
    if (createdBooking === 'success') {
      props?.goNextPage();
    }
    dispatch(getAllCards());
    dispatch(getUser());
  }, [createdBooking, dispatch, editedPrice]);

  const paymentOption = () => {
    switch (activeCardPayment) {
      case 2:
        return (
          <div>
            <img className="mr-2" src={cashImg} alt="cash" />
            cash
          </div>
        );
      case 0:
        return (
          <div>
            {' '}
            {cards?.filter((item) => item.id === cardId)?.map((item) => '****' + item.last_four)}
          </div>
        );
      case 1:
        return (
          <div>
            {' '}
            {cards?.filter((item) => item.id === cardId)?.map((item) => '****' + item.last_four)}
          </div>
        );

      default:
        return (
          <div>
            {' '}
            {cards?.filter((item) => item.id === cardId)?.map((item) => '****' + item.last_four)}
          </div>
        );
    }
  };

  return (
    <>
      <div className="booking__address booking__detail__address m-0">
        <div>
          <img src={address} alt="address" />
        </div>
        <div>
          <div className="book__address__pickup ml-3">
            <h6 className="text-white">{props?.formData?.pickup}</h6>
          </div>
          <div className="book__address__pickup ml-3 mt-4">
            <h6 className="text-white">{props?.formData?.delivery}</h6>
          </div>
        </div>
      </div>

      {/* booking description */}
      <div className="booking__description__container">
        <div className="booking__description__container__header d-flex justify-content-between align-content-center mb-2">
          <div className="d-flex gap-2">
            <img src={calendarImg} alt="calender" />
            <span>
              {dayjs(props?.formData?.pickup_date).format('MMM, DD YYYY')} |{' '}
              {dayjs(props?.values?.pickup_time).format('hh:mm A')}
            </span>
          </div>
          {/* <img src={editImg} alt="edit" /> */}
        </div>
        <div className="border-bottom mb-4 mt-3"></div>
        <div className="estimate_delivery">
          <h6>
            ₦{editedPrice === '' ? store?.price?.toLocaleString() : editedPrice}{' '}
            <span className=" ml-1 ml-lg-3 ml-md-3" onClick={() => setShowEditPrice(true)}>
              <img src={editImg} alt="edit" />
            </span>
          </h6>
          <span>Estimated delivery fare</span>
        </div>

        <div className="delivery_confirm d-flex justify-content-between p-3 border-bottom border-top align-items-center ">
          {/* <p>
            <img className="mr-2" src={cashImg} alt="cash" />
            cash
          </p> */}
          {paymentOption()}
          <p className="change" onClick={() => setPaymentShow(true)}>
            Change
          </p>
        </div>

        <div className="d-flex justify-content-between p-3 border-bottom align-items-center">
          <p className="contact__person">Contact person</p>
          <p
            className="contact__person contact__person__name"
            onClick={() => setShowContactPerson(true)}
          >
            {props?.formData?.recipient_name} <span className="change">(edit)</span>
          </p>
        </div>

        <div className=" mt-4 d-flex justify-content-lg-center gap-5">
          <Button onClick={props.prevPage} className="btn_clear" text={'Back'} />
          <Button onClick={handleSubmit} className="btn" text={'Request booking'} />
        </div>
      </div>

      {showEditPrice && (
        <EditPrice
          show={showEditPrice}
          onHide={() => setShowEditPrice(false)}
          formdetails={props}
          setEditedPrice={setEditedPrice}
          editedPrice={editedPrice}
        />
      )}
      {showContactPerson && (
        <ContactPerson
          show={showContactPerson}
          onHide={() => setShowContactPerson(false)}
          formdetails={props}
        />
      )}
      {paymentShow && (
        <BookingPayment
          setCardId={setCardId}
          cardId={cardId}
          show={paymentShow}
          setActiveCardPayment={setActiveCardPayment}
          activeCardPayment={activeCardPayment}
          onHide={() => setPaymentShow(false)}
          editedPrice={editedPrice}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default ConfirmBooking;
