import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useJsApiLoader, GoogleMap, DirectionsRenderer } from '@react-google-maps/api';
import './oneOffBooking.css';
import close from '../../../../../assets/trip/close.svg';
import ShippingLocation from './shippingLocation/ShippingLocation';
import BookingDescription from './bookingDescription/BookingDescription';
import ConfirmBooking from './confirmBooking/ConfirmBooking';
import pickupIcon from './assets/locator.svg';
import deliveryIcon from './assets/truck_icons.svg';

const OneOffBooking = (props) => {
  const [page, setPage] = useState(1);
  const [truckId, setTruckId] = useState('');
  const [pickup, setPickup] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [delivery, setDelivery] = useState('');
  const google = window.google;
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState('');

  const [formData, setFormData] = useState({
    recipient_name: '',
    recipient_contact: '',
    pickup: pickup,
    delivery: delivery,
    truck_type_id: truckId,
    tonnage: '',
    value_of_goods: '',
    nature_of_goods_id: '',
    pickup_time: startTime,
    pickup_date: '',
  });

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;
    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
      truck_type_id: truckId,
      pickup: pickup,
      delivery: delivery,
      pickup_time: startTime,
    }));
  };

  useEffect(() => {
    // handleInputData();
  }, [truckId, pickup, delivery, formData, startTime]);

  function goNextPage() {
    if (page === 4) {
      props?.onHide();
    }
    setPage((page) => page + 1);
  }

  const prevPage = () => {
    setPage((page) => page - 1);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  var icons = {
    start: new google.maps.MarkerImage(
      // URL
      pickupIcon,
      // (width,height)
      new google.maps.Size(44, 32),
      // The origin point (x,y)
      new google.maps.Point(0, 0),
      // The anchor point (x,y)
      new google.maps.Point(22, 32),
    ),
    end: new google.maps.MarkerImage(
      // URL
      deliveryIcon,
      // (width,height)
      new google.maps.Size(49, 46),
      // The origin point (x,y)
      new google.maps.Point(0, 0),
      // The anchor point (x,y)
      new google.maps.Point(22, 32),
    ),
  };

  async function calculateRoute() {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: pickup,
      destination: delivery,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text.split(' ')?.slice(0, -1)?.join(' '));
    var leg = results.routes[0].legs[0];
    makeMarker(leg?.start_location, icons.start, 'title');
    makeMarker(leg?.end_location, icons.end, 'title');
  }

  function makeMarker(position, icon, title) {
    new google.maps.Marker({
      position: position,
      map: map,
      icon: icon,
      title: title,
    });
  }

  return (
    <>
      <Modal {...props} size="lg" id="create__booking__modal">
        <Modal.Header className="modal__header accept__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3 className="text-center">Make a booking</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="create__google__maps">
            {/* BOOKING GOOGLE MAP */}
            {isLoaded && (
              <GoogleMap
                // center={center}
                zoom={15}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
                onLoad={(map) => setMap(map)}
              >
                {directionsResponse && (
                  <DirectionsRenderer
                    directions={directionsResponse}
                    options={{
                      suppressMarkers: true,
                      polylineOptions: {
                        strokeColor: '#000000',
                      },
                    }}
                  />
                )}
              </GoogleMap>
            )}

            <div className="create__booking__container">
              {page === 1 && (
                <ShippingLocation
                  goNextPage={goNextPage}
                  handleFormData={handleInputData}
                  values={formData}
                  calculateRoute={calculateRoute}
                  delivery={delivery}
                  setPickup={setPickup}
                  pickup={pickup}
                  setDelivery={setDelivery}
                  setStartTime={setStartTime}
                  startTime={startTime}
                />
              )}

              {page === 2 && (
                <BookingDescription
                  goNextPage={goNextPage}
                  truckId={truckId}
                  setTruckId={setTruckId}
                  handleFormData={handleInputData}
                  values={formData}
                  distance={distance}
                  prevPage={prevPage}
                />
              )}

              {page === 3 && (
                <ConfirmBooking
                  formData={formData}
                  truckId={truckId}
                  handleFormData={handleInputData}
                  // values={formData}
                  goNextPage={goNextPage}
                  prevPage={prevPage}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OneOffBooking;
