import React from 'react';
import { connect } from 'react-redux';
import LoaderMini from '../Loader/LoaderMini';
import PropTypes from 'prop-types';

const Button = (props) => {
  return (
    <button
      type="submit"
      className={`btn btn-login btn-block invite_btn ${props.className}`}
      onClick={props.onClick}
    >
      {props.loading === true ? <LoaderMini /> : props.text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loading: state.home.loading,
});

export default connect(mapStateToProps)(Button);
