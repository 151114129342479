import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../../../assets/driver/close.svg';
// import pdfImg from '../../../../../assets/driver/pdf.png';
import './addvehicle.css';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
  createVehicle,
  getAllVehicles,
  getTruckTypes,
  //   uploadVehicleCredential,
} from '../../../../../redux/actions/fleet';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation, Mousewheel } from 'swiper';
import { ToastContainer } from 'react-toastify';

SwiperCore.use([Navigation, Mousewheel]);

const AddVehicle = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.fleets);
  const [formData, setFormData] = useState({});
  const [truckId, setTruckId] = useState('');
  const [year, setYear] = useState('');

  const handleChange = (e) => {
    e.persist();
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
      truck_type_id: truckId,
      year: year,
    }));
  };

  let inputProps = {
    placeholder: 'Select year',
    name: 'year',
    className: ' login__form__control p-3 w-100',
    height: '58px',
  };

  useEffect(() => {
    dispatch(getAllVehicles());
    dispatch(getTruckTypes());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createVehicle(formData));
  };

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3>Add a new vehicle</h3>
          <div className="adddriver_btn">
            <button type="submit" onClick={props.onHide} className="btn btn-white btn-block ">
              Discard
            </button>
            <button onClick={handleSubmit} type="submit" className="btn btn-login">
              Save
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="adddriver__body">
          <form onSubmit={handleSubmit} className="container login__right pb-5 vehicle__container">
            <div className="vehicle__type">
              <h3 className="basic__h3"> Choose vehicle type</h3>

              <Swiper
                slidesPerView={4}
                spaceBetween={10}
                mousewheel={true}
                className="mySwiper"
                navigation={true}
                breakpoints={{
                  546: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
              >
                {store?.allTruckTypes?.map((truck) => (
                  <SwiperSlide>
                    <div
                      className={
                        truckId === truck?.id
                          ? 'active_truck each__truck '
                          : 'each__truck vehicle__truck'
                      }
                      key={truck?.id}
                      name="truck_type_id"
                      value={truck?.id}
                      onClick={() => {
                        setTruckId(truck?.id);
                      }}
                    >
                      <img src={truck?.icon_url} width="60px" height="60px" alt={truck?.name} />
                      <p className="neutral__paragraph p-0">
                        {truck?.name} ({truck?.capacity} tons)
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="fleet__top_line"></div>

            <div className="basic__info">
              <h3 className="basic__h3">Basic Information</h3>
              <p className="f-16 text-left">Cash withdrawn will be paid into this bank account</p>
              <div className="form mt-4">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control login__form__control"
                    id="brand"
                    placeholder="Brand"
                    name="brand"
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="brand" className="login__label">
                    Brand
                  </label>
                </div>

                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control login__form__control"
                    id="Model"
                    placeholder="Model"
                    name="model"
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="Model" className="login__label">
                    Model
                  </label>
                </div>

                <div className="row g-2">
                  {/* year */}
                  <div className="col-md">
                    <div className="form-floating mb-3">
                      <Datetime
                        inputProps={inputProps}
                        dateFormat="YYYY"
                        onChange={(date) => setYear(date.year())}
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control login__form__control"
                        id="Colour"
                        name="color"
                        placeholder="Colour"
                        onChange={handleChange}
                      />
                      <label htmlFor="Colour" className="login__label">
                        Colour
                      </label>
                    </div>
                  </div>
                </div>

                {/* Registration Number  */}
                <div className="form-floating mt-4">
                  <input
                    type="tel"
                    className="form-control login__form__control"
                    id="number"
                    placeholder="Registration Number"
                    name="registration_number"
                    required
                    onChange={handleChange}
                  />
                  <label htmlFor="number" className="login__label">
                    Registration Number
                  </label>
                </div>

                <div className="row g-2">
                  {/* name */}
                  <div className="col-md">
                    <div className="form-floating mt-4 mb-4">
                      <input
                        type="date"
                        className="form-control login__form__control"
                        id="date"
                        placeholder="Expiry Date"
                        name="expiry_date"
                        required
                        onChange={handleChange}
                      />
                      <label htmlFor="date" className="login__label">
                        Expiry Date
                      </label>
                    </div>
                  </div>
                  {/* License Number */}
                  <div className="col-md">
                    <div className="form-floating mt-4">
                      <input
                        type="text"
                        className="form-control login__form__control"
                        id="number"
                        placeholder="License Number"
                        name="license_number"
                        required
                        onChange={handleChange}
                      />
                      <label htmlFor="number" className="login__label">
                        License Number
                      </label>
                    </div>
                  </div>
                </div>

                {/*                                 
                                <div className="fleet__bottom_line"></div>

                                <div className="document__upload">
                                    <h3 className='basic__h3'>Document upload</h3>
                                    <p className='f-16 text-left'>Upload a copy of your driver's license or passport</p>

                                    <div className="document__container">
                                        <div className="document__upload__item">                                                <img src={pdfImg} alt="pdf" />
                                            <div className="document__upload__item__img">
                                                <p>Road Worthiness Document</p>
                                                <small>File size max. of 5MB (PDF)</small>
                                            </div>
                                        </div>
                                        <div className="document__upload__item__icon">
                                            <input type="file" name="road_worthiness" id='upload' className='d-none' accept = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleChange} />
                                            <label htmlFor="upload">Upload</label>
                                        </div>
                                    </div>

                                    <div className="document__container">
                                        <div className="document__upload__item">                                                <img src={pdfImg} alt="pdf" />
                                            <div className="document__upload__item__img">
                                                <p>Vehicle Insurance Document</p>
                                                <small>File size max. of 5MB (PDF)</small>
                                            </div>
                                        </div>
                                        <div className="document__upload__item__icon">
                                            <input type="file" name="vehicle_insurance" id='vehicle_insurance' className='d-none' accept = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleChange} />
                                            <label htmlFor="vehicle_insurance">Upload</label>
                                        </div>
                                    </div>

                                    <div className="document__container">
                                        <div className="document__upload__item">                                        <img src={pdfImg} alt="pdf" />
                                            <div className="document__upload__item__img">
                                                <p>Vehicle Inspection Report Document</p>
                                                <small>File size max. of 5MB (PDF)</small>
                                            </div>
                                        </div>
                                        <div className="document__upload__item__icon">
                                            <input type="file" name="inspection_report" id='inspection_report' className='d-none'
                                            accept = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleChange} />
                                            <label htmlFor="inspection_report">Upload</label>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AddVehicle;
