import {
  CREATE_NEW_VEHICLE,
  DELETE_SPECIFIC_VEHICLE,
  GET_SPECIFIC_VEHICLE,
  GET_ALL_TRUCKTYPES,
  GET_ALL_VEHICLES,
  UPDATE_VEHICLE,
  UPLOAD_VEHICLE_CREDENTIALS,
  GET_ALL_DRIVERS,
  CREATE_DRIVERS,
} from '../types/fleetTypes';
import { LOADING } from '../types/loadingType';

const initialState = {
  allVehicles: {},
  allTruckTypes: [],
  specificVehicle: {},
  allDrivers: {},
  loading: false,
};

function fleetReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case GET_ALL_VEHICLES:
      return {
        ...state,
        loading: false,
        allVehicles: payload,
      };
    case GET_ALL_TRUCKTYPES:
      return {
        ...state,
        loading: false,
        allTruckTypes: payload,
      };
    case CREATE_NEW_VEHICLE:
      return {
        ...state,
      };
    case GET_SPECIFIC_VEHICLE:
      return {
        ...state,
        specificVehicle: payload,
      };
    case DELETE_SPECIFIC_VEHICLE:
      return {
        ...state,
      };
    case UPLOAD_VEHICLE_CREDENTIALS:
      return {
        ...state,
      };
    case UPDATE_VEHICLE:
      return {
        ...state,
      };
    case GET_ALL_DRIVERS:
      return {
        ...state,
        loading: false,
        allDrivers: payload,
      };
    case CREATE_DRIVERS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export default fleetReducer;
