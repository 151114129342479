import React from 'react';
import { Route, Switch } from 'react-router-dom';
import VerifyAccount from '../../authentication/verifyAccount/VerifyAccount';
import Home from '../../pages/truckPages/Home/Home';
import Fleets from '../../pages/truckPages/fleets/Fleets';
import Trips from '../../pages/truckPages/trips/Trips';
import Settings from '../../pages/truckPages/settings/Settings';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import SuccessfulSignUp from '../../authentication/createAccount/CreateAccount';
import Login from '../../authentication/login/Login';
import EnterBusiness from '../../authentication/enterBusiness/EnterBusiness';
import ThanksForSigning from '../../authentication/thanksForSigning/ThanksForSigning';

const TruckOwnerRoute = () => {
  return (
    <>
      <Switch>
        <Route path="/register" component={SuccessfulSignUp} />
        <Route exact path="/" component={Login} />
        <Route path="/verify-truck" component={VerifyAccount} />
        <Route path="/enterbusiness-truck" component={EnterBusiness} />
        <Route path="/thanksforsigning" component={ThanksForSigning} />

        <PrivateRoute path="/carrier-home" component={Home} />
        <PrivateRoute path="/fleets" component={Fleets} />
        <PrivateRoute path="/trips" component={Trips} />
        <PrivateRoute exact path="/settings" component={Settings} />
      </Switch>
    </>
  );
};

export default TruckOwnerRoute;
