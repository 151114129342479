import { Card } from 'antd';
import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVehicles } from '../../../../../redux/actions/fleet';

const MobileVehicle = ({ searchTerm, handleDelete }) => {
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state?.fleets?.allVehicles?.data);

  useEffect(() => {
    dispatch(getAllVehicles());
  }, [dispatch]);
  return (
    <div>
      {vehicles
        ?.filter((item) => {
          if (searchTerm === '') {
            return item;
          } else if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return item;
          }
        })
        ?.map((item, index) => (
          <Card key={index} className="mb-3 shadow-sm">
            <div className="d-flex justify-content-between gap-1">
              <div>
                <h6 className="mobile_title">NAME</h6>
                <p className="mobile_text">{item?.brand}</p>
              </div>

              <div className="dropdown">
                <Dropdown
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Dropdown.Toggle className="table-button" id="dropdown-basic">
                    <i className="fas fa-ellipsis-h"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Edit Vehicle</Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleDelete}
                      style={{ color: '#F25A68' }}
                      id={item?.id}
                    >
                      Delete Vehicle
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="d-flex justify-content-between gap-1">
              {' '}
              <div>
                <h6 className="mobile_title">LICENSE NUMBER</h6>
                <p className="mobile_text">{item?.license_number}</p>
              </div>
              <div>
                <h6 className="mobile_title">CAPACITY</h6>
                <p className="mobile_text">{item?.truck?.capacity}</p>
              </div>
            </div>

            <div>
              <h6 className="mobile_title">STATUS</h6>
              {item.active === 1 ? (
                <p className="trip__status w-fit"> Active</p>
              ) : (
                <p className="booking__status_new text-center w-fit"> Deactivated</p>
              )}{' '}
            </div>
          </Card>
        ))}
    </div>
  );
};

export default MobileVehicle;
