import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import close from '../../../../assets/home/Close.svg';
import { getAllVehicles, getDrivers } from '../../../../redux/actions/fleet';
import { acceptTrip, assignDriver, assignVehicle } from '../../../../redux/actions/home';
import './acceptModal.css';
import SuccessModal from './SuccessModal';

const AcceptModal = (props) => {
  const dispatch = useDispatch();
  const vehicles = useSelector((state) => state?.fleets?.allVehicles?.data);
  const drivers = useSelector((state) => state?.fleets?.allDrivers?.data);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const accepted_trip = useSelector((state) => state?.home?.accepted_trip);

  useEffect(() => {
    dispatch(getAllVehicles());
    dispatch(getDrivers());

    if (accepted_trip === 'Trip has been accepted') {
      dispatch(assignDriver({ driver: selectedDriver, id: props?.id }));
      dispatch(assignVehicle({ vehicle: selectedVehicle, id: props?.id }));
      setModalShow(true);
    }
  }, [accepted_trip, dispatch, props?.id, selectedDriver, selectedVehicle]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(acceptTrip(props?.id));
  };

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header accept__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3 className="text-center">Accept request</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-left">
              Choose a driver & vehicle for this booking request to accept it.
            </p>
            <form className="form mt-4" onSubmit={handleSubmit}>
              <div className="row g-2">
                <div className="col-md">
                  <div className="form-floating">
                    <select
                      className="form-select form-select-lg p-3 login__form__control login__label"
                      aria-label=".form-select-lg example"
                      onChange={(e) => setSelectedDriver(e.target.value)}
                    >
                      <option value="Admin">Choose a driver</option>
                      {drivers?.map((driver) => (
                        <option key={driver.id} value={driver.id}>
                          {driver.name}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelect" className="login__label">
                      Driver
                    </label>
                  </div>
                </div>
              </div>
              <div className="invite__line"></div>

              <div className="row g-2">
                <div className="col-md">
                  <div className="form-floating">
                    <select
                      className="form-select form-select-lg p-3 login__form__control login__label"
                      aria-label="Choose a role example"
                      onChange={(e) => setSelectedVehicle(e.target.value)}
                    >
                      <option>Choose a vehicle</option>
                      {vehicles?.map((vehicle) => (
                        <option key={vehicle?.id} value={vehicle?.id}>
                          {vehicle?.brand}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelect" className="login__label">
                      Vehicle
                    </label>
                  </div>
                </div>
              </div>
              <div className="ytt mt-4">
                <button
                  type="submit"
                  className="btn btn-login btn-block"
                  // onClick={() => setModalShow(true)}
                >
                  Confirm trip
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {modalShow && <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />}
      <ToastContainer />
    </>
  );
};

export default AcceptModal;
