import { toast } from 'react-toastify';
import instance from '../../axiosConfig';
import { DEFAULT_CARD, GET_ALL_CARDS } from '../types/cardTypes';

export const getAllCards = () => {
  return async (dispatch) => {
    await instance
      .get('/payment/card')
      .then((response) => {
        dispatch({
          type: GET_ALL_CARDS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const defaultCard = (id) => async (dispatch) => {
  try {
    id &&
      (await instance.post(`/payment/card/${id}`)).then((response) => {
        dispatch({
          type: DEFAULT_CARD,
          payload: response.data,
        });
      });
  } catch (err) {
    toast.error(err?.data?.message, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
