import { combineReducers } from 'redux';
import authReducer from '../redux/reducer/authReducer';
import bookingReducer from './reducer/bookingReducer';
import cardReducer from './reducer/cardReducer';
import companyReducer from './reducer/companyReducer';
import fleetReducer from './reducer/fleetReducer';
import homeReducer from './reducer/homeReducer';
import notificationReducer from './reducer/notificationReducer';
import settingReducer from './reducer/settingReducer';
import tripReducer from './reducer/tripReducer';

export default combineReducers({
  auth: authReducer,
  company: companyReducer,
  home: homeReducer,
  setting: settingReducer,
  fleets: fleetReducer,
  trips: tripReducer,
  bookings: bookingReducer,
  card: cardReducer,
  notifications: notificationReducer,
});
