import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import camImg from '../../../../assets/settings/camera.png';
import InviteTeam from '../../../../authentication/thanksForSigning/InviteModal/InviteTeam';
import { getAllUsers } from '../../../../redux/actions/settings';
import ActivateUserModal from './components/activateUserModal';
import DeactivateUserModal from './components/deactivateUser';

const ManageUser = () => {
  const [modalShow, setModalShow] = useState(false);
  const [activateUserModal, setActivateUserModal] = useState(false);
  const [deactivateUserModal, setDeactivateUserModal] = useState(false);
  const [userId, setUserId] = useState('');
  const dispatch = useDispatch();
  const store = useSelector((state) => state.setting.manageUser?.data);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <>
      <div className="user__container">
        <div className="table-responsive table-borderless mt-4">
          <div className="user__header">
            <h4>Users</h4>
            <div className="user__select__options">
              {/* <div>
                <select className="form-select p-2 booking__select">
                  <option value="">All status</option>
                  <option value="active">Active users</option>
                  <option value="inactive">Inactive users</option>
                </select>
              </div> */}
              <button
                type="submit"
                onClick={() => setModalShow(true)}
                className="btn btn-login btn-block invite_btn"
              >
                Invite users
              </button>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr className="tr1">
                <th className="booking_table_title">NAME</th>
                <th className="booking_table_title">PHONE NUMBER</th>
                <th className="booking_table_title">ROLE</th>
                <th className="booking_table_title">STATUS</th>
                <th className="booking_table_title">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {' '}
              {store?.map((item) => (
                <tr className="booking__rows">
                  {/* name */}
                  <td className="booking__row">
                    <div className="booking__name">
                      <img
                        className="mr-3"
                        src={item?.avatar_url ? item?.avatar_url : camImg}
                        // src={item?.avatar_url}
                        alt={item?.name}
                      />
                      {item?.name}
                    </div>
                  </td>
                  {/* phone */}
                  <td className="booking__row">{item?.phone}</td>
                  {/*role */}
                  <td className="booking__row">{item?.role_id === 1 ? 'Owner' : 'User'}</td>
                  {/* status */}
                  <td className="booking__row">
                    {item?.status === true ? (
                      <p className="trip__status">
                        {' '}
                        <span>Active</span>
                      </p>
                    ) : (
                      <p className="booking__status_new text-center">
                        {' '}
                        <span>Inactive</span>{' '}
                      </p>
                    )}
                  </td>
                  {/* actions */}
                  <td className="dropdown">
                    {item?.role_id === 1 ? (
                      ''
                    ) : (
                      <Dropdown>
                        <Dropdown.Toggle className="table-button" id="dropdown-basic">
                          <i className="fas fa-ellipsis-h"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            className="btn_cancel"
                            onClick={() => {
                              setDeactivateUserModal(true);
                              setUserId(item?.id);
                            }}
                          >
                            Deactivate
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setActivateUserModal(true);
                              setUserId(item?.id);
                            }}
                          >
                            Activate
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {activateUserModal && (
        <ActivateUserModal
          show={activateUserModal}
          onHide={() => setActivateUserModal(false)}
          userId={userId}
        />
      )}
      {deactivateUserModal && (
        <DeactivateUserModal
          show={deactivateUserModal}
          onHide={() => setDeactivateUserModal(false)}
          userId={userId}
        />
      )}
      {modalShow && <InviteTeam show={modalShow} onHide={() => setModalShow(false)} />}
    </>
  );
};

export default ManageUser;
