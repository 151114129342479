import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyState from '../../../../components/emptyState/EmptyState';
import { getTruckTypes } from '../../../../redux/actions/fleet';
import { getPendingTrip } from '../../../../redux/actions/trips';

const MobileHome = ({ handleBookingDetails, searchTerm }) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);

  const pendingData = useSelector((state) => state?.trips?.pendingTrips?.data);
  const vehicleTypes = useSelector((state) => state?.fleets?.allTruckTypes);

  useEffect(() => {
    dispatch(getTruckTypes());
    dispatch(getPendingTrip());
  }, [dispatch]);

  // this updates the pagination data
  useEffect(() => {
    if (pendingData) {
      setDataSource(pendingData);
    }
  }, [pendingData]);

  return (
    <div>
      {dataSource?.length === 0 ? (
        <EmptyState text={'Booking'} />
      ) : (
        <>
          {dataSource?.map((item, index) => (
            <Card
              key={index}
              className="mb-3 shadow-sm overflow-hidden"
              onClick={() => handleBookingDetails(item?.id)}
            >
              <div className="d-flex justify-content-between gap-1">
                <div>
                  <h6 className="mobile_title">COMPANY</h6>
                  <p className="mobile_text">{item?.user?.name}</p>
                </div>

                <div>
                  <h6 className="mobile_title">VEHICLE TYPE</h6>
                  <img
                    src={
                      vehicleTypes?.filter((truck) => truck?.id === item?.truck_type_id)[0]
                        ?.icon_url
                    }
                    className="driver_content__icon "
                    alt="truck"
                  />{' '}
                  <small className="neutral__paragraph p-0">
                    {
                      vehicleTypes?.filter((truck) => truck?.id === item?.truck_type_id)[0]
                        ?.capacity
                    }{' '}
                    ton(s)
                  </small>
                </div>
              </div>

              <div>
                <h6 className="mobile_title">DESCRIPTION</h6>
                <p className="mobile_text">{item?.goods?.name}</p>
              </div>

              <div>
                <h6 className="mobile_title">PICKUP LOCATION</h6>
                <p className="mobile_text ">{item?.pickup}</p>
              </div>
              <div>
                <h6 className="mobile_title">DESTINATION</h6>
                <p className="mobile_text">{item?.delivery}</p>
              </div>
              <div>
                <h6 className="mobile_title">PRICE</h6>
                <p className="mobile_text">{item?.price}</p>
              </div>
            </Card>
          ))}
        </>
      )}
    </div>
  );
};

export default MobileHome;
