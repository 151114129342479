export const UPDATE_USERS = 'UPDATE_USERS';
export const MANAGE_USERS = 'MANAGE_USERS';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPLOAD_PROFILE_PHOTO = 'UPLOAD_PROFILE_PHOTO';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';

// BANK DETAILS
export const ADD_BANK_DETAILS = 'ADD_BANK_DETAILS';
export const UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS';
export const GET_ALL_BANKS = 'GET_ALL_BANKS';
export const GET_USER_BANK_DETAIL = 'GET_USER_BANK_DETAIL';
