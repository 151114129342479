import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../../assets/home/Close.svg';
import FareResult from './FareResult/FareResult';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getTruckTypes } from '../../../../redux/actions/fleet';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation, Mousewheel } from 'swiper';
import { calculateFare } from '../../../../redux/actions/home';
import PlacesAutocomplete from 'react-places-autocomplete';
//import scriptLoader from "react-async-script-loader";
import Loader from '../../../../components/Loader/Loader';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import { loading } from '../../../../redux/actions/loading';

SwiperCore.use([Navigation, Mousewheel]);

const Fare = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const [pickup, setPickup] = useState('');
  const [delivery, setDelivery] = useState('');
  const [totalDistance, setTotalDistance] = useState('');
  const [truckId, setTruckId] = useState('');
  const [tonnage, setTonnage] = useState('');
  const [valueOfGoods, setValueOfGoods] = useState('');
  const store = useSelector((state) => state?.fleets);
  const fare = useSelector((state) => state?.home?.calculateFare);
  const google = window.google;

  const handleChange = (value) => {
    setPickup(value);
  };

  const handleSelect = (value) => {
    setPickup(value);
  };

  const onError = (status, clearSuggestions) => {
    toast.error(`Google Maps API returned error with status: ${status} `, {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    clearSuggestions();
  };

  const searchOptions = {
    location: new google.maps.LatLng(9.082, 8.6753),
    radius: 2000,
    types: ['address'],
    componentRestrictions: { country: ['ng'] },
  };

  // calculate distance
  const calculateDistance = async () => {
    var origin = pickup;
    var destination = delivery;
    var service = await new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.metric, // kilometers and meters.
        avoidHighways: false,
        avoidTolls: false,
      },
      callback,
    );
  };

  // get distance results
  function callback(response) {
    setTotalDistance(
      response?.rows[0]?.elements[0]?.distance?.text?.split(' ')?.slice(0, -1)?.join(' '),
    );
  }

  useEffect(() => {
    dispatch(getTruckTypes());
    calculateDistance();
  }, [fare, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loading(true));
    dispatch(
      calculateFare({
        truck_type_id: truckId,
        distance: totalDistance,
        tonnage: tonnage,
        value_of_goods: valueOfGoods,
      }),
    );
    if (Object.keys(fare).length === 0) {
      setModalShow(false);
    } else {
      setModalShow(true);
      props.onHide();
    }
    if (fare.status === 'success') {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
      }, 4000);
    }
  };

  return (
    <>
      {modalShow && (
        <FareResult
          show={modalShow}
          onHide={() => setModalShow(false)}
          delivery={delivery}
          pickup={pickup}
        />
      )}
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header accept__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3 className="text-center">Calculate fare</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-left">Get an estimate of how much your trip will cost.</p>
            <form className="form mt-4" onSubmit={handleSubmit}>
              <div className="row g-2">
                <div className="col-md">
                  <PlacesAutocomplete
                    value={pickup}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    onError={onError}
                    searchOptions={searchOptions}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className="form-floating">
                        <input
                          className="form-control login__form__control"
                          id="pick_up_location"
                          name="pick_up_location"
                          required
                          {...getInputProps({
                            placeholder: 'Pick-up location',
                          })}
                        />
                        <label htmlFor="pick_up_location" className="login__label">
                          Pick-up location
                        </label>
                        <div>
                          {loading && (
                            <div>
                              <Loader />
                            </div>
                          )}
                          {suggestions.map((suggestion) => {
                            const style = suggestion.active
                              ? { backgroundColor: '#FACF19', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };

                            return (
                              <div {...getSuggestionItemProps(suggestion, { style })}>
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>

                  <PlacesAutocomplete
                    value={delivery}
                    onChange={setDelivery}
                    onSelect={setDelivery}
                    onError={onError}
                    searchOptions={searchOptions}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className="form-floating mt-4">
                        <input
                          className="form-control login__form__control"
                          id="delivery_location"
                          name="delivery_location"
                          required
                          {...getInputProps({
                            placeholder: 'Delivery location',
                          })}
                        />
                        <label htmlFor="delivery_location" className="login__label">
                          Delivery location
                        </label>
                        <div>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const style = suggestion.active
                              ? { backgroundColor: '#FACF19', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };

                            return (
                              <div {...getSuggestionItemProps(suggestion, { style })}>
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>

              {/* <div className="ytt">
                            <p className="form-check-label  mt-3">
                                <span className='add__delivery' >+ Add another delivery location</span>
                            </p>
                        </div> */}

              <div className="row mt-3 g-2">
                <div className="col-md">
                  <input
                    className="form-control login__form__control pt-4 pb-4"
                    id="floatingPassword"
                    name="tonnage"
                    placeholder="Tonnage"
                    onChange={(e) => setTonnage(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md">
                  <input
                    className="form-control login__form__control pt-4 pb-4"
                    id="floatingPassword"
                    name="value_of_goods"
                    placeholder="Est. value of goods"
                    onChange={(e) => setValueOfGoods(e.target.value)}
                    required
                  />
                </div>
              </div>

              {/* Select truck type */}
              <div className="row select__truck">
                <div className="login__right mt-4">
                  <p className="f-16 text-left">Select truck type</p>
                </div>

                <Swiper
                  slidesPerView={4}
                  spaceBetween={10}
                  mousewheel={true}
                  className="mySwiper"
                  navigation={true}
                  breakpoints={{
                    546: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {store?.allTruckTypes?.map((truck) => (
                    <SwiperSlide key={truck?.id}>
                      <div
                        className={
                          truck?.id === truckId
                            ? 'each__truck  active_truck'
                            : 'each__truck vehicle__truck'
                        }
                        key={truck?.id}
                        name="truck_type_id"
                        value={truck?.id}
                        onClick={() => {
                          setTruckId(truck?.id);
                          calculateDistance();
                        }}
                      >
                        <img src={truck?.icon_url} width="60px" height="60px" alt={truck?.name} />
                        <p className="neutral__paragraph p-0">
                          {truck?.name} ({truck?.capacity} tons)
                        </p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              <div className=" mt-4">
                <Button text={'Estimate fare'} />
                {/* <button type="submit" className="btn btn-login btn-block" onClick={handleSubmit}></button> */}
              </div>
              <p className="neutral__paragraph mt-4 text-center">
                Please note that this is an estimate and that the actual fare <br /> might be bit
                lower or more than this estimate.
              </p>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  fare: state.home.calculateFare,
  loading: state.home.loading,
  trips: state.home.trips,
});

export default connect(mapStateToProps)(Fare);
