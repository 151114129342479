import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import VehicleDetail from './vehicleDetail/VehicleDetail';
import AddVehicle from './addVehicles/AddVehicle';
import { useSelector, useDispatch, connect } from 'react-redux';
import { deleteVehicle, getAllVehicles } from '../../../../redux/actions/fleet';
import { loading } from '../../../../redux/actions/loading';
import Loader from '../../../../components/Loader/Loader';
import SearchBar from '../../../../components/search/SearchBar';
import useWindowDimensions from '../../../../hooks/useWindowResize';
import MobileVehicle from './components/mobile_vehicle';

const Vehicles = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.fleets?.allVehicles);
  const [vehicleDetail, setVehicleDetail] = useState(false);
  const [vehicleId, setVehicleId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    dispatch(getAllVehicles());
    dispatch(loading(true));
  }, [dispatch]);

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteVehicle(e.target.id));
  };

  const tableHeader = ['NAME', 'LICENSE NUMBER', 'CAPACITY', 'STATUS', 'ACTIONS'];
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const { width } = useWindowDimensions();

  return (
    <>
      <div className="fleets_table">
        {props?.loading ? (
          <div style={{ width: '100%' }}>
            <Loader />
          </div>
        ) : (
          <div className="table-responsive table-borderless mt-4">
            <div className="fleets_search">
              <SearchBar handleSearch={handleSearch} text="Search for Vehicles" />
              <div className="fleets__select">
                {/* <select
                  className="form-select p-2 booking__select"
                  aria-label=".form-select-lg example"
                >
                  <option selected>All status</option>
                  <option value="1">All truck type</option>
                  <option value="2">All truck type</option>
                  <option value="3">All truck type</option>
                </select> */}
                <button
                  type="submit"
                  onClick={() => setModalShow(true)}
                  className="btn btn-login btn-block invite_btn"
                >
                  Add Vehicle
                </button>
              </div>
            </div>
            {store.total === 0 ? (
              <h3 className="text-center">Oops! no vehicle data available</h3>
            ) : (
              <>
                {width < 768 ? (
                  <MobileVehicle searchTerm={searchTerm} handleDelete={handleDelete} />
                ) : (
                  <>
                    <table className="table">
                      <thead>
                        <tr className="tr1">
                          {tableHeader.map((header, index) => (
                            <th key={index}>{header}</th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {props?.vehicles?.data
                          ?.filter((item) => {
                            if (searchTerm === '') {
                              return item;
                            } else if (
                              item.brand.toLowerCase().includes(searchTerm.toLowerCase())
                            ) {
                              return item;
                            }
                          })
                          .map((vehicle, index) => (
                            <tr
                              key={index}
                              className="booking__rows"
                              onClick={() => {
                                setVehicleDetail(true);
                                setVehicleId(vehicle?.id);
                              }}
                            >
                              <td className="booking__row">
                                <div className="booking__name d-flex items-center">
                                  <img
                                    className="mr-3"
                                    width="40px"
                                    height="40px"
                                    src={vehicle?.truck?.icon_url}
                                    alt="vehicle"
                                  />
                                  <div>
                                    {vehicle?.brand}
                                    <div className="booking__small">
                                      <small>{vehicle.contact}</small>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="booking__row">{vehicle?.license_number}</td>
                              <td className="booking__row">{vehicle?.truck?.capacity} tonnage</td>
                              <td className="booking__row">
                                {vehicle?.active === 1 ? (
                                  <p className="trip__status w-fit">
                                    {' '}
                                    <span>Active</span>
                                  </p>
                                ) : (
                                  <p className="booking__status_new w-fit">
                                    {' '}
                                    <span>Deactivated</span>{' '}
                                  </p>
                                )}
                              </td>
                              <td>
                                <div className="dropdown">
                                  <Dropdown
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Dropdown.Toggle className="table-button" id="dropdown-basic">
                                      <i className="fas fa-ellipsis-h"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item>Edit Vehicle</Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={handleDelete}
                                        style={{ color: '#F25A68' }}
                                        id={vehicle?.id}
                                      >
                                        Delete Vehicle
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>

      <VehicleDetail
        show={vehicleDetail}
        onHide={() => setVehicleDetail(false)}
        id={vehicleId}
        handleDelete={handleDelete}
      />
      <AddVehicle show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

const mapStateToProps = (state) => ({
  vehicles: state?.fleets?.allVehicles,
  loading: state?.fleets?.loading,
});

export default connect(mapStateToProps)(Vehicles);
