import { GET_ALL_NOTIFICATIONS } from '../types/notificationTypes';

const initialState = {
  allNotifications: {},
};

function notificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: payload,
      };
    default:
      return state;
  }
}

export default notificationReducer;
