import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../../assets/home/Close.svg';
import SuccessReject from './SuccessReject';
import './rejectModal.css';
import { useDispatch, useSelector } from 'react-redux';
import { rejectTrip } from '../../../../redux/actions/home';
import { useEffect } from 'react';

const RejectModal = (props) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const rejectedTrip = useSelector((state) => state?.home?.reject_trip);

  useEffect(() => {
    if (rejectedTrip.status === 'success') {
      setModalShow(true);
    } else {
      setModalShow(false);
    }
    setTimeout(() => {
      setModalShow(false);
    }, 3000);
  }, [rejectedTrip]);

  const handleDelete = (e) => {
    e.preventDefault();
    props.onHide();
    dispatch(rejectTrip(props?.id));
  };

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header accept__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3 className="text-center">Reject request</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container  pb-5">
            <h6 className="f-16 text-left">Are you sure you want to reject this request?</h6>
            <p className="neutral__paragraph">
              Do you want to reject this delivery request. It will be passed on to another delivery
              company.
            </p>
          </div>

          <div className="reject__footer">
            <button onClick={props.onHide} className="btn btn__reject">
              cancel
            </button>
            <button onClick={(e) => handleDelete(e)} className="btn btn__accept btn__modal__reject">
              Reject this request
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {modalShow && <SuccessReject show={modalShow} onHide={() => setModalShow(false)} />}
    </>
  );
};

export default RejectModal;
