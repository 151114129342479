import { CREATE_BOOKING, GET_ALL_GOODS, GET_BOOKING, GET_PAGINATION } from "../types/bookingTypes";
import { LOADING } from "../types/loadingType";


const initialState = {
    allGoods: [],
    createBooking: {},
    pagination: {},
    allBookings:{},
};

function bookingReducer (state = initialState, action) {
    const { type, payload } = action; 

    switch (type) {
        case LOADING:
            return {
                ...state,
                loading: payload,
            }
        case GET_ALL_GOODS: 
          return{
            ...state,
            allGoods: payload
        }
        case CREATE_BOOKING:
            return{
                ...state,
                createBooking: payload
            }
        case GET_BOOKING:
            return{
                ...state,
                allBookings: payload
            }
        case GET_PAGINATION:
            return{
                ...state,
                pagination: payload
            }
        default:
            return state;
    }
}

export default bookingReducer;
