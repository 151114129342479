import React from 'react';
import { ToastContainer } from 'react-toastify';

const Messages = ({ title, body, limit }) => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={true}
        containerId="customId3"
        limit={limit}
        theme="light"
        role="alert"
      />
      <h4>{title}</h4>
      <p>{body}</p>
      <ToastContainer />
    </div>
  );
};

export default Messages;
