import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../../../assets/home/Close.svg';
import AddFixedWage from './components/addFixedWage';
import AddVariableWage from './components/addVariableWage';

const AddWageModal = (props) => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header accept__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3 className="text-center">Add rates</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <ul className="row list-group-flush">
            <li
              className={`${
                toggleState === 1 ? ' bg-yellow bg-opacity-50' : 'bg-light'
              } col-6 list-group-item p-1 text-center border-0`}
              onClick={() => toggleTab(1)}
            >
              Variable rate
            </li>
            <li
              className={`${
                toggleState === 2 ? '  bg-yellow' : 'bg-light'
              } col-5 list-group-item p-1 text-center`}
              onClick={() => toggleTab(2)}
            >
              Fixed rate
            </li>
          </ul>

          <div className="book__address__pickup">
            <h6 className="px-3 my-3 basic__h3">Set your the rates you want to charge</h6>
          </div>
          <div className="px-3">
            {toggleState === 1 ? <AddVariableWage /> : <AddFixedWage />}{' '}
            <p className="neutral__paragraph mt-4 text-center">
              Please note that this is an estimate and that the actual fare <br /> might be bit
              lower or more than this estimate.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddWageModal;
