import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Pine from '../../components/Pine/Pine';
import base from './Base.svg';
import InviteTeam from './InviteModal/InviteTeam';
import './ThanksForSigning.css';

function ThanksForSigning() {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <div className="row">
        <Pine />
        <div class="col-md-7 text-center p-3">
          <div className="container">
            <div class="mt-10">
              <img src={base} alt="Envelope" className="evenlope" />
              <h4 class="mt-4 header__text">Thanks for signing up with us! 🎉</h4>
              <p class="verify-text">
                Your sign up was successful! You can invite your team (e.g, drivers) to join you or
                skip for later.
              </p>
            </div>

            <div className="mt-5 text-center">
              <button
                type="submit"
                className="btn btn-login verify-button"
                onClick={() => setModalShow(true)}
              >
                Invite your team
              </button>

              <div className="ytt">
                <p className="form-check-label text-center mt-3">
                  <span className="text-secondary">Don’t want to do this now? </span>
                  <Link to="/" className="login__links">
                    Continue to dashboard{' '}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InviteTeam show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default ThanksForSigning;
