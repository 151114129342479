import {
  ACCEPT_TRIP,
  ASSIGN_DRIVER,
  ASSIGN_VEHICLE,
  CALCULATE_FARE,
  GET_STATISTICS,
  GET_TRIPS,
  GET_USER_DETAILS,
  GO_OFFLINE,
  GO_ONLINE,
  REJECT_TRIP,
} from '../types/homeTypes';
import { LOADING } from '../types/loadingType';

const initialState = {
  user: {},
  statistics: {},
  offline: '',
  online: '',
  loading: false,
  trips: [],
  calculateFare: {},
  accepted_trip: {},
  reject_trip: {},
  assigned_driver: {},
  assigned_vehicle: {},
};

function homeReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case GET_STATISTICS:
      return {
        ...state,
        statistics: payload,
      };
    case GET_TRIPS:
      return {
        ...state,
        trips: payload,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        user: payload,
      };
    case GO_OFFLINE:
      return {
        ...state,
        offline: payload,
      };
    case GO_ONLINE:
      return {
        ...state,
        online: payload,
      };
    case CALCULATE_FARE:
      return {
        ...state,
        calculateFare: payload,
      };
    case ACCEPT_TRIP:
      return {
        ...state,
        accepted_trip: payload,
      };
    case REJECT_TRIP:
      return {
        ...state,
        reject_trip: payload,
      };
    case ASSIGN_DRIVER:
      return {
        ...state,
        assign_driver: payload,
      };
    case ASSIGN_VEHICLE:
      return {
        ...state,
        assign_vehicle: payload,
      };
    default:
      return state;
  }
}

export default homeReducer;
