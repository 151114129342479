import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './trip.css';
import TransitTrip from './transitTrip/TransitTrip';
import CompletedTrip from './completeTrip/CompletedTrip';
import Navbar from '../../../components/mainNav/Navbar';
import * as dayjs from 'dayjs';
import Loader from '../../../components/Loader/Loader';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  deleteTrip,
  getDeliveredTrip,
  getOngoingTrip,
  getQueuedTrip,
  getSingleTrip,
} from '../../../redux/actions/trips';
import { loading } from '../../../redux/actions/loading';
import SearchBar from '../../../components/search/SearchBar';
import { Table } from 'antd';
import { getPagination } from '../../../redux/actions/booking';
import QueuedDetails from './queuedDetails/QueuedDetails';
import { ToastContainer } from 'react-toastify';
import useWindowDimensions from '../../../hooks/useWindowResize';
import MobileInTransit from './components/mobile_intransit';
import MobileQueued from './components/mobile_queued';
import MobileCompleteTrip from './components/mobile_completed';

const Trips = (props) => {
  const [tripDetails, setTripDetails] = useState(false);
  const [transitTripShow, setTransitTripShow] = useState(false);
  const [completeTripShow, setCompleteTripShow] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.trips.ongoingTrips);
  const queuedData = useSelector((state) => state?.trips.queuedTrips?.data);
  const deliveredData = useSelector((state) => state?.trips.deliveredTrips?.data);
  const [tripId, setTripId] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [newQueuedData, setNewQueuedData] = useState([]);
  const [newDeliveredData, setNewDeliveredData] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [searchTerm, setSearchTerm] = useState([]);
  const [queueSearchTerm, setQueueSearchTerm] = useState([]);
  const [deliveredSearchTerm, setDeliveredSearchTerm] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    dispatch(getQueuedTrip());
    dispatch(getOngoingTrip());
    dispatch(getDeliveredTrip());
    dispatch(getSingleTrip(tripId));
    dispatch(loading(true));
  }, [tripId, dispatch]);

  useEffect(() => {
    if (store) {
      setSearchTerm(store?.data);
      setDataSource(store?.data);
    }
    if (queuedData) {
      setQueueSearchTerm(queuedData);
      setNewQueuedData(queuedData);
    }
    if (deliveredData) {
      setDeliveredSearchTerm(deliveredData);
      setNewDeliveredData(deliveredData);
    }
  }, [queuedData, deliveredData, store]);

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteTrip(e.target.id));
  };

  // function to handle search bar input for all trip tables
  const handleSearch = (e) => {
    let currValue = e.target.value;

    const filteredData = searchTerm.filter((entry) =>
      entry.user.name.toLowerCase().includes(currValue.toLowerCase()),
    );
    const queuedFilteredData = queueSearchTerm.filter((entry) =>
      entry.user.name.toLowerCase().includes(currValue.toLowerCase()),
    );
    const deliveredFilteredData = deliveredSearchTerm.filter((entry) =>
      entry.user.name.toLowerCase().includes(currValue.toLowerCase()),
    );
    if (store) {
      setDataSource(filteredData);
    }
    if (newQueuedData) {
      setNewQueuedData(queuedFilteredData);
    }
    if (newDeliveredData) {
      setNewDeliveredData(deliveredFilteredData);
    }
  };

  const renderStatus = () => {
    switch (toggleState) {
      case 1:
        return <span className="booking__status_transit">In transit</span>;
      case 2:
        return <span className="booking__status_new">Queued </span>;
      case 3:
        return <span className="booking__status_complete">Completed</span>;
      default:
        return 'none';
    }
  };

  const columns = [
    {
      title: 'COMPANY',
      dataIndex: 'user',
      render: (user) => <>{user?.name}</>,
      key: 'user',
    },
    {
      title: 'PICKUP LOCATION',
      dataIndex: 'pickup',
      key: 'pickup',
      // render: (pickup) => <>{JSON.parse(pickup)?.description}</>,
    },

    {
      title: 'DRIVER & VEHICLE',
      dataIndex: 'driver',
      key: 'driver',
      render: (driver) => <>{driver?.name}</>,
    },
    {
      title: 'DATE & TIME',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => (
        <>
          {dayjs(text).format('DD/MM/YYYY')},
          <span className="booking__small_contact">{dayjs(text).format(' hh:mm A')}</span>
        </>
      ),
    },
    {
      title: 'STATUS',
      key: 'accepted',
      render: () => <>{renderStatus()}</>,
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (id) => (
        <div className="dropdown">
          <Dropdown
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle className="table-button" id="dropdown-basic">
              <i className="fas fa-ellipsis-h"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Edit Trip</Dropdown.Item>
              <Dropdown.Item id={id.id} className="cancel" onClick={handleDelete}>
                Cancel Trip
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const handleTripDetails = (id) => {
    setTripId(id);
    switch (toggleState) {
      case 2:
        return setTripDetails(true);
      case 1:
        return setTransitTripShow(true);
      case 3:
        return setCompleteTripShow(true);
      default:
        return 'none';
    }
  };

  const { width } = useWindowDimensions();

  return (
    <>
      <Navbar />
      <div className="home">
        <div className="home__content">
          <div className="bookings">
            <div className="table-responsive table-borderless mt-4">
              <div className=" booking__header">
                <div>
                  <h4>Trips ({store?.total})</h4>
                </div>
                <div className="d-md-flex gap-3 justify-content-between align-items-center">
                  <SearchBar handleSearch={handleSearch} text="Search by company" />

                  <div className="fleets_header">
                    <ul className="fleets_header_ul">
                      <li
                        className={toggleState === 1 ? ' fleets_active' : 'fleets__li'}
                        onClick={() => toggleTab(1)}
                      >
                        In transit
                      </li>
                      <li
                        className={toggleState === 2 ? ' fleets_active' : 'fleets__li'}
                        onClick={() => toggleTab(2)}
                      >
                        Queued
                      </li>
                      <li
                        className={toggleState === 3 ? ' fleets_active' : 'fleets__li'}
                        onClick={() => toggleTab(3)}
                      >
                        Delivered
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {!store ? (
                <Loader />
              ) : (
                <>
                  {toggleState === 1 ? (
                    <>
                      {width < 1024 ? (
                        <MobileInTransit
                          handleTripDetails={handleTripDetails}
                          renderStatus={renderStatus}
                        />
                      ) : (
                        <Table
                          loading={props?.loading}
                          columns={columns}
                          rowKey={(record) => record.uid}
                          dataSource={dataSource}
                          pagination={{
                            pageSize: 15,
                            total: store?.total,
                            current: currentPage,
                            onChange: (page) => {
                              setCurrentPage(page);
                              dispatch(getPagination(currentPage));
                              // setDataSource(store.data)
                            },
                          }}
                          onRow={(rowIndex) => {
                            return {
                              onClick: () => {
                                handleTripDetails(rowIndex.id);
                              },
                            };
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {toggleState === 2 ? (
                        <>
                          {width < 1024 ? (
                            <MobileQueued
                              handleTripDetails={handleTripDetails}
                              renderStatus={renderStatus}
                            />
                          ) : (
                            <Table
                              loading={props?.loading}
                              columns={columns}
                              dataSource={newQueuedData}
                              pagination={{
                                pageSize: 15,
                                total: queuedData?.total,
                                onChange: (page) => {
                                  dispatch(getPagination(page));
                                  // setDataSource(store.data)
                                },
                              }}
                              rowKey={(record) => record.uid}
                              onRow={(rowIndex) => {
                                return {
                                  onClick: () => {
                                    handleTripDetails(rowIndex.id);
                                  },
                                };
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {width < 1024 ? (
                            <MobileCompleteTrip
                              handleTripDetails={handleTripDetails}
                              renderStatus={renderStatus}
                            />
                          ) : (
                            <Table
                              loading={props?.loading}
                              columns={columns}
                              rowKey={(record) => record.uid}
                              dataSource={newDeliveredData}
                              pagination={{
                                pageSize: 15,
                                total: deliveredData?.total,
                                onChange: (page) => {
                                  dispatch(getPagination(page));
                                  // setDataSource(store.data)
                                },
                              }}
                              onRow={(rowIndex) => {
                                return {
                                  onClick: () => {
                                    handleTripDetails(rowIndex.id);
                                  },
                                };
                              }}
                            />
                          )}{' '}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

      {tripDetails && (
        <QueuedDetails
          tripId={tripId}
          show={tripDetails}
          selectedTrip={'ongoing'}
          onHide={() => setTripDetails(false)}
          // pickup={JSON.parse(storeTripId?.pickup)?.description}
        />
      )}

      {transitTripShow && (
        <TransitTrip
          show={transitTripShow}
          onHide={() => setTransitTripShow(false)}
          tripId={tripId}
        />
      )}

      {completeTripShow && (
        <CompletedTrip
          show={completeTripShow}
          onHide={() => setCompleteTripShow(false)}
          // pickup={parsedString}
          tripId={tripId}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  trips: state.trips.allTrips,
  loading: state.home.loading,
});

export default connect(mapStateToProps)(Trips);
