import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import address from '../../../../assets/home/address.svg';
import close from '../../../../assets/trip/close.svg';
import '../trip.css';
import Map from '../../../../components/GoogleMap/GoogleMap';
import Loader from '../../../../components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleTrip } from '../../../../redux/actions/trips';
import { loading } from '../../../../redux/actions/loading';
import dayjs from 'dayjs';

const TransitTrip = (props) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.trips?.singleTrip);

  console.log(store);

  useEffect(() => {
    dispatch(loading(true));
    dispatch(getSingleTrip(props?.tripId));
  }, [props?.tripId, dispatch]);

  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Body className="show-grid">
          <div className="booking__details">
            <div className="booking_detail__content">
              {/* BOOKING DETAIL HEADER */}
              <div className="booking_detail__header">
                <div className="booking__name">
                  <img
                    className="mr-3"
                    src={
                      store?.recipient?.avatar_url ||
                      'https://img.icons8.com/material-outlined/44/000000/user-male-circle.png'
                    }
                    alt="profile"
                  />
                  <div>
                    <h6>{store?.recipient?.name}</h6>
                    <div className="booking__small">
                      <a
                        style={{ 'text-decoration': 'none', color: '#939EAD' }}
                        href={`tel:+${props?.singleTrip?.recipient?.contact}`}
                      >
                        {store?.recipient?.contact}
                      </a>
                    </div>
                  </div>
                </div>
                <p className="booking__status_transit">In Transit</p>
              </div>

              {/* BOOKING DETAILS BODY */}
              <div className="booking_body__container">
                <div className="booking__detail__body">
                  <h6 className="booking__body__title">Booking ID</h6>
                  <p className="booking__body__response">#{store?.id}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Date & time</h6>
                  <p className="booking__body__response">
                    {dayjs(store?.recipient?.created_at).format('DD/MM/YYYY')}, &nbsp;
                    {dayjs(store?.recipient?.created_at).format('h:mm A ')}
                  </p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Nature of goods</h6>
                  <p className="booking__body__response">{store?.goods?.name}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Value of goods</h6>
                  <p className="booking__body__response">₦{store?.value_of_goods || 0}</p>
                </div>

                <div className="booking__detail__body mt-3">
                  <h6 className="booking__body__title">Estimated fare</h6>
                  <p className="booking__body__response">₦{store?.price || 0.0}</p>
                </div>

                {/* BOOKING ADDRESS */}
                <div className="booking__address">
                  <div>
                    <img src={address} alt="address" />
                  </div>
                  <div>
                    {typeof store?.pickup === 'undefined' || null ? (
                      ''
                    ) : (
                      <>
                        {' '}
                        <div className="book__address__pickup ml-3">
                          <h6>{store?.pickup}</h6>
                        </div>
                        <div className="book__address__pickup ml-3 mt-4">
                          <h6>{store?.delivery}</h6>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* BOOKING FOOTER / BUTTONS */}
              <div className="booking__footer flex-row-reverse">
                <button className="btn btn__reject col-lg-12">1hrs 20mins remaining</button>
              </div>
            </div>

            <div className="google__maps">
              <img
                onClick={props.onHide}
                src={close}
                alt="close"
                className="close_transit d-flex m-4"
              />

              {/* BOOKING GOOGLE MAP */}
              {store?.pickup && props?.loading ? (
                <Loader />
              ) : (
                <div style={{ width: '100%', height: '100%' }}>
                  <Map pickup={store?.pickup} delivery={store?.delivery} />
                </div>
              )}
              <div className="driver_content">
                <div className="driver_content__header">
                  <img
                    src={
                      store?.driver?.avatar_url ||
                      'https://img.icons8.com/material-outlined/44/000000/user-male-circle.png'
                    }
                    className="driver_content__icon"
                    alt="profile"
                  />
                  <div className="driver_content__header__text">
                    <h6>{store?.driver?.name}</h6>
                    <small>
                      <a href={`tel:+${store.driver?.phone}`}>{store.driver?.phone}</a>
                    </small>
                  </div>
                  <div className="driver_content__header__text">
                    <h6>{store?.vehicle?.brand}</h6>
                    <small>{store?.vehicle?.license_number}</small>
                  </div>
                  <div className="driver_content__header__text">
                    <h6>
                      4.8
                      <span>
                        <img
                          src="https://img.icons8.com/material/16/29CC92/star--v1.png"
                          alt="rating"
                        />
                      </span>
                    </h6>
                    <small>Rating</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransitTrip;
