import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { updatePassword } from '../../../../redux/actions/settings';
import { ToastContainer } from 'react-toastify';

const ChangePassword = () => {
    const dispatch = useDispatch();
     // form validation rules 
     const validationSchema = Yup.object().shape({
        new_password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('new_password')], 'Passwords must match')
            
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    function onSubmit(data) {
        console.log(data);
        dispatch(updatePassword(
            data
        ))
        // display form data on success
        return false;
    }

    return (
        <>
            <form className="form mt-4 pb-5" onSubmit={handleSubmit(onSubmit)}>
                {/* Current password*/}
                <div className="form-floating mt-4">
                    <input type="password" className="form-control login__form__control" id="currentPassword" 
                    placeholder="Current password"
                    name='old_password'
                    {...register('old_password')}
                    required
                     />
                    <label htmlFor="currentPassword" className='login__label'>Current password</label>
                </div>

                {/*new password  */}
                <div className="form-floating mt-4 mb-4">
                    <input type="password" 
                    id="newPassword" 
                    className={`form-control login__form__control ${errors.password ? 'is-invalid' : 'form-control'}`} 
                    placeholder="New password"
                    name='new_password'
                    required 
                    {...register('new_password')} 
                    />
                    <div className="invalid-feedback">{errors.password?.message}</div>
                    <label htmlFor="newPassword" className='login__label'>New password</label>
                </div>  

                {/* confirm password */}
                <div className="form-floating mt-4 mb-4">
                    <input type="password" 
                    id="confirmPassword" 
                    className={`form-control login__form__control ${errors.confirmPassword ? 'is-invalid' : 'form-control'}`}
                    placeholder="Confirm password"
                    name='confirm_assword'
                    required
                    {...register('confirmPassword')}
                    />
                    <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                    <label htmlFor="confirmPassword" className='login__label'>Confirm password</label>
                </div>  
               
                <button type="submit" className="btn btn-login btn-block col-lg-4 mt-5">
                    Save changes
                </button>
            </form>
            <ToastContainer/>
        </>
    )
}

export default ChangePassword
