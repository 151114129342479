import React, { useEffect } from 'react';
import address from '../../../../../../assets/home/address.svg';
import validator from 'validator';
import ToastError from '../../../../../../components/Toast/ToastError';
import { toast, ToastContainer } from 'react-toastify';
import PlacesAutocomplete from 'react-places-autocomplete';
import Button from '../../../../../../components/Button/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ShippingLocation = (props) => {
  const google = window.google;

  const nexStep = (e) => {
    e.preventDefault();
    if (
      validator.isEmpty(props?.values.pickup) ||
      validator.isEmpty(props?.values.delivery) ||
      validator.isEmpty(props?.values.recipient_name)
    ) {
    } else {
      props?.goNextPage();
    }
  };

  const onError = (status, clearSuggestions) => {
    toast.error(<ToastError text={`Google Maps API returned error with status: ${status}`} />);
    clearSuggestions();
  };

  useEffect(() => {
    props?.handleFormData(props?.values);
  }, []);

  const searchOptions = {
    location: new google.maps.LatLng(9.082, 8.6753),
    radius: 2000,
    types: ['address'],
    componentRestrictions: { country: ['ng'] },
  };

  return (
    <>
      <div className="create__booking__container__content">
        <h1>From where are you shipping to?</h1>
        <div className="row">
          <div className="col-1 address__icon">
            <img src={address} alt="address" />
          </div>
          <div className="col-10  create__booking__address">
            <div className="book__address__pickup ml-3">
              <div className="form-floating mt-4">
                <PlacesAutocomplete
                  value={props?.pickup}
                  onChange={props?.setPickup}
                  onSelect={props?.setPickup}
                  onError={onError}
                  searchOptions={searchOptions}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="form-floating mt-4">
                      <input
                        className="form-control login__form__control"
                        id="floatingPassword"
                        name="Pickup_location"
                        required
                        {...getInputProps({
                          placeholder: 'Pickup location',
                        })}
                      />
                      <label htmlFor="floatingPassword" className="login__label">
                        Pickup location
                      </label>
                      <div>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const style = suggestion.active
                            ? { backgroundColor: '#FACF19', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };

                          return (
                            <div
                              key={suggestion?.description}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              {props?.values.pickup === '' ? (
                <p className="text-danger">Please enter pickup location</p>
              ) : (
                ''
              )}
            </div>

            <div className="book__address__pickup ml-3 mt-4">
              <div className="form-floating mt-4">
                <PlacesAutocomplete
                  value={props?.delivery}
                  onChange={props?.setDelivery}
                  onSelect={props?.setDelivery}
                  onError={onError}
                  searchOptions={searchOptions}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="form-floating mt-4" onClick={props?.calculateRoute}>
                      <input
                        className="form-control login__form__control"
                        id="floatingPassword"
                        name="Delivery_location"
                        required
                        {...getInputProps({
                          placeholder: 'Delivery location',
                        })}
                      />
                      <label htmlFor="floatingPassword" className="login__label">
                        Delivery location
                      </label>
                      <div>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const style = suggestion.active
                            ? { backgroundColor: '#FACF19', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };

                          return (
                            <div
                              key={suggestion?.description}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              {props?.values.delivery === '' ? (
                <p className="text-danger">Please enter delivery location</p>
              ) : (
                ''
              )}
            </div>
            {/* <div className="ytt">
              <p className="form-check-label  mt-3">
                <span className="add__delivery">+ Add another delivery location</span>
              </p>
            </div> */}
          </div>
        </div>

        <div className="border-top my-3"></div>

        <div className="pickup__time">
          <h5>Let’s know your pickup time and date </h5>
          {/* set date and time */}
          <div className="row g-2 mt-2">
            {/* name */}
            <div className="col-md">
              <div className="form-floating">
                <input
                  type="date"
                  className="form-control login__form__control"
                  id="recipient_name"
                  placeholder="Last name"
                  name="recipient_name"
                  required
                  defaultValue={props?.values?.pickup_date}
                  onChange={props?.handleFormData('pickup_date')}
                />
                <label htmlFor="recipient_name" className="login__label">
                  Pickup date
                </label>
              </div>
              {props?.values?.pickup_date === '' ? (
                <p className="text-danger">Please enter pickup date</p>
              ) : (
                ''
              )}
            </div>
            <div className="col-md">
              <div className="form-floating">
                {/* <input
                  type="time"
                  className="form-control login__form__control"
                  id="pickup_time"
                  placeholder="Pick up time"
                  name="pickup_time"
                  defaultValue={props?.values?.pickup_time}
                  onChange={props?.handleFormData('pickup_time')}
                /> */}
                <DatePicker
                  onChange={(date) => props.setStartTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="form-control login__form__control"
                  placeholderText="Select time"
                  selected={props?.startTime}
                />
                <label htmlFor="pickup_time" className="login__label">
                  Pickup Time
                </label>
              </div>
              {props?.values?.pickup_time === '' ? (
                <p className="text-danger">Please enter pickup time</p>
              ) : (
                ''
              )}
            </div>
          </div>

          {/* set recipient name and contact */}
          <div className="row g-2 mt-2">
            {/* name */}
            <div className="col-md">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control login__form__control"
                  id="recipient_name"
                  placeholder="Recipient name"
                  name="recipient_name"
                  required
                  defaultValue={props?.values?.recipient_name}
                  onChange={props?.handleFormData('recipient_name')}
                />
                <label htmlFor="recipient_name" className="login__label">
                  Recipient name
                </label>
              </div>
              {validator.isEmpty(props?.values?.recipient_name) && (
                <p className="text-danger">Please enter recipient name</p>
              )}
            </div>
            <div className="col-md">
              <div className="form-floating">
                <input
                  type="tel"
                  className="form-control login__form__control"
                  id="recipient_contact"
                  placeholder="Recipient contact"
                  name="recipient_contact"
                  defaultValue={props?.values?.recipient_contact}
                  onChange={props?.handleFormData('recipient_contact')}
                />
                <label htmlFor="recipient_contact" className="login__label">
                  Recipient Contact
                </label>
              </div>
              {props.values.recipient_phone === '' && (
                <p className="text-danger">Please enter valid phone number</p>
              )}
            </div>
          </div>
        </div>

        <div className=" mt-5">
          <Button
            onClick={(e) => {
              nexStep(e);
            }}
            text={'Continue'}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ShippingLocation;
