import React from 'react';
import { useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotifications } from '../../redux/actions/notification';

const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state?.notifications?.allNotifications);

  useEffect(() => {
    dispatch(getAllNotifications());
  }, [dispatch]);

  return (
    <>
      <NavDropdown.Item className="notification p-3">
        <h4 id="settings">Notification</h4>
      </NavDropdown.Item>
      <NavDropdown.Item eventKey="/shipper-setting" className="notification">
        {notifications === null ? (
          <h5 className="px-4">No Notifications at the moment</h5>
        ) : (
          <div>
            {/* {notifications?.map((notification, index) => (
              <div className="notify__container d-flex justify-content-between" key={index}>
                <div className="notify__title">
                  <p>{notification?.title}</p>
                  <small>{notification?.body}</small>
                </div>
                <p>{notification?.date}</p>
              </div>
            ))} */}
          </div>
        )}
      </NavDropdown.Item>
    </>
  );
};

export default Notification;
