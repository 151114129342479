import React from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../../../../../assets/settings/close.svg';
import { ToastContainer } from 'react-toastify';

const ContactPerson = (props) => {
  const handleSave = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3>Contact Person</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-left">Edit the recipient name and contact info</p>
            <form className="form mt-4">
              <div className="form-floating mt-4">
                <input
                  type="text"
                  className="form-control login__form__control"
                  id="recipient_name"
                  placeholder=" Recipient’s name"
                  required
                  defaultValue={props?.formdetails?.values?.recipient_name}
                  onChange={props?.formdetails?.handleFormData('recipient_name')}
                />
                <label htmlFor="recipient_name" className="login__label">
                  Recipient’s name
                </label>
              </div>

              <div className="form-floating mt-4">
                <input
                  type="text"
                  className="form-control login__form__control"
                  id="recipient_contact"
                  placeholder="Recipient’s contact"
                  required
                  defaultValue={props?.formdetails?.values?.recipient_contact}
                  onChange={props?.formdetails?.handleFormData('recipient_contact')}
                />
                <label htmlFor="recipient_contact" className="login__label">
                  Recipient’s contact
                </label>
              </div>
            </form>
          </div>

          <div className="reject__footer ">
            <button onClick={props.onHide} className="btn btn__reject mr-4">
              Discard
            </button>
            <button className="btn btn__accept" onClick={handleSave}>
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ContactPerson;
