import React, { useState } from 'react';
import Button from '../../../../components/Button/Button';
import Select from '../../../../components/select/Select';
import { subscriptionData } from './data/data';
import checklistIcon from '../../../../assets/settings/checklist.svg';
import AddCard from '../../../shipperPages/shipperHome/payment/addCard/AddCard';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../../redux/actions/home';

const ManageSubscription = () => {
  const [openAddCard, setOpenAddCard] = useState(false);
  const [changeAmount, setChangeAmount] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state?.home?.user);

  dispatch(getUser());

  const renderSelect = (type) => {
    switch (type) {
      case 'Free':
        return <div className="mt-10"></div>;
      case 'Pro':
        return <Select options={['500 dispatch']} className="mt-3" label="No of dispatch" />;
      case 'Enterprise':
        return <Select options={['Unlimited']} className="mt-3" label="No of dispatch" />;
      default:
        return 'none';
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-5">
        <h4>Dispatch {store?.name}</h4>
        <div className="d-flex gap-3">
          <Button text="Details" />
          <Button text="Add Card" onClick={() => setOpenAddCard(true)} />
        </div>
      </div>

      <div className="d-flex justify-content-center gap-2 mb-5">
        <label htmlFor="amountChange" className="form-check-label">
          Bill monthly
        </label>
        <div className="shadow-none form-switch">
          <input
            className="form-check-input"
            onClick={() => setChangeAmount(!changeAmount)}
            type="checkbox"
            id="amountChange"
          />
        </div>
        <label htmlFor="amountChange" className="form-check-label">
          Bill Annually
        </label>
      </div>

      <div className="d-flex flex-lg-row gap-3 flex-column justify-content-between mt-5">
        {subscriptionData.map((list, index) => (
          <div className="card_container p-4 text-center" key={index}>
            <h4>{list?.name}</h4>
            <p>{list?.description}</p>
            <h2 className="mt-4 subscription_font_size">
              {changeAmount && list.name === 'Pro'
                ? '$' + list?.price.substring(1) * 12
                : list?.price}
              <small className="small_sub">{list.duration}</small>
            </h2>

            {renderSelect(list.name)}
            <Button text="Select" className="mt-3" />

            <ul className="mt-5 d-flex flex-column p-0 m-0">
              {list.features.map((feature, index) => (
                <li className="d-flex align-items-center gap-3 mb-2" key={index}>
                  <img src={checklistIcon} alt="checklist" />
                  <p className="p-0 m-0">{feature}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {openAddCard && <AddCard show={openAddCard} onHide={() => setOpenAddCard(false)} />}
    </div>
  );
};

export default ManageSubscription;
