import instance from '../../axiosConfig';
import { toast } from 'react-toastify';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_CURRENT_USER,
} from '../types/authTypes';
import { loading } from './loading';
// import { useHistory } from 'react-router-dom';

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    const res = await instance.post('/register', formData);
    toast.success('Registration Successful', {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    localStorage.setItem('user', JSON.stringify({ email: formData.email, phone: formData.phone }));
    localStorage.setItem('access_token', res.data.token);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(setCurrentUser());
    window.location.href = '/verify-truck';
  } catch (err) {
    if (err.status === 422) {
      let errors = err.data.errors;
      if (errors.password) {
        errors.password.map((err) => {
          return toast.error(err, {
            position: 'top-right',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }

      if (errors.name) {
        errors.name.map((err) => {
          return toast.error(err, {
            position: 'top-right',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }

      if (errors.phone) {
        errors.phone.map((err) => {
          return toast.error(err, {
            position: 'top-right',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }

      if (errors.email) {
        errors.email.map((err) => {
          return toast.error(err, {
            position: 'top-right',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }

      if (errors.name) {
        errors.name.map((err) => {
          return toast.error(err, {
            position: 'top-right',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }
    } else {
      toast.error(err.response.data.message, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const login = (formData) => async (dispatch) => {
  // const history = useHistory();
  const body = {
    username: formData.email,
    password: formData.password,
    token_name: 'web_app',
  };
  try {
    const res = await instance.post('/login', body);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.user,
    });
    dispatch(loading(false));

    localStorage.setItem(
      'user',
      JSON.stringify({ email: res.data.user.email, phone: res.data.user.phone }),
    );

    if (res.data.user.role_id === 1 && !res.data.user.status) {
      toast.success('Login Successful', {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return (window.location.href = '/verify-truck');
    }

    // if (res.data.user.company_id === null) {
    //   return (window.location.href = '/enterbusiness-truck');
    // }

    localStorage.setItem('access_token', res.data.token);
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('userData', JSON.stringify(res.data.user));

    dispatch(setCurrentUser(res.data.user));

    if (res.data.user.role_id === 1) {
      window.location.href = '/carrier-home';
      // history.push('/carrier-home');
    } else if (res.data.user.role_id === 4) {
      // history.push('/cargo-home');
      window.location.href = '/cargo-home';
    }
  } catch (err) {
    dispatch(loading(false));
    if (err.status === 422) {
      let errors = err.data.errors;
      if (errors.password) {
        errors.password.map((err) => {
          return toast.error(err, {
            position: 'top-right',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }

      if (errors.username) {
        console.log('a');
        errors.username.map((err) => {
          return toast.error(err, {
            position: 'top-right',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }
    } else {
      toast.error(err.data.message, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// verify account
export const verifyAccount = (formData) => {
  const code =
    formData.field_1 + formData.field_2 + formData.field_3 + formData.field_4 + formData.field_5;
  instance
    .post('/code/confirm', { code: code })
    .then((res) => {
      toast.success('Successfully verified, Login to continue', {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.href = '/enterbusiness-truck';
    })
    .catch((err) => {
      toast.error(err.data.message, {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

// Logout
// export const logOut = event => {
//     event.preventDefault();

//     instance.post('/logout').then((res) => {
//       localStorage.clear();
//       setCurrentUser(null);
//       dispatch( {type: LOGOUT});
//       history.push('/');  //redirect to login page
//       window.location.href = '/';
//     }).catch((err) => {
//       console.log(err)
//     })
// }
