import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../../authentication/login/Login';

const PrivateRoute = ({ component: Component, ...rest }) => {
  let isAuthenticated = localStorage.getItem('isAuthenticated');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          // alert("You are not authorized to view this page!")
          return (
            <>
              {' '}
              <Login />
            </>
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
