import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import close from '../../../../assets/settings/close.svg';
import OneOffBooking from './oneOffBooking/OneOffBooking';
import LeaseBooking from './lease/LeaseBooking';

const BookingType = (props) => {
  const [oneOffBookingShow, setOneOffBookingShow] = useState(false);
  const [leaseBookingShow, setLeaseBookingShow] = useState(false);

  // const leaseModalFunction = () => {
  //   props.onHide();
  //   setLeaseBookingShow(true);
  // };

  const OneOffModalFunction = () => {
    props.onHide();
    setOneOffBookingShow(true);
  };

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="modal__header">
          <img onClick={props.onHide} src={close} alt="close" />
          <h3>Booking Types</h3>
          <div></div>
        </Modal.Header>
        <Modal.Body>
          <div className="container login__right pb-5">
            <p className="f-16 text-left">Choose your booking type.</p>
            <form className="form mt-4">
              <div className="booking__type mt-4">
                <button onClick={OneOffModalFunction} className="btn cash__btn col-12">
                  One off
                </button>
              </div>

              <div className="booking__type mt-4">
                <button
                  disabled
                  // onClick={leaseModalFunction}
                  className="btn cash__btn col-12"
                >
                  Lease (coming soon)
                </button>
              </div>
            </form>
          </div>

          {/* <div className="mt-2 mb-4">
                        <button type="submit" className="btn btn-login btn-block col-10 mx-auto"> Continue</button>
                    </div> */}
        </Modal.Body>
      </Modal>

      {oneOffBookingShow && (
        <OneOffBooking show={oneOffBookingShow} onHide={() => setOneOffBookingShow(false)} />
      )}

      {leaseBookingShow && (
        <LeaseBooking show={leaseBookingShow} onHide={() => setLeaseBookingShow(false)} />
      )}
    </>
  );
};

export default BookingType;
