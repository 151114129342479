import React from 'react';
import Modal from 'react-bootstrap/Modal';
import rejectImg from '../../../../assets/home/rejected.png';

const SuccessReject = (props) => {
  const handleClose = () => {
    props.onHide();
  };
  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="container login__right pb-5">
            <div class="mt-10 text-center">
              <img src={rejectImg} alt="Envelope" className="evenlope" />
              <h4 class="mt-4 header__text">Request rejected!</h4>
              <p class="verify-text">
                You have successfully rejected this request and the booking will be assigned to
                another delivery company.
              </p>
            </div>

            <div className="mt-5 text-center">
              <button type="submit" className="btn verify-button send_invite_btn">
                View trip details
              </button>

              <div className="ytt">
                <p
                  className="form-check-label text-center mt-3 accepted__link"
                  onClick={handleClose}
                >
                  Continue to bookings
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessReject;
