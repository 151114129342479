import { Dropdown } from 'react-bootstrap';

export const variableRateColumns = [
  {
    title: 'ASSET',
    dataIndex: 'asset',
    key: 'asset',
  },
  {
    title: 'PRICE PER TON',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'PRICE PER KILOMETER',
    dataIndex: 'pricePerKm',
    key: 'pricePerKm',
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'ACTION',
    dataIndex: 'action',
    render: (id) => (
      <div className="dropdown" key={id}>
        <Dropdown
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown.Toggle className="table-button" id="dropdown-basic">
            <i className="fas fa-ellipsis-h"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                // setAcceptModalShow(true);
              }}
            >
              Accept Booking
            </Dropdown.Item>
            <Dropdown.Item
              id={id.id}
              className="cancel"
              onClick={() => {
                // setRejectModalShow(true);
              }}
            >
              Reject Booking
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];
