import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import camImg from '../../../../assets/settings/camera.png';
import { updateCompany } from '../../../../redux/actions/company';

const BusinessInfo = () => {
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const store = useSelector((state) => state.home.user);
  const [formData, setFormData] = useState({});

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleChange = (e) => {
    e.persist();
    setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    dispatch(updateCompany({ formData }, { id: store?.company?.id }));
  };

  return (
    <>
      <form className="form mt-4 pb-5" onSubmit={handleSubmit}>
        <div className="row">
          <figure className="col-md-6 mb-4">
            <input type="file" onChange={onImageChange} id="filetype" />
            <label htmlFor="filetype">
              <img
                src={
                  image === null
                    ? 'https://img.icons8.com/ios-filled/80/000000/user-male-circle.png'
                    : image
                }
                className="previewImg"
                alt="preview"
              />
              <img className="cameraImg" src={camImg} alt="camera" />
            </label>
          </figure>
        </div>
        {/* Company name */}
        <div className="form-floating mt-4">
          <input
            type="tel"
            className="form-control login__form__control"
            id="companyName"
            placeholder="Company name"
            name="name"
            required
            disabled
            defaultValue={store?.company?.name}
            onChange={handleChange}
          />
          <label htmlFor="companyName" className="login__label">
            Company name
          </label>
        </div>
        {/* Company phone number  */}
        <div className="form-floating mt-4 mb-4">
          <input
            type="text"
            className="form-control login__form__control"
            id="phone"
            placeholder="Company phone number"
            name="phone"
            required
            value={store?.company?.phone}
            disabled
            onChange={handleChange}
          />
          <label htmlFor="phone" className="login__label">
            Company phone number
          </label>
        </div>
        {/* Company email */}
        <div className="form-floating mt-4 mb-4">
          <input
            type="email"
            className="form-control login__form__control"
            id="email"
            placeholder="jeremiah@ellie-logistics.com"
            name="email"
            required
            value={store?.company?.email}
            onChange={handleChange}
          />
          <label htmlFor="email" className="login__label">
            Company email address
          </label>
        </div>
        {/* company address */}
        <div className="form-floating mt-4 mb-4">
          <input
            type="text"
            className="form-control login__form__control"
            id="address"
            placeholder="jeremiah@ellie-logistics.com"
            name="address"
            required
            defaultValue={store?.company?.address}
            onChange={handleChange}
          />
          <label htmlFor="address" className="login__label">
            Company address
          </label>
        </div>
        {/* company description */}
        <div className="form-floating mb-4">
          <textarea
            className="form-control login__form__control"
            placeholder="Company description"
            style={{ height: '100px' }}
            id="floatingTextarea"
            name="description"
            defaultValue={store?.company?.description}
            onChange={handleChange}
          ></textarea>
          <label htmlFor="floatingTextarea" className="login__label">
            Company description
          </label>
        </div>
        <button type="submit" className="btn btn-login btn-block col-lg-4 mt-5">
          Save changes
        </button>
      </form>
      <ToastContainer />
    </>
  );
};

export default BusinessInfo;
