import React, { useState } from 'react';
import Navbar from '../../../components/mainNav/Navbar';
import FixedPricing from './components/fixed/fixed';
import VariablesPricing from './components/variables/variables';

const Pricing = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div>
      <Navbar />
      <div className="home">
        <div className="home__content">
          <div className="fleets_container">
            <div className="fleets_header">
              <ul className="fleet_header_ul ">
                <li
                  className={toggleState === 1 ? 'fleets__li fleets_active' : 'fleets__li'}
                  onClick={() => toggleTab(1)}
                >
                  Variable
                </li>
                <li
                  className={toggleState === 2 ? 'fleets__li fleets_active' : 'fleets__li'}
                  onClick={() => toggleTab(2)}
                >
                  Fixed
                </li>
              </ul>
            </div>

            {toggleState === 1 ? <VariablesPricing /> : ''}
            {toggleState === 2 ? <FixedPricing /> : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
