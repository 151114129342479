import React from 'react'
import Modal from 'react-bootstrap/Modal'
import address from '../../../../../assets/home/address.svg'
import successImg from '../../../../../assets/home/success.png'
import close from '../../../../../assets/home/Close.svg'
import { useSelector } from 'react-redux'

const FareResult = (props) => {
    const store = useSelector(state => state?.home?.calculateFare);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Body className='fare__container p-1 md-p-1'>
                    <div className=" d-flex justify-content-lg-end">
                        <div></div>
                        <div></div>                    
                        <img onClick={props.onHide} src={close} alt="close" />
                    </div>
                    <div className="container  login__right pb-5">
                        <div class="pt-5 text-center">
                            <img src={successImg} alt="Envelope" className="evenlope" />
                            <h4 class="mt-4 header__text">₦{store?.data?.price}</h4>
                            <p class="verify-text">Estimated fare for this trip. Note that actual fare might be different.</p>                
                        </div>

                        {/* BOOKING ADDRESS */}
                        <div className="fare__content">
                            <div className="booking__address">
                                <div>
                                    <img src={address} alt="address" />
                                </div>
                                <div>
                                    <div className="book__address__pickup ml-3">
                                        <h6>{props?.pickup}</h6>
                                        {/* <div className='booking__small'>
                                        </div> */}
                                    </div>
                                    <div className="book__address__pickup ml-3 mt-4">
                                        <h6>{props?.delivery}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="fare__cost">
                                <div className="fare__cost_line"></div>
                                <div className="row text-center ">
                                    <div className="col-lg-6 fare__col-left">
                                        <h6>Trailer Truck</h6>
                                        <p className="neutral__paragraph">Vehicle type</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <h6>₦{store?.data?.price}</h6>
                                        <p className="neutral__paragraph">Vehicle cost</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        
                    </div>
                </Modal.Body>
            </Modal> 
        </>
    )
}

export default FareResult
