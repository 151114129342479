import { Card } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyState from '../../../../components/emptyState/EmptyState';
import { getQueuedTrip } from '../../../../redux/actions/trips';

const MobileQueued = ({ handleTripDetails, renderStatus }) => {
  const queuedData = useSelector((state) => state?.trips?.queuedTrips?.data);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQueuedTrip());
  }, [dispatch]);
  return (
    <div>
      {queuedData?.length === 0 ? (
        <EmptyState text={'Queued trip'} />
      ) : (
        <>
          {queuedData?.map((item, index) => (
            <Card
              key={index}
              className="mb-3 shadow-sm overflow-hidden"
              onClick={() => handleTripDetails(item?.id)}
            >
              <div className="d-flex justify-content-between gap-1">
                <div>
                  <h6 className="mobile_title">COMPANY</h6>
                  <p className="mobile_text">{item?.user?.name}</p>
                </div>

                <div>
                  <h6 className="mobile_title">DRIVER & VEHICLE</h6>
                  <p className="mobile_text">{item?.driver?.name}</p>
                </div>
              </div>

              <div className="d-flex justify-content-between gap-1">
                <div>
                  <h6 className="mobile_title">DATE & TIME</h6>
                  {dayjs(item?.created_at).format('DD/MM/YYYY')},
                  <span className="booking__small_contact">
                    {dayjs(item?.created_at).format(' hh:mm A')}
                  </span>
                </div>

                <div>
                  <h6 className="mobile_title">STATUS</h6>
                  <p className="mobile_text">{renderStatus()}</p>
                </div>
              </div>

              <div>
                <h6 className="mobile_title">PICKUP LOCATION</h6>
                <p className="mobile_text ">{item?.pickup}</p>
              </div>
            </Card>
          ))}
        </>
      )}
    </div>
  );
};

export default MobileQueued;
