import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { getDrivers } from '../../../../redux/actions/fleet';
import { connect, useDispatch } from 'react-redux';
import Loader from '../../../../components/Loader/Loader';
import { loading } from '../../../../redux/actions/loading';
import InviteTeam from '../../../../authentication/thanksForSigning/InviteModal/InviteTeam';
import ActivateUserModal from '../../settings/manageUsers/components/activateUserModal';
import DeactivateUserModal from '../../settings/manageUsers/components/deactivateUser';
import SearchBar from '../../../../components/search/SearchBar';
import useWindowDimensions from '../../../../hooks/useWindowResize';
import MobileDriver from './components/mobile_driver';

const Driver = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [activateDriverModal, setActivateDriverModal] = useState(false);
  const [deactivateDriverModal, setDeactivateDriverModal] = useState(false);
  const [driverId, setDriverId] = useState('');

  useEffect(() => {
    dispatch(getDrivers());
    dispatch(loading(true));
  }, [driverId, dispatch, activateDriverModal, deactivateDriverModal]);

  const tableHeader = ['NAME', 'PHONE NUMBER', 'TRIPS', 'STATUS', 'ACTIONS'];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const { width } = useWindowDimensions();

  return (
    <>
      <div className="fleets_table">
        {props?.loading ? (
          <Loader />
        ) : (
          <div className="table-responsive table-borderless mt-4">
            <div className="fleets_search">
              <SearchBar handleSearch={handleSearch} text="Search for driver" />
              <div className="fleets__select">
                <button
                  type="submit"
                  onClick={() => setModalShow(true)}
                  className="btn btn-login btn-block invite_btn"
                >
                  Add driver
                </button>
              </div>
            </div>
            {props?.drivers?.data === 0 ? (
              <h3 className="text-center">Oops! no vehicle data available</h3>
            ) : (
              <>
                {width < 768 ? (
                  <MobileDriver
                    searchTerm={searchTerm}
                    setDeactivateDriverModal={setDeactivateDriverModal}
                    setActivateDriverModal={setActivateDriverModal}
                    setDriverId={setDriverId}
                  />
                ) : (
                  <table className="table">
                    <thead>
                      <tr className="tr1">
                        {tableHeader.map((header, index) => (
                          <th key={index}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {props?.drivers?.data
                        ?.filter((item) => {
                          if (searchTerm === '') {
                            return item;
                          } else if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return item;
                          }
                        })
                        .map((driver) => {
                          return (
                            <tr key={driver?.id} className="booking__rows">
                              <td className="booking__row">
                                <div className="booking__name">
                                  <img
                                    className="mr-3"
                                    src={
                                      driver?.avatar_url === ''
                                        ? 'https://img.icons8.com/ios-filled/40/000000/user-male-circle.png'
                                        : driver?.avatar_url
                                    }
                                    alt="driver"
                                  />
                                  <div>
                                    {driver?.name}
                                    <div className="booking__small"></div>
                                  </div>
                                </div>
                              </td>
                              <td className="booking__row">{driver?.phone}</td>
                              <td className="booking__row">{driver?.trips?.length || 0}</td>

                              <td className="booking__row">
                                {driver.status === true ? (
                                  <p className="trip__status w-fit">
                                    {' '}
                                    <span>Active</span>
                                  </p>
                                ) : (
                                  <p className="booking__status_new text-center w-fit">
                                    {' '}
                                    <span>Deactivated</span>{' '}
                                  </p>
                                )}
                              </td>
                              <td>
                                <div className="dropdown">
                                  <Dropdown>
                                    <Dropdown.Toggle className="table-button" id="dropdown-basic">
                                      <i className="fas fa-ellipsis-h"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          setDriverId(driver?.id);
                                          setActivateDriverModal(true);
                                        }}
                                      >
                                        {' '}
                                        Activate Driver
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        style={{ color: '#F25A68' }}
                                        id={driver?.id}
                                        onClick={() => {
                                          setDriverId(driver?.id);
                                          setDeactivateDriverModal(true);
                                        }}
                                      >
                                        Deactivate Driver
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {modalShow && <InviteTeam show={modalShow} onHide={() => setModalShow(false)} />}
      {activateDriverModal && (
        <ActivateUserModal
          show={activateDriverModal}
          onHide={() => setActivateDriverModal(false)}
          userId={driverId}
        />
      )}
      {deactivateDriverModal && (
        <DeactivateUserModal
          show={deactivateDriverModal}
          onHide={() => setDeactivateDriverModal(false)}
          userId={driverId}
        />
      )}
      {/* <DriverDetail 
                show={modalShow}
                onHide={() => setModalShow(false)}
            /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  const data = state?.fleets;
  return {
    drivers: data?.allDrivers,
    loading: data?.loading,
  };
};

export default connect(mapStateToProps)(Driver);
