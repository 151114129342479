import { Switch } from 'react-router-dom';
import DispatchHome from '../../pages/dispatchPages/dispatchHome/dispatchHome';
import Pricing from '../../pages/dispatchPages/pricing/pricing';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

export const DispatchRoute = () => {
  return (
    <>
      <Switch>
        <PrivateRoute path="/dispatch/dispatch-home" component={DispatchHome} />
        <PrivateRoute path="/pricing" component={Pricing} />
      </Switch>
    </>
  );
};
