import React, { useState } from 'react';
import './VerifyAccount.css';
import VerifyEnvelope from './verify.png';
import { ToastContainer } from 'react-toastify';
import { verifyAccount } from '../../redux/actions/auth';
import Pine from '../../components/Pine/Pine';

const Verify = () => {
  const [formData, setFormData] = useState({});

  let user = localStorage.getItem('user');
  user = JSON.parse(user);
  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split('_');
    console.log(user, fieldName);
    let fieldIntIndex = parseInt(fieldIndex, 10);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 5) {
        const nextfield = document.querySelector(`input[name=field_${fieldIntIndex + 1}]`);
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
    setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    verifyAccount(formData);
  };

  return (
    <div class="App">
      <div class="row">
        <Pine />

        <div class="col-md-7 text-center">
          <div class="mt-10">
            <img src={VerifyEnvelope} alt="Envelope" className="evenlope" />
            <h4 class="mt-4 header__text">Verify your account to continue,</h4>
            <p class="verify-text">
              We sent you a verification code to . Enter the verification code to continue
            </p>
            <div className="col-md-8 mx-auto">
              <form className="form mt-8" onSubmit={handleSubmit}>
                <ul class="tel-list">
                  <li>
                    <input
                      type="tel"
                      className="form-control"
                      name="field_1"
                      maxLength="1"
                      onChange={handleChange}
                      value={formData.one}
                    />
                  </li>
                  <li>
                    <input
                      type="tel"
                      className="form-control"
                      name="field_2"
                      maxLength="1"
                      onChange={handleChange}
                      value={formData.two}
                    />
                  </li>
                  <li>
                    <input
                      type="tel"
                      className="form-control"
                      name="field_3"
                      maxLength="1"
                      onChange={handleChange}
                      value={formData.three}
                    />
                  </li>
                  <li>
                    <input
                      type="tel"
                      className="form-control"
                      name="field_4"
                      maxLength="1"
                      onChange={handleChange}
                      value={formData.four}
                    />
                  </li>
                  <li>
                    <input
                      type="tel"
                      className="form-control"
                      name="field_5"
                      maxLength="1"
                      onChange={handleChange}
                      value={formData.five}
                    />
                  </li>
                </ul>

                <div className="mt-8">
                  <button type="submit" className="btn btn-login verify-button">
                    {' '}
                    Verify account{' '}
                  </button>
                  <div className="ytt pt-3">
                    <p className="form-check-label text-center mt-3">
                      <span className="text-secondary">
                        Code will expire in <b>01:59</b>{' '}
                      </span>
                      <a href="#/" onClick={handleSubmit} className="login__links">
                        Resend Code
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Verify;
