import { DirectionsRenderer, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import pickupIcon from '../../assets/map/locator.svg';
import deliveryIcon from '../../assets/map/truck_icons.svg';

const Map = ({ pickup, delivery }) => {
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const google = window.google;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  var icons = {
    start: new google.maps.MarkerImage(
      // URL
      deliveryIcon,
      // (width,height)
      new google.maps.Size(49, 46),
      // The origin point (x,y)
      new google.maps.Point(0, 0),
      // The anchor point (x,y)
      new google.maps.Point(22, 32),
    ),
    end: new google.maps.MarkerImage(
      // URL
      pickupIcon,
      // (width,height)
      new google.maps.Size(49, 46),
      // The origin point (x,y)
      new google.maps.Point(0, 0),
      // The anchor point (x,y)
      new google.maps.Point(22, 32),
    ),
  };

  async function calculateRoute() {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: pickup || '',
      destination: delivery || '',
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    var leg = results.routes[0].legs[0];
    makeMarker(leg?.start_location, icons.start, 'title');
    makeMarker(leg?.end_location, icons.end, 'title');
  }

  function makeMarker(position, icon, title) {
    new google.maps.Marker({
      position: position,
      map: map,
      icon: icon,
      title: title,
    });
  }
  useEffect(() => {
    if (pickup && delivery) {
      calculateRoute();
      makeMarker(icons);
    }
  }, [pickup, delivery, icons]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <>
        {directionsResponse && isLoaded && (
          <GoogleMap
            center={directionsResponse || ''}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
          >
            {directionsResponse && (
              <DirectionsRenderer
                directions={directionsResponse}
                options={{
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: '#000000',
                  },
                }}
              />
            )}
          </GoogleMap>
        )}
      </>
    </div>
  );
};

export default Map;
