import React from 'react';
import { toast } from 'react-toastify';
const ToastError = ({ text }) => {
  return (
    <div>
      {toast.error(text, {
        role: 'alert',
        // toastId: 'customId',
        limit: 1,
      })}
    </div>
  );
};

export default ToastError;
