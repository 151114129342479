import {
  ACTIVATE_USER,
  ADD_BANK_DETAILS,
  DEACTIVATE_USER,
  GET_ALL_BANKS,
  GET_USER_BANK_DETAIL,
  MANAGE_USERS,
  UPDATE_BANK_DETAILS,
  UPDATE_COMPANY_PROFILE,
  UPDATE_PASSWORD,
  UPDATE_USERS,
  UPLOAD_PROFILE_PHOTO,
} from '../types/settingsType';

const initialState = {
  updateUser: {},
  allBanks: {},
  userBankDetails: {},
  manageUser: {},
};

function settingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_USERS:
      return {
        ...state,
        manageUser: payload,
      };
    case UPDATE_COMPANY_PROFILE:
      return {
        ...state,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
      };
    case UPLOAD_PROFILE_PHOTO:
      return {
        ...state,
      };
    case ACTIVATE_USER:
      return {
        ...state,
      };
    case DEACTIVATE_USER:
      return {
        ...state,
      };
    case MANAGE_USERS:
      return {
        ...state,
        manageUser: payload,
      };
    //BANK REDUCERS
    case ADD_BANK_DETAILS:
      return {
        ...state,
      };
    case UPDATE_BANK_DETAILS:
      return {
        ...state,
      };
    case GET_ALL_BANKS:
      return {
        ...state,
        allBanks: payload,
      };
    case GET_USER_BANK_DETAIL:
      return {
        ...state,
        userBankDetails: payload,
      };
    default:
      return state;
  }
}

export default settingReducer;
