import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import close  from '../../../../../assets/settings/close.svg'
import mastercardIcon from '../../../../../assets/payment/mastercard.svg';
import EditCard from '../editCard/EditCard';

const CardInfo = (props) => {
    const [editCard, setEditCard] = useState(false);

    const EditCardFunction = () => {
        props.onHide()
        setEditCard(true)
    } 

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header
                className="modal__header cardInfo__header"
                 >
                     <img onClick={props.onHide} src={close} alt="close" />
                    <h3>Mastercard</h3>
                    <div></div>
                    
                </Modal.Header>
                <Modal.Body className='cardInfo__body'>
                    <div className="container login__right ">
                        <div className="card__header d-flex justify-content-between">
                            <h1>MasterCard</h1>
                            <img src={mastercardIcon} alt="mastercard" />
                        </div>
                        <div className="fare__cost fare__content card__info__body__content">
                            <div className="card__info__content">
                                <h6>**** **** **** 9887</h6>
                                <p>Card number</p>
                            </div>
                            <div className="fare__cost_line card__line"></div>
                            <div className="card__info__content">
                                <h6>04/2022</h6>
                                <p>Expiry date</p>
                            </div>
                        </div>
                        <div className="booking__footer flex-row-reverse">
                            <button  className='btn btn__reject col-6' onClick={EditCardFunction}>Edit</button>
                            <button onClick={props.onHide} className='btn btn_accept btn__cancel col-6 mr-4'>Delete</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <EditCard
                show={editCard}
                onHide={() => setEditCard(false)}
            />
        </>
    )
}

export default CardInfo

