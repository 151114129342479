import React, { useState } from 'react';
import './EnterBusiness.css';
import { ToastContainer } from 'react-toastify';
import Pine from '../../components/Pine/Pine';
import { createCompany } from '../../redux/actions/company';
import { connect } from 'react-redux';

function EnterBusiness({ createCompany }) {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    e.persist();
    setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createCompany(formData);
  };

  return (
    <>
      <div class="login">
        <div class="row">
          <Pine />

          <div class="col-md-7 text-center">
            <div class="business__top login__right">
              <h4 class="mt-4 text-center">Enter your business detail, 💼</h4>
              <p class="f-16 business__paragraph">
                You cannot edit or change your company name again after this screen, so confirm that
                the spelling is correct.
              </p>
              <div className="col-md-8 mx-auto">
                <form className="form mt-4" onSubmit={handleSubmit}>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control login__form__control"
                      id="name"
                      placeholder="company name"
                      value={formData.name}
                      name="name"
                      onChange={handleChange}
                      required
                    />
                    <label for="name" className="login__label">
                      Company name
                    </label>
                  </div>

                  <div className="form-floating mb-4">
                    <input
                      className="form-control login__form__control"
                      id="phone"
                      placeholder="phone"
                      value={formData.phone}
                      name="phone"
                      onChange={handleChange}
                      required
                    />
                    <label for="phone" className="login__label">
                      Company phone number
                    </label>
                  </div>

                  <div className="form-floating mb-4">
                    <input
                      type="email"
                      className="form-control login__form__control"
                      id="email"
                      placeholder="Company email address"
                      value={formData.email}
                      name="email"
                      onChange={handleChange}
                      required
                    />
                    <label for="email" className="login__label">
                      Company email address
                    </label>
                  </div>

                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control login__form__control"
                      id="address"
                      placeholder="Company address"
                      value={formData.address}
                      name="address"
                      onChange={handleChange}
                      required
                    />
                    <label for="address" className="login__label">
                      Company address
                    </label>
                  </div>

                  <div class="form-floating mb-4">
                    <textarea
                      className="form-control login__form__control"
                      placeholder="Company description"
                      style={{ height: '100px' }}
                      id="floatingTextarea"
                      value={formData.message}
                      name="message"
                      onChange={handleChange}
                    ></textarea>
                    <label for="floatingTextarea" className="login__label">
                      Company description
                    </label>
                  </div>

                  <button type="submit" className="btn btn-login btn-block">
                    {' '}
                    Continue
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer autoClose={2000} />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    createCompany: (data) => dispatch(createCompany(data)),
  };
};

export default connect(null, mapDispatchToProps)(EnterBusiness);
